import React from 'react';
import { Loading, Text, AlertIcon, CompletedCheckmarkIcon, PromostandardsIcon, colors, themeOptions, ConnectedPlusIcon} from '@commonsku/styles';
import { DashboardTile } from '../helpers';

function DashboardNumberTile({
  text,
  total = null,
  checked = false,
  title = null,
  onClick = null,
  style = {},
  clickable,
  textWrapperStyle = {color: "#2A4D63"},
  titleStyle = {},
  totalStyle = {color: colors.neutrals['100']},
  loading = false,
  showWarning=false,
  showPS=false,
  ...props
}) {
  const styles = {
    textAlign: 'center',
    margin: 0,
    fontFamily: themeOptions.fontFamilies.regular,
    color: checked ? '#4ade96' : "#000000",
  };

  function renderHeaderTotal() {
    if (checked) { return null; }
    const totalStyles = {
      ...styles,
      fontSize: '24px',
      fontWeight: 'bold',
      display: 'inline-block',
      verticalAlign: 'middle',
      color: colors.neutrals['100'],
      ...totalStyle
    };

    return (
      <div style={{textAlign: 'center'}}>
        <span style={{paddingRight: 10,}}>
          {total !== null ? <Text style={totalStyles}>{total}</Text> : null}
        </span>
        {showWarning ? <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}><AlertIcon mt={8} mr={8} style={{verticalAlign: 'middle'}} /></div> : null}
        {showPS ? <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}><ConnectedPlusIcon size="large" style={{verticalAlign: 'middle',}} /></div> : null}
      </div>
    );
  }

  function renderCheckmark() {
    if (!checked) { return null; }
    const checkmarktextStyle = {
      color: '#01D374',
      fontFamily: themeOptions.fontFamilies.regular,
    };

    return (
      <>
        <CompletedCheckmarkIcon size="huge"/>
        <div style={checkmarktextStyle}>Excellent!</div>
      </>
    );
  }

  function renderText() {
    const titleStyles = {
      display: 'block',
      fontSize: '16px',
      fontWeight: '600',
      fontFamily: themeOptions.fontFamilies.regular,
      margin: '0 0 5px 0',
      ...titleStyle
    };

    const textStyles = {
      ...styles,
      fontFamily: themeOptions.fontFamilies.regular,
      fontSize: '14px',
      minHeight: '42px',
      display: 'block',
      ...textWrapperStyle,
    };

    return (
      <>
        {title && !checked ? <p style={titleStyles}>{title}</p> : null}
        <Text as="p" style={textStyles}>{text}</Text>
      </>
    );
  }

  function renderBody() {
    return (
      <div style={{ height: '100%', textAlign: 'center', verticalAlign: 'middle', }}>
        {renderCheckmark()}
        {renderText()}
      </div>
    );
  }

  return (
    <DashboardTile
      style={{
        textAlign: 'center',
        background: checked ? '#f6fffb' : '#ffffff',
        overflowY: 'auto',
        cursor: clickable || onClick ? 'pointer' : 'auto',
        maxHeight: 195,
        height: '100%',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        padding: '16px',
        ...style
      }}
      onClick={onClick}
      {...props}
    >
      {loading ? <Loading /> : <>
        <div style={{ height: '100%', }}></div>
        {renderHeaderTotal()}
        {renderBody()}
        <div style={{ height: '100%', }}></div>
      </>}
    </DashboardTile>
  );
}

export default DashboardNumberTile;
