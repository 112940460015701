import _ from 'lodash';
import { ANALYTICS_REPORT_TYPES } from "../../containers/AnalyticsHelper";

const marginByClientTypes = Object.values(ANALYTICS_REPORT_TYPES.MARGIN_BY_CLIENT.type);
export const getProjectTotalBudget = (p, order, report_type='') => {
    const order_type = _.get(order, ['order_type'], _.get(p, ['order_type'], null));
    if (order_type && ['SALES ORDER', 'INVOICE'].includes(order_type)) {
        if ((marginByClientTypes.includes(report_type))) {
            return p.total_margin_amount;
        }
        return p.total_subtotal;
    }
    return p.total_budget;
};

export const getProjectInHandsDate = (p, order) => {
    if (order) {
        return order.date_inhandsdate;
    }
    return p.order_date_inhandsdate || p.date_inhandsdate || '';
};
export const getProjectCreatedDate = (p, order) => {
    if (order) {
        return order.date_created;
    }
    return p.order_date_created || p.date_created || '';
};
