import _ from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Col, Loading, Row } from '@commonsku/styles';
import { useIdentity } from '../../../hooks';
import { REPORT_TYPE, selectors as reportsSelectors } from '../../../redux/reports';
import { TilesSlider } from '../../helpers/TilesSlider';
import DashboardNumberTile from '../DashboardNumberTile';
import { parseReports } from '../utils';
import { parseFilters } from './utils';

const PageContent = React.memo((props) => {
  const {
    onClickNumberTile,
    isLoading = false,
    filters = {},
  } = props;

  const getFilters = useCallback((report_type = '') => parseFilters(filters, report_type), [filters]);

  const identity = useIdentity();
  const reports = useSelector(reportsSelectors.reports);
  const salesReports = parseReports(reports);

  const tiles = useMemo(() => {
    const invoiceOpenStatusTotals = salesReports.invoiceOpenStatusTotals;
    const soReadyToBeInvoicedTotals = salesReports.soReadyToBeInvoicedTotals;
    const soInProductionTotals = salesReports.soInProductionTotals;

    const depositInvoicesNotPaidUnInvoiced = parseInt(_.get(
      salesReports.depositInvoicesNotPaidUnInvoiced,
      ['totals'],
      ''
    ) || 0);
    const depositInvoicesPaidSOInProduction = parseInt(_.get(
      salesReports.depositInvoicesPaidSOInProduction,
      ['totals'],
      ''
    ) || 0);
    const clientsOnCreditHoldTotals = parseInt(_.get(
      salesReports.clientsOnCreditHold,
      ['totals'],
      ''
    ) || 0);

    return [
      {
        clickable: invoiceOpenStatusTotals > 0,
        checked: invoiceOpenStatusTotals === 0,
        showWarning: invoiceOpenStatusTotals > 0,
        total: invoiceOpenStatusTotals,
        type: 'projects-list',
        title: 'Invoices',
        text: 'Open',
        report_type: REPORT_TYPE.INVOICE_OPEN_STATUS_TOTALS.type,
        order_type: REPORT_TYPE.INVOICE_OPEN_STATUS_TOTALS.params.order_type,
        status_id: REPORT_TYPE.INVOICE_OPEN_STATUS_TOTALS.params.status_id,
        params: {
          report_type: REPORT_TYPE.INVOICE_OPEN_STATUS_TOTALS.type,
          ...getFilters(REPORT_TYPE.INVOICE_OPEN_STATUS_TOTALS.type),
        },
        totalStyle: {
          color: invoiceOpenStatusTotals === 0 ? '#DADADA' : '#000000',
        },
      },
      {
        clickable: soReadyToBeInvoicedTotals > 0,
        showWarning: soReadyToBeInvoicedTotals > 0,
        total: soReadyToBeInvoicedTotals,
        type: 'projects-list',
        title: 'Sales Orders',
        text: 'Ready to be invoiced',
        report_type: REPORT_TYPE.SALES_ORDER_READY_INVOICING_STATUS_TOTALS.type,
        order_type: REPORT_TYPE.SALES_ORDER_READY_INVOICING_STATUS_TOTALS.params.order_type,
        status_id: REPORT_TYPE.SALES_ORDER_READY_INVOICING_STATUS_TOTALS.params.status_id,
        params: {
          report_type: REPORT_TYPE.SALES_ORDER_READY_INVOICING_STATUS_TOTALS.type,
          ...getFilters(REPORT_TYPE.SALES_ORDER_READY_INVOICING_STATUS_TOTALS.type),
        },
        totalStyle: {
          color: soReadyToBeInvoicedTotals === 0 ? '#DADADA' : '#000000',
        },
      },
      {
        clickable: soInProductionTotals > 0,
        showWarning: soInProductionTotals > 0,
        total: soInProductionTotals,
        type: 'projects-list',
        title: 'Sales Orders',
        text: 'In production',
        report_type: REPORT_TYPE.SALES_ORDER_IN_PRODUCTION_STATUS_TOTALS.type,
        order_type: REPORT_TYPE.SALES_ORDER_IN_PRODUCTION_STATUS_TOTALS.params.order_type,
        status_id: REPORT_TYPE.SALES_ORDER_IN_PRODUCTION_STATUS_TOTALS.params.status_id,
        params: {
          report_type: REPORT_TYPE.SALES_ORDER_IN_PRODUCTION_STATUS_TOTALS.type,
          ...getFilters(REPORT_TYPE.SALES_ORDER_IN_PRODUCTION_STATUS_TOTALS.type),
        },
        totalStyle: {
          color: soInProductionTotals === 0 ? '#DADADA' : '#000000',
        },
      },
      {
        clickable: depositInvoicesNotPaidUnInvoiced > 0,
        showWarning: depositInvoicesNotPaidUnInvoiced > 0,
        total: depositInvoicesNotPaidUnInvoiced,
        type: 'deposit-invoices-list',
        title: 'Deposit Invoices',
        text: 'Not paid for uninvoiced orders',
        report_type: REPORT_TYPE.DEPOSIT_INVOICES_NOT_PAID_UNINVOICED.type,
        params: {
          report_type: REPORT_TYPE.DEPOSIT_INVOICES_NOT_PAID_UNINVOICED.type,
          ...getFilters(REPORT_TYPE.DEPOSIT_INVOICES_NOT_PAID_UNINVOICED.type),
        },
        totalStyle: {
          color: depositInvoicesNotPaidUnInvoiced === 0 ? '#DADADA' : '#000000',
        },
      },
      {
        clickable: depositInvoicesPaidSOInProduction > 0,
        showWarning: depositInvoicesPaidSOInProduction > 0,
        total: depositInvoicesPaidSOInProduction,
        type: 'deposit-invoices-list',
        title: 'Deposit Invoices',
        text: 'Paid and not in production',
        report_type: REPORT_TYPE.DEPOSIT_INVOICES_PAID_SO_NOT_IN_PRODUCTION.type,
        params: {
          report_type: REPORT_TYPE.DEPOSIT_INVOICES_PAID_SO_NOT_IN_PRODUCTION.type,
          ...getFilters(REPORT_TYPE.DEPOSIT_INVOICES_PAID_SO_NOT_IN_PRODUCTION.type),
        },
        totalStyle: {
          color: depositInvoicesPaidSOInProduction === 0 ? '#DADADA' : '#000000',
        },
      },
      {
        clickable: clientsOnCreditHoldTotals > 0,
        showWarning: clientsOnCreditHoldTotals > 0,
        total: clientsOnCreditHoldTotals,
        type: 'clients-list',
        title: 'Clients',
        text: 'On credit hold',
        report_type: REPORT_TYPE.CLIENTS_ON_CREDIT_HOLD.type,
        data: salesReports.clientsOnCreditHold.data || [],
        params: {
          report_type: REPORT_TYPE.CLIENTS_ON_CREDIT_HOLD.type,
          ...getFilters(REPORT_TYPE.CLIENTS_ON_CREDIT_HOLD.type),
        },
        totalStyle: {
          color: clientsOnCreditHoldTotals === 0 ? '#DADADA' : '#000000',
        },
      },
    ];
  }, [salesReports, getFilters]);

  if (isLoading) {
    return (
      <>
        <Loading />
        <p style={{
          padding: 0,
          margin: 0,
          textAlign: 'center',
          color: '#00A0B6',
          fontSize: '1.3rem',
        }}>Loading...</p>
      </>
    );
  }

  return (
    <Row>
      <Col xs>
        <TilesSlider deps={[tiles, identity]}>
          {tiles.map(v => {
            return (
              <Col xs sm={4} lg={3} padded
                key={v.report_type + v.report_sub_type}
                style={{ textAlign: 'center', height: 185 }}
              >
                <DashboardNumberTile
                  style={{ height: 170, minWidth: 160, }}
                  {...v}
                  {...(v.clickable ? { onClick: onClickNumberTile(v) } : {})}
                />
              </Col>
            );
          })}
        </TilesSlider>
      </Col>
    </Row>
  );
});

export default PageContent;
