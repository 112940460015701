import _ from 'lodash';
import React from 'react';
import SupplierDashboard from './SupplierDashboard';
import { useIdentity } from '../../hooks';
import SalesDashboard from './SalesDashboard';
import FinanceDashboard from './FinanceDashboard';
import ManagementDashboard from './ManagementDashboard';
import ProductionDashboard from './ProductionDashboard';

function DashboardContainer(props) {
  const identity = useIdentity();

  if (identity.company_type === "SUPPLIER") {
    return <SupplierDashboard />;
  } else if (props.dashboard_type === "SALES_DASHBOARD") {
    return <SalesDashboard />;
  } else if (props.dashboard_type === "FINANCE_DASHBOARD") {
    return <FinanceDashboard />;
  } else if (props.dashboard_type === "MANAGEMENT_DASHBOARD") {
    return <ManagementDashboard />;
  } else if (props.dashboard_type === "PRODUCTION_DASHBOARD") {
    return <ProductionDashboard />;
  }

  return <div>Dashboard</div>;
}

export default DashboardContainer;
