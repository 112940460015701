import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button, SidePanel, Product as ProductStyled, Loading, Table, TH, TD, TR, THead, TBody, Row, Col } from '@commonsku/styles';
import { fetchProduct, fetchSupplierEvents } from './dashboardSlice';
import { formatMoney } from '../../utils';
import { useIdentity } from '../../hooks';
import { getSupplierEvents, getLoading, getProduct } from './selectors';

const handleOpenProductLink = (product) => () =>
  window.open(`/product.php?id=${product.product_id}&source=${product.source}&sku=${product.product_supplier_code}`, '_blank');
const getRating = (product) => product.esp ? product.esp.Rating : 0;

// Note: pass `useCallback` version of any `mutations functions` to use the `Memoized` version properly
const Product = ({product_id, product, addOnClick=true, showRating=true}) => {
  return (
    <ProductStyled
      key={product_id}
      product_id={product_id}
      name={product.product_name}
      supplier={product.supplier_name}
      sku={"#" + (product.product_supplier_code || '')}
      source={product.source}
      price={_.isNumber(product.cost1) ? formatMoney(product.cost1) : ''}
      currency={product.currency_id}
      picture={product.product_img_original}
      onClick={addOnClick ? handleOpenProductLink(product) : null}
      rating={showRating ? getRating(product) : 0}
    />
  );
};
const ProductMemoized = React.memo(Product);

const isSupplier = (identity) => identity.company_type === "SUPPLIER";

function getStatsFilters({ product_id, supplierStatsData, group_by='tenant' }) {
  const isPrev = _.get(supplierStatsData, 'isPrev', false);
  const filters = {product_id, group_by,};
  if (_.get(supplierStatsData, 'tenant_id', false)) {
    filters['tenant_id'] = supplierStatsData['tenant_id'];
  }
  if (_.get(supplierStatsData, 'division_id', false)) {
    filters['division_id'] = supplierStatsData['division_id'];
  }
  if (_.get(supplierStatsData, `${isPrev ? 'prev_' : ''}year`, false)) {
    filters['year'] = supplierStatsData[`${isPrev ? 'prev_' : ''}year`];
  }
  if (_.get(supplierStatsData, `${isPrev ? 'prev_' : ''}month`, false)) {
    filters['month'] = supplierStatsData[`${isPrev ? 'prev_' : ''}month`];
  }
  if (_.get(supplierStatsData, 'quarter', false)) {
    filters['quarter'] = supplierStatsData[`${isPrev ? 'prev_' : ''}quarter`];
  }
  if (_.get(supplierStatsData, `${isPrev ? 'prev_' : ''}date_from`, false)) {
    filters['date_from'] = supplierStatsData[`${isPrev ? 'prev_' : ''}date_from`];
  }
  if (_.get(supplierStatsData, `${isPrev ? 'prev_' : ''}date_to`, false)) {
    filters['date_to'] = supplierStatsData[`${isPrev ? 'prev_' : ''}date_to`];
  }
  if (_.get(supplierStatsData, 'type', false)) {
    filters['type'] = supplierStatsData['type'];
  }
  return filters;
}

function ProductSidepanel(props) {
  const {
    product_id,
    onClosePanel,
    product_data,
    supplierStatsData,
  } = {
    product_data: {},
    supplierStatsData: {},
    ...props
  };

  const dispatch = useDispatch();
  const identity = useIdentity();
  const product = useSelector(getProduct(product_id));
  const supplierEvents = useSelector(getSupplierEvents);
  const loading = useSelector(getLoading);

  useEffect(() => {
    const reskuElem = document.querySelectorAll("#root > .resku")[0];
    if (product_id) {
      if (reskuElem) {
        reskuElem.style.position = "fixed";
        reskuElem.style.zIndex = "100";
      }
      const supplierStatsFilters = getStatsFilters({product_id, supplierStatsData, group_by:'tenant'});
      if (!isSupplier(identity)) {
        dispatch(fetchProduct(product_id));
      } else {
        dispatch(fetchSupplierEvents(supplierStatsFilters, true));
      }
    } else {
      if (reskuElem) {
        reskuElem.style.position = null;
        reskuElem.style.zIndex = null;
      }
    }
  }, [product_id]);

  return (
    <SidePanel style={{ paddingBottom: 15, overflow: 'auto', }}
      visible={product_id}
      animationDuration={product_id ? 1050 : 400}
      from="right"
      height={100}
      title={product.product_name || product_data.product_name || ""}
      fullWidthTitle
      backdrop
      bodyScrollable={false}
      controls={<>
        {!isSupplier(identity) ?
          <Button mr={10} onClick={handleOpenProductLink}>Product Page</Button> : null}
        <Button onClick={() => onClosePanel()}>Close</Button>
      </>}>
        <Row>
          {product_id ? <>
            {!isSupplier(identity) ?
              <Col xs padded>
                {loading.product ? <Loading /> :
                  <ProductMemoized product_id={product_id} product={product}
                    addOnClick={!isSupplier(identity)} showRating={false} />}
              </Col>
              : <Col xs padded>
                  <Table>
                      <THead>
                        <TR>
                          <TH padded>Distributor</TH>
                          <TH padded>Totals <span style={{fontStyle: 'italic'}}>{product_data.totals ? `(${product_data.totals})` : null}</span></TH>
                        </TR>
                      </THead>
                      <TBody>
                      {loading.SUPPLIER_EVENTS ? <TR style={{textAlign: 'center'}}><TD colSpan={2}><Loading height={40} /></TD></TR> :
                        supplierEvents && supplierEvents.length > 0 ? supplierEvents.map((v, i) => {
                          return (<TR key={'product_spo_'+i}>
                            <TD padded>{v.tenant_name}</TD>
                            <TD padded>{v.totals}</TD>
                          </TR>);
                        }) : null}
                      </TBody>
                    </Table>
                </Col>}
          </>: null}
        </Row>
    </SidePanel>
  );
}

ProductSidepanel.propTypes = {
  product_id: PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool,
  ]).isRequired,
  onClosePanel: PropTypes.func.isRequired,
  product_data: PropTypes.shape({
    product_id: PropTypes.string,
    product_name: PropTypes.string,
    img_src: PropTypes.string,
    group: PropTypes.oneOfType([
      PropTypes.string, PropTypes.number,
    ]),
    totals: PropTypes.oneOfType([
      PropTypes.string, PropTypes.number,
    ]),
    isPrev: PropTypes.bool,
  }),
};

export default ProductSidepanel;
