import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { oauth } from '../utils';
import { deleteNoteSuccess, updateNoteSuccess } from './notes';

const initialState = {
    data: {},
    current_ids: [],
    errors: {},
};

const slice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessages(state, action) {
        state.data = action.payload;
    },
    fetchMessagesSuccess(state, action) {
        state.data = {
            ...state.data,
            ...action.payload.reduce((acc, v) => ({
                ...acc,
                [v.message_id]: v,
            }), {}),
        };
        state.current_ids = action.payload.map(v => v.message_id);
    },
    updateMessageSuccess(state, action) {
        state.data = {
            ...state.data,
            [action.payload.message_id]: {
                ...(state.data[action.payload.message_id] || {}),
                ...action.payload,
            },
        };
    },
    deleteMessageSuccess(state, action) {
        delete state.data[action.payload.message_id];
    },
    messagesFailure(state, action) {
        state.errors = action.payload;
    },
  },
});

const fetchMessages = (params = {}) => async (dispatch, getState) => {
    try {
        const { json } = await oauth('INDEX', 'message', params);

        const data = _.get(json, 'messages', []);
        dispatch(fetchMessagesSuccess(data));
        const notes = data.filter(v => v.details_type === 'NOTE')
            .map(v => v.note || getNoteFromMessage(v));
        dispatch(updateNoteSuccess(notes));
        return data;
    } catch (error) {
        console.error('ERROR|fetchMessages| ', error);
        dispatch(messagesFailure('Cannot get messages.'));
    }

    return null;
};

const createMessage = (params = {}) => async (dispatch, getState) => {
    try {
        const { json } = await oauth('POST', 'message', params);

        const data = _.get(json, 'message', {});
        dispatch(updateMessageSuccess(data));

        if (data.details_type === 'NOTE') {
            const note = getNoteFromMessage(data);
            dispatch(updateNoteSuccess(note));
        }
        return data;
    } catch (error) {
        console.error('ERROR|createMessage| ', error);
        dispatch(messagesFailure('Cannot create message.'));
    }

    return null;
};

const fetchMessage = (message_id, params = {}) => async (dispatch, getState) => {
    try {
        const { json } = await oauth('GET', `message/${message_id}`, params);

        const data = _.get(json, 'message', {});
        dispatch(updateMessageSuccess(data));
        if (data.details_type === 'NOTE') {
            const note = getNoteFromMessage(data);
            dispatch(updateNoteSuccess(note));
        }
        return data;
    } catch (error) {
        console.error('ERROR|fetchMessage| ', error);
        dispatch(messagesFailure('Cannot get message.'));
    }

    return null;
};

const updateMessage = (message_id, params = {}) => async (dispatch, getState) => {
    try {
        const { json } = await oauth('PUT', `message/${message_id}`, params);

        const data = _.get(json, 'message', {});
        dispatch(updateMessageSuccess(data));
        if (data.details_type === 'NOTE') {
            const note = getNoteFromMessage(data);
            dispatch(updateNoteSuccess(note));
        }
        return data;
    } catch (error) {
        console.error('ERROR|updateMessage| ', error);
        dispatch(messagesFailure('Cannot update message.'));
    }

    return null;
};

const deleteMessage = (message_id, note_id = '', params = {}) => async (dispatch, getState) => {
    try {
        await oauth('DELETE', `message/${message_id}`, params);
        dispatch(deleteMessageSuccess({ message_id }));
        if (note_id) {
            dispatch(deleteNoteSuccess({ note_id }));
        }
    } catch (error) {
        console.error('ERROR|deleteMessage| ', error);
        dispatch(messagesFailure('Cannot delete message.'));
    }
};

const noteFields = {
    'note_id': 'note_id',
    'note_type': 'note_type',
    'parent_type': 'note_parent_type',
    'parent_id': 'note_parent_id',
    'message_text': 'message_text',
    'urgent': 'urgent',
    'date_reminder': 'date_reminder',
    'contact_id': 'contact_id',
    'reminder_complete': 'reminder_complete',
    'date_complete': 'date_complete',
    'created_by': 'created_by',
    'date_created': 'date_created',
    'active': 'active',
    'replies': 'replies',
    'message_id': 'message_id',
    'note_file_id': 'note_file_id',
    'actor': 'actor',
    'job_number': 'job_number',
};
export const getNoteFromMessage = (m) => {
    if (m.note && typeof m.note === 'object') { return m.note; }
    const note = Object.keys(noteFields).reduce((acc, k) => ({...acc, [k]: m[noteFields[k]]}), {});
    if (m.reminder_user && typeof m.reminder_user === 'object') {
        note['reminder_user'] = m.reminder_user.user_id;
        note['reminder_user_email'] = m.reminder_user.user_email;
        note['reminder_user_first_name'] = m.reminder_user.user_first_name;
        note['reminder_user_last_name'] = m.reminder_user.user_last_name;
        note['reminder_user_full_name'] = (
            (m.reminder_user.user_first_name || '').trim() + ' ' +
            (m.reminder_user.user_last_name || '').trim()
        ).trim();
    }
    return note;
};

export const {
    setMessages,
    fetchMessagesSuccess,
    updateMessageSuccess,
    deleteMessageSuccess,
    messagesFailure,
} = slice.actions;
export {
    fetchMessages,
    fetchMessage,
    createMessage,
    updateMessage,
    deleteMessage,
};
export default slice.reducer;
