import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, } from '@commonsku/styles';
import { useIdentity } from '../../../hooks';
import { fetchDashboardReports, fetchReportTotals, REPORT_TYPE, selectors as reportsSelectors } from '../../../redux/reports';
import DashboardNumberTile from '../DashboardNumberTile';
import TilesSlider from '../../helpers/TilesSlider';

const totalColor = (total) => total === 0 ? '#DADADA' : '#000000';

export const numberTiles = ({ salesReports, loading }) => {
  const projectsWithNoBudget = salesReports.projectsWithNoBudget.totals || 0;
  const projectsWithNoInhandsDate = salesReports.projectsWithNoInhandsDate.totals || 0;
  const presalesPendingClientApproval = salesReports.presalesPendingClientApproval.totals || 0;
  const soPendingClientApproval = salesReports.soPendingClientApproval.totals || 0;
  const soClientApproved = salesReports.openOrderStatusTotals.sales_order_num_client_approved || 0;
  const soClientChangeRequested = salesReports.openOrderStatusTotals.sales_order_num_client_change_requested || 0;
  const potentialRepeatOrders = salesReports.potentialRepeatOrders.totals || 0;
  const clientWithNoRecentActivity = salesReports.clientWithNoRecentActivity.totals || 0;

  return [
    {
      clickable: projectsWithNoBudget > 0,
      checked: projectsWithNoBudget === 0,
      total: projectsWithNoBudget,
      title: 'Projects',
      text: projectsWithNoBudget === 0
        ? 'All projects have a budget'
        : 'No budget',
      loading: loading[REPORT_TYPE.PROJECTS_WITH_NO_BUDGET.type],
      report_type: REPORT_TYPE.PROJECTS_WITH_NO_BUDGET.type,
      showWarning: projectsWithNoBudget > 0,
      cb: fetchDashboardReports,
      params: {},
    },
    {
      clickable: projectsWithNoInhandsDate > 0,
      checked: projectsWithNoInhandsDate === 0,
      total: projectsWithNoInhandsDate,
      title: 'Projects',
      text: projectsWithNoInhandsDate === 0
        ? 'All projects have an in-hands date'
        : 'Overdue',
      loading: loading[REPORT_TYPE.OVERDUE_IN_HANDS_DATE_PROJECTS.type],
      report_type: REPORT_TYPE.OVERDUE_IN_HANDS_DATE_PROJECTS.type,
      showWarning: projectsWithNoInhandsDate > 0,
      cb: fetchDashboardReports,
      params: {},
    },

    {
      clickable: soClientApproved > 0,
      total: soClientApproved,
      title: 'Sales Orders',
      text: 'Client approved',
      loading: loading[REPORT_TYPE.OPEN_ORDER_STATUS_TOTALS.type],
      report_type: REPORT_TYPE.OPEN_ORDER_STATUS_TOTALS.type,
      checked: soClientApproved === 0,
      showWarning: soClientApproved > 0,
      cb: fetchReportTotals,
      params: {
        order_type: 'SALES ORDER',
        status_name: 'Client Approved',
      },
      totalStyle: {
        color: totalColor(soClientApproved),
      },
    },
    {
      clickable: soClientChangeRequested > 0,
      total: soClientChangeRequested,
      title: 'Sales Orders',
      text: 'Client change requested',
      loading: loading[REPORT_TYPE.OPEN_ORDER_STATUS_TOTALS.type],
      report_type: REPORT_TYPE.OPEN_ORDER_STATUS_TOTALS.type,
      checked: soClientChangeRequested === 0,
      showWarning: soClientChangeRequested > 0,
      cb: fetchReportTotals,
      params: {
        order_type: 'SALES ORDER',
        status_name: 'Client Change Requested',
      },
      totalStyle: {
        color: totalColor(soClientChangeRequested),
      },
    },

    {
      clickable: presalesPendingClientApproval > 0,
      total: presalesPendingClientApproval,
      title: 'Pre-sales',
      text: 'Client review',
      loading: loading[REPORT_TYPE.PRESALES_PENDING_CLIENT_APPROVAL.type],
      report_type: REPORT_TYPE.PRESALES_PENDING_CLIENT_APPROVAL.type,
      cb: fetchDashboardReports,
      params: {},
      totalStyle: {
        color: totalColor(presalesPendingClientApproval),
      },
    },
    {
      clickable: soPendingClientApproval > 0,
      total: soPendingClientApproval,
      title: 'Sales Orders',
      text: 'Pending client approval',
      loading: loading[REPORT_TYPE.SALES_ORDERS_PENDING_CLIENT_APPROVAL.type],
      report_type: REPORT_TYPE.SALES_ORDERS_PENDING_CLIENT_APPROVAL.type,
      cb: fetchDashboardReports,
      params: {},
      totalStyle: {
        color: totalColor(soPendingClientApproval),
      },
    },

    {
      clickable: potentialRepeatOrders > 0,
      total: potentialRepeatOrders,
      title: 'Sales Orders',
      text: 'Potential repeat',
      loading: loading[REPORT_TYPE.POTENTIAL_REPEAT_ORDERS.type],
      report_type: REPORT_TYPE.POTENTIAL_REPEAT_ORDERS.type,
      showDateCreated: true,
      defaultSort: {id: 'date_created', desc: false },
      cb: fetchReportTotals,
      params: {
        order_type: 'SALES ORDER',
        last_year_orders: 90,
      },
      totalStyle: {
        color: totalColor(potentialRepeatOrders),
      },
    },
    {
      clickable: clientWithNoRecentActivity > 0,
      total: clientWithNoRecentActivity,
      type: 'clients-list',
      title: 'Clients',
      text: 'No recent activity',
      loading: loading[REPORT_TYPE.CLIENTS_WITH_NO_RECENT_ACTIVITY.type],
      report_type: REPORT_TYPE.CLIENTS_WITH_NO_RECENT_ACTIVITY.type,
      data: Object.values(salesReports.clientWithNoRecentActivity.data || {}),
      totalStyle: {
        color: totalColor(clientWithNoRecentActivity),
      },
    },
  ];
};

function ReportsNumberTiles({
  salesReports={},
  salesTeamId='',
  salesRepId,
  salesRepType,
  onClickTile,
  style={},
}) {
  const dispatch = useDispatch();
  const identity = useIdentity();
  const loading = useSelector(reportsSelectors.loading);

  const onClick = v => () => {
    const type = REPORT_TYPE[v.report_type];
    v.cb && dispatch(
      v.cb(type.route, v.report_type, {
        report_type: v.report_type,
        get_data: 1,
        rep_type: salesRepType,
        rep_id: salesRepId === 'GROUP' ? '' : salesRepId,
        team_id: salesTeamId,
        ...(v.params || {}),
      })
    );
    onClickTile && onClickTile({...v, type: v.type || 'projects-list', title: v.text || ''});
  };

  return (<>
    <TilesSlider deps={[salesReports, loading, identity]}>
      {numberTiles({ salesReports, loading, identity }).map((v, i) => (
        <Col xs sm={4} lg={3} padded key={'DashboardNumberTile' + i} style={{ textAlign: 'center', height: 185, ...style }}>
          <DashboardNumberTile {...v}
            style={{ height: 170, width: 160, }}
            {...(v.clickable ? {onClick: onClick(v)} : {})}
          />
        </Col>
      ))}
    </TilesSlider>
  </>);
}

export default ReportsNumberTiles;
