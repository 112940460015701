import React from 'react';
import {Row, Col, Box, Wrapper, colors } from '@commonsku/styles';
import _ from 'lodash';

const titleStyles = {color: '#0f3d51', fontSize: '1em', fontWeight: 'bold'};
export const WidgetTitle = ({color="primary", startText="", highlightText, endText="", style={}, children}) => (
  <span style={{...titleStyles, ...style}}>
    {startText} <span style={{color: color === 'cta' ? colors.cta : colors.primary, borderBottom: 'dashed'}}>
      {highlightText}
    </span> {endText}
    {children}
  </span>
);
export const WidgetTitleSelect = ({color="primary", startText="", endText="", value="", options=[], onChange=(v) => {}}) => {
  const [isShown, setIsShown] = React.useState(false);

  return (
    <span style={titleStyles}>
      {startText} <span style={{color: color === 'cta' ? colors.cta : colors.primary, borderBottom: 'dashed'}}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >{isShown ? <select style={{borderRadius: 5, padding: 8, paddingLeft: 2, borderColor: colors.primary}}
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}>
        {_.map(options, (v, i) => <option key={v.value + i} value={v.value}>{v.label}</option>)}
      </select> : _.get(_.find(options, v => v.value==value), 'label', 'Select...')}</span> {endText}
    </span>
  );
};

const WidgetCardBox = ({ colSizes={xs: 12}, title, value, components={}, id="", boxStyle={}, boxHeight={},...props }) => {
  return (
    <Col padded {...colSizes} >
      <Box padded style={{ padding: 20, marginTop: 0, ...boxStyle, ...boxHeight}} >
        <Row style={{ width: '100%' }}>
          <Col xs={12} style={{ paddingBottom: 10 }}>
            {components.Title || <span style={titleStyles}>{title}</span>}
            <Wrapper style={{float: 'right', maxWidth: 35, width: 35,}} px={10} id={"export_menu_"+id} className="custom-tools" custom={`
              & > ul {
                top: auto !important;
                bottom: auto !important;
                right: auto !important;
                left: auto !important;
                position: inherit !important;
                float: right !important;
              }
            `}>
            </Wrapper>
          </Col>
          {props.children}
        </Row>
      </Box>
    </Col>
  );
};

export default React.memo(WidgetCardBox);
