import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row, SidePanel, Text, LabeledInput, colors, CheckMark, Radio, CircleProgressIcon, Dropdown, Loading, themeOptions } from '@commonsku/styles';
import { LabeledSelect, Select } from '../helpers';
import { fetchNotes, isNoteComplete, selectors as notesSelectors, updateNote } from '../../redux/notes';
import { dateStr, formatFriendlyDate } from '../../utils';
import DateInput, { LabeledDateInput } from '../DateInput';
import { createMessage } from '../../redux/messages';
import MentionTextarea from '../MentionTextarea';
import { getStatusDropdown } from '../../selectors/dropdowns';
import { getMentionUsers, getUserRepOptionsNoAll } from '../../selectors/users';
import { createFetchProject, updateProject } from '../../actions/project';
import { getProjectCreatedDate, getProjectInHandsDate } from './utils';
import { ORDER_TYPES, PROJECT_STAGES } from '../dashboard';
import { updateOrder } from '../../actions/order';

const getLatestOrderById = createSelector(
  [
    s => s.entities.projects,
    (s, projectId) => projectId
  ],
  (projects, projectId) => _.get(projects, [projectId, 'latest_order'], {})
);

const getProjectById = createSelector(
  [s => s.entities.projects, (s, projectId) => projectId],
  (projects, projectId) => _.get(projects, [projectId], {})
);

const ProjectSidepanel = ({
  projectId,
  orderId='',
  onClose,
  onUpdate = null,
}) => {
  const dispatch = useDispatch();

  const project =  useSelector(s => getProjectById(s, projectId));
  const users_options =  useSelector(getUserRepOptionsNoAll);
  const status_options = useSelector(s => {
    const o = getLatestOrderById(s, projectId);
    if (!o || !o.order_type) { return []; }
    return _.map(
      getStatusDropdown(s, { order_type: o.order_type }),
      v => ({ label: v.status_name, value: v.status_id })
    );
  });

  const [state, setState] = useState(project);
  const [order, setOrder] = useState({});
  const [showLoading, setShowLoading] = useState(true);
  const [showNewTask, setShowNewTask] = useState(false);

  useEffect(() => {
    if (!projectId) { return; }

    setShowLoading(true);
    Promise.resolve(dispatch(createFetchProject(projectId, {with_latest_order: 1, order_id: orderId})))
      .then((action) => {
        const job = action.payload.project;
        if (job.latest_order && job.latest_order.order_id) {
          setOrder(job.latest_order);
          // dispatch(createLoadOrder(job.latest_order.order_id));
        }
        if (job.notes && job.notes.length) {
          dispatch(fetchNotes({
            parent_id: projectId,
            parent_type: 'JOB',
            note_type: 'ALL REMINDERS'
          }));
        }
        setShowLoading(false);
      });
  }, [dispatch, projectId, orderId]);

  useEffect(() => {
    const o = _.get(project, ['latest_order'], {});
    setState({
      ...project,
      date_inhandsdate: getProjectInHandsDate(project, o),
      date_created: getProjectCreatedDate(project, o),
      total_budget: _.get(project, ['total_budget'], _.get(o, ['total_budget'], 0)),
    });
    setOrder(o);
  }, [project]);

  const handleUpdate = React.useCallback((field, value, previous_value) => {
    if (!order.order_id || !projectId) { return; }

    Promise.resolve(
      dispatch(
        ['date_inhandsdate', 'status_id'].includes(field) ?
          updateOrder(
            order.order_id,
            field,
            previous_value,
            value
          ) : updateProject(
            projectId,
            field,
            previous_value,
            value
          )
      )
    ).then(action => {
      setOrder(s => ({
        ...s,
        job_id: projectId,
        order_id: order.order_id,
        order_type: order.order_type,
        [field]: action.payload.data[field],
      }));
      onUpdate && onUpdate({
        job_id: projectId,
        order_id: order.order_id,
        field,
        previous_value,
        value: action.payload.data[field],
        shouldRemoteUpdate: false,
        order_type: order.order_type,
      });
    });
  }, [order.order_id, order.order_type, projectId, onUpdate, dispatch]);

  const projectValue = React.useMemo(() => {
    const orderType = _.get(order, ['order_type'], false);
    if (['SALES ORDER', 'INVOICE'].includes(orderType)
      && !isNaN(order.total_subtotal)
    ) {
      return parseFloat(order.total_subtotal).toFixed(2);
    }
    return '';
  }, [order]);

  const actionItems = React.useMemo(() => {
    const result = [
      {
        onClick: () => window.open(`/project/${project.job_number}/`, '_blank'),
        content: 'View Project'
      },
    ];
    if (_.get(order, 'client_id', null)) {
      result.push({
        onClick: () => window.open(`/client.php?id=${order.client_id}`, '_blank'),
        content: 'View Client'
      });
    }

    return result;
  }, [project, order]);

  return (
    <SidePanel
      visible={projectId}
      style={{zIndex: 9999, padding: '1em'}}
      header={<>
        <Row>
          <Col xs md={6}>
            <Text as="a"
              href={`/project/${project.job_number}`}
              target="_blank"
              style={{fontSize: '24px', lineHeight: '40px', fontWeight: 'bold', textDecoration: 'none', fontFamily: themeOptions.fontFamilies.bold}}
            >Project</Text>
          </Col>
          <Col xs md={6} style={{textAlign: 'right'}}>
            <Dropdown
              sm={'width: 100%; margin-top: 10px;'}
              style={{marginRight: '10px', zIndex: 105}}
              items={actionItems}
            />
            <Button
              onClick={(e) => {
                onClose && onClose(e);
              }}>Close</Button>
          </Col>
          <Col xs style={{paddingTop: 5, paddingBottom: 10,}}>
            <Text as="span"
              style={{fontSize: '16px', lineHeight: '24px', fontWeight: 'bold', display: 'block', fontFamily: themeOptions.fontFamilies.bold}}
            >{_.get(order, 'client_name')} - Project #{_.get(project, 'job_number')} - {_.get(project, 'job_name')}</Text>
          </Col>
        </Row>
      </>}
    >
      <Row>
      {showLoading
        ? <Col xs><Loading /></Col>
        : <>
          <Col padded xs md={6}>
            <span style={{display: 'inline-block'}}>
              <CircleProgressIcon
                percentage={_.get(PROJECT_STAGES, [order.order_type, 'order'], 0)/5 * 100}
                text={ORDER_TYPES[order.order_type]}
              />
            </span>
            <span style={{display: 'inline-block', paddingLeft: '10px', verticalAlign: 'top', paddingTop: 10,}}>
              <Text style={{display: 'block'}}>{order.order_type}</Text>
            </span>
          </Col>
          <Col padded xs md={6}>
            <Select options={status_options}
              value={order.status_id}
              onChange={e => handleUpdate('status_id', e.value, order.status_id)}
            />
          </Col>
          <Col padded xs>
            <DateInput
              customInput={<LabeledDateInput date={state.date_inhandsdate} label="In-Hands Date" />}
              showMonthDropdown
              showYearDropdown
              value={state.date_inhandsdate}
              onChange={date => handleUpdate('date_inhandsdate', date, state.date_inhandsdate)}
              portalId="root-portal"
              style={{width: '100%'}}
            />
          </Col>
          <Col padded xs md={6}>
            <LabeledInput label="Budget"
              value={state.total_budget || ''}
              onChange={e => setState(s => ({...s, total_budget: e.target.value}))}
              onBlur={e => handleUpdate('total_budget', e.target.value, state.total_budget)}
            />
          </Col>
          <Col padded xs md={6}>
            <LabeledInput label="Value"
              defaultValue={projectValue}
              disabled
              readOnly
              style={{
                border: 'none',
                boxShadow: 'none',
              }}
            />
          </Col>
          <Col padded xs md={6}>
            <LabeledSelect
              name="project_client_rep"
              label="Client Rep"
              options={users_options}
              value={state.client_rep_id}
              onChange={e => handleUpdate('client_rep_id', e.value, state.client_rep_id)}
            />
          </Col>
          <Col padded xs md={6}>
            <LabeledSelect
              name="project_order_rep"
              label="Order Rep"
              options={users_options}
              value={state.sales_rep_id}
              onChange={e => handleUpdate('sales_rep_id', e.value, state.sales_rep_id)}
            />
          </Col>

          <Col padded xs style={{paddingBottom: 20}}>
            <Button
              style={{width: '100%', background: '#EEFDFF', color: colors.primary, boxShadow: '0px 2px 4px rgba(22, 112, 125, 0.12)', border: 'none'}}
              onClick={e => setShowNewTask(s => !s)}
            >New Task</Button>
          </Col>

          <NewTaskForm
            projectId={projectId}
            showNewTask={showNewTask}
            setShowNewTask={setShowNewTask}
          />

          <ProjectNotes projectId={projectId} />
        </>}
      </Row>
    </SidePanel>
  );
};

function ProjectNotes({ projectId }) {
  const dispatch = useDispatch();
  const projectNotes = useSelector(
    s => notesSelectors.remindersByParent(s, projectId, 'JOB')
  );

  const onUpdateNote = React.useCallback((note) => {
    dispatch(updateNote(note.note_id, note));
  }, [dispatch]);

  if (!projectId) { return null; }

  return projectNotes.map((v, i) => {
    const completed = isNoteComplete(v);
    const message = (v.message_text || '')
      .replace(/(href=['"])(?!https?:)/, "$1/")
      .replace(/<a /, '<a target="_blank" ');
    return (
      <div key={`${i}_${v.message_id}`} style={{marginBottom: '0.2rem', width: '100%'}}>
        <span style={{display: 'inline-block', verticalAlign: 'top'}}>
          <Radio type="checkbox" name="note-checkbox" checked={completed} />
          <CheckMark
            checked={completed}
            style={{ top: 'auto', right: 'auto', bottom: 'auto', left: 'auto', }}
            onClick={e => {
              onUpdateNote({
                note_id: v.note_id,
                reminder_complete: completed ? 0 : 1,
                date_complete: completed ? '' : dateStr(new Date()),
              });
            }}
          />
        </span>
        <span style={{display: 'inline-block', paddingLeft: 35,}}>
          <p style={{margin: 0}} dangerouslySetInnerHTML={{__html: message}} />
          <p style={{margin: 0, paddingTop: 10, color: '#B7B7B7', fontSize: 12, }}>
            Due {formatFriendlyDate(Date.parse(v.date_reminder))}
          </p>
        </span>
      </div>
    );
  });
}

function NewTaskForm({ projectId, showNewTask, setShowNewTask }) {
  const dispatch = useDispatch();
  const mention_users = useSelector(getMentionUsers);
  const users_options =  useSelector(getUserRepOptionsNoAll);

  const [newTask, setNewTask] = useState({
    message_text: '',
    date_reminder: '',
    reminder_user: '',
  });

  const onAddTask = () => {
    Promise.resolve(dispatch(createMessage({
      date_reminder: newTask.date_reminder,
      details_parent_id: projectId,
      details_parent_type: "JOB",
      details_type: "NOTE",
      job_id: projectId,
      message_text: newTask.message_text,
      reminder_user: newTask.reminder_user,
      tagged_users: "",
    }))).then(resp => {
      setShowNewTask(false);
      setNewTask({
        message_text: '',
        date_reminder: '',
        reminder_user: '',
      });
    });
  };

  if (!projectId || !showNewTask) { return null; }

  return (
    <Col xs style={{
      borderBottom: '1px solid #e0e7eb',
      padding: 10,
      marginBottom: 16,
      background: '#edf2f4',
      borderRadius: 4,
      boxShadow: '0 2px 4px rgb(0 0 0 / 7%)',
    }}>
      <Row>
        <Col padded xs className="resku">
          <MentionTextarea
            mention_users={mention_users}
            value={newTask.message_text}
            onChange={value => setNewTask(s => ({...s, message_text: value}))}
          />
        </Col>
        <Col padded xs md={6}>
          <DateInput
            portalId="root-portal"
            customInput={<LabeledDateInput date={newTask.date_reminder} label="Task Date" />}
            showMonthDropdown
            showYearDropdown
            value={newTask.date_reminder}
            onChange={dt => setNewTask(s => ({...s, date_reminder: dt}))}
          />
        </Col>
        <Col padded xs md={6}>
          <LabeledSelect
            name="project_new_task_assign_to"
            label="Assign To"
            options={users_options}
            value={newTask.reminder_user}
            onChange={e => setNewTask(s => ({...s, reminder_user: e.value}))}
          />
        </Col>
        <Col padded xs style={{textAlign: 'right'}}>
          <Button onClick={onAddTask}>Create Task</Button>
        </Col>
      </Row>
    </Col>
  );
}

export default ProjectSidepanel;
