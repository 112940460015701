import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { DraggableTasksCalendar,TaskIcon, ReceiptLongIcon } from '@commonsku/styles';
import { dateStr, getFirstDayOfWeek, getLastDayOfWeek, subtractDaysDate } from '../../utils';
import { useIdentity, useUserFlags } from '../../hooks';
import { rebuildTooltip } from '../helpers';
import { TASK_PARENT_TYPES } from '../message/utils';
import usePOs from './usePOs';
import useCalendarNotes from './useCalendarNotes';
import { CALENDAR_VIEWS, SHOW_WEEKEND_FLAG } from './utils';
import ReactTooltip from 'react-tooltip';

const today = new Date();

function MultiViewsCalendar({
  itemId=null,
  onClickItem=null,
  onAddTask=null,
  onChangeWeek=null,
  onUpdate=null,
  repType=null,
  userId=null,
  teamId=null,
  ...props
}) {
  const identity = useIdentity();
  const {
    onAddFlag,
    onDeleteFlag,
    hasUserFlag,
  } = useUserFlags(identity);
  const [selectedTab, setSelectedTab] = useState('ALL');
  const [currentView, setCurrentView] = useState(CALENDAR_VIEWS.TASK);
  const views = React.useMemo(() => {
    return [
      {
        title: 'Tasks',
        type: CALENDAR_VIEWS.TASK,
        selected: false,
        showFooterTasks: true,
        Icon: TaskIcon,
        calendarTabs: [
          { content: '', label: 'All Tasks', key: 'ALL', onClick: () => { setSelectedTab('ALL'); } },
          { content: '', label: 'Client Tasks', key: 'CLIENT', onClick: () => { setSelectedTab('CLIENT'); } },
          { content: '', label: 'Project Tasks', key: 'JOB', onClick: () => { setSelectedTab('JOB'); } },
          { content: '', label: 'PO Tasks', key: 'PURCHASE ORDER', onClick: () => { setSelectedTab('PURCHASE ORDER'); } },
        ],
      },
      {
        title: 'POs',
        type: CALENDAR_VIEWS.PO,
        selected: false,
        showFooterTasks: false,
        Icon: ReceiptLongIcon,
        calendarTabs: [
          { content: '', label: 'Next Action Date', key: 'NA_DATE', onClick: () => { setSelectedTab('NA_DATE'); } },
          { content: '', label: 'In Hands Date', key: 'IH_DATE', onClick: () => { setSelectedTab('IH_DATE'); } },
          { content: '', label: 'Projected Ship Date', key: 'PS_DATE', onClick: () => { setSelectedTab('PS_DATE'); } },
        ],
      },
    ];
  }, []);

  const selectedView = React.useMemo(() => {
    return views.find(v => v.type === currentView);
  }, [views, currentView]);

  const notesData = useCalendarNotes({
    identity, userId, teamId, repType, selectedTab,
    isSelected: selectedView.type === CALENDAR_VIEWS.TASK,
  });
  const posData = usePOs({
    identity, userId, teamId, repType, selectedTab,
    isSelected: selectedView.type === CALENDAR_VIEWS.PO,
  });

  const loading = React.useMemo(() => {
    if (selectedView.type === CALENDAR_VIEWS.TASK) {
      return notesData.loading;
    } else if (selectedView.type === CALENDAR_VIEWS.PO) {
      return posData.loading;
    } else if (selectedView.type === CALENDAR_VIEWS.PROJECT) {
      return false;
    }
    return false;
  }, [selectedView, notesData.loading, posData.loading]);

  const tasks = React.useMemo(() => {
    if (selectedView.type === CALENDAR_VIEWS.TASK) {
      return selectedTab === TASK_PARENT_TYPES.ALL.value
        ? notesData.tasks
        : _.filter(notesData.tasks, v => v.parent_type === selectedTab);
    } else if (selectedView.type === CALENDAR_VIEWS.PO) {
      return posData.tasks;
    } else if (selectedView.type === CALENDAR_VIEWS.PROJECT) {
      return [];
    }

    return [];
  }, [notesData.tasks, posData.tasks, selectedTab, selectedView]);

  const footerTasks = React.useMemo(() => {
    if (selectedView.type === CALENDAR_VIEWS.TASK) {
      return selectedTab === TASK_PARENT_TYPES.ALL.value
        ? notesData.overdueTasks
        : _.filter(notesData.overdueTasks, v => v.parent_type === selectedTab);
    }

    return [];
  }, [notesData.overdueTasks, selectedTab, selectedView]);

  const onUpdateCalendarItem = (item, otherData) => {
    if (selectedView.type === CALENDAR_VIEWS.TASK) {
      if (otherData.action === 'DROP') {
        notesData.onUpdate({
          note_id: item.note_id,
          date_reminder: _.isDate(item.date) ? dateStr(item.date) : item.date
        });
      } else if (otherData.action === 'TOGGLE_CHECKBOX') {
        notesData.onUpdate({
          note_id: item.note_id,
          reminder_complete: item.completed ? 1 : 0,
        });
      }
      rebuildTooltip();
    } else if (selectedView.type === CALENDAR_VIEWS.PO) {
      if (otherData.action === 'DROP') {
        let field = null;
        switch (selectedTab) {
          case 'NA_DATE':
            field = 'date_nextaction';
            break;
          case 'PS_DATE':
            field = 'date_shipdatecurrent';
            break;
          default:
            return;
        }
        if (!field) {
          return;
        }

        const newDate = _.isDate(item.date) ? dateStr(item.date) : item.date;
        posData.onUpdate(item.purchase_order_id, field, item[field], newDate);
      }
    }
    onUpdate && onUpdate(item, otherData, selectedView);
  };

  useEffect(() => {
    if (selectedView.type === CALENDAR_VIEWS.TASK) {
      // on first load get overdue tasks for last 30 days
      notesData.getNotes({
        start_stamp_reminder: dateStr(subtractDaysDate(30, today)),
        end_stamp_reminder: dateStr(subtractDaysDate(1, getFirstDayOfWeek(today))),
        include_complete: false,
      });
    }
  }, [identity]);

  useEffect(() => {
    setSelectedTab(
      selectedView.calendarTabs
        && selectedView.calendarTabs.length
      ? selectedView.calendarTabs[0].key
      : 'ALL'
    );
  }, [selectedView]);

  useEffect(() => {
    if (selectedView.type === CALENDAR_VIEWS.TASK) {
      notesData.getNotes();
    } else if (selectedView.type === CALENDAR_VIEWS.PO) {
      posData.getPOs({
        start_stamp: dateStr(getFirstDayOfWeek(today)),
        end_stamp: dateStr(getLastDayOfWeek(today)),
        date_filter: '',
      });
    } else if (selectedView.type === CALENDAR_VIEWS.PROJECT) {
      // fetch PROJECTS
    }

    rebuildTooltip();
  }, [selectedView, notesData.getNotes, posData.getPOs]);

  useEffect(() => {
    onClickItem && onClickItem(false);
  }, [selectedTab, onClickItem, selectedView]);

  useEffect(() => {
    rebuildTooltip();
  }, [loading]);

  const hanldeClickItem = React.useCallback((item) => {
    if (typeof itemId !== 'undefined' && itemId === item.id) {
      onClickItem && onClickItem(false);
      return;
    }
    if (selectedView.type === 'PO') {
      Promise.resolve(posData.fetchPO(item.id))
      .then(res => {
        onClickItem && onClickItem(
          selectedView.type,
          item.id,
          posData.findPO(item.id) || item
        );
      });
      return;
    }
    onClickItem && onClickItem(selectedView.type, item.id, item);
  }, [itemId, onClickItem, selectedView, posData.findPO, posData.fetchPO]);

  const handleChangeWeek = React.useCallback(({ date }) => {
    if (selectedView.type === CALENDAR_VIEWS.TASK) {
      const params = {
        start_stamp_reminder: dateStr(getFirstDayOfWeek(date)),
        end_stamp_reminder: dateStr(getLastDayOfWeek(date)),
      };
      notesData.getNotes(params);
      rebuildTooltip();
    } else if (selectedView.type === CALENDAR_VIEWS.PO) {
      posData.getPOs({
        start_stamp: dateStr(getFirstDayOfWeek(date)),
        end_stamp: dateStr(getLastDayOfWeek(date)),
        date_filter: '',
      });
    } else if (selectedView.type === CALENDAR_VIEWS.PROJECT) {
      // fetch PROJECTS
    }

    onChangeWeek && onChangeWeek({
      date,
      start_stamp: dateStr(getFirstDayOfWeek(date)),
      end_stamp: dateStr(getLastDayOfWeek(date)),
    });
  }, [onChangeWeek, selectedView, notesData.getNotes, posData.getPOs]);

  const showWeekendFlag = hasUserFlag(SHOW_WEEKEND_FLAG);
  const onToggleWeekend = (show) => {
    if (show) {
      onAddFlag(SHOW_WEEKEND_FLAG);
    } else {
      onDeleteFlag(SHOW_WEEKEND_FLAG);
    }
    rebuildTooltip();
  };

  return (
    <>
      <DraggableTasksCalendar
        tasks={tasks}
        views={_.map(
          views,
          v => ({...v, selected: currentView === v.type })
        )}
        onUpdateTask={onUpdateCalendarItem}
        footerTasks={footerTasks}
        headerTabs={_.get(selectedView, 'calendarTabs', [])}
        onClickTask={hanldeClickItem}
        onChangeWeek={handleChangeWeek}
        onClickAddTask={onAddTask}
        loading={loading}
        weekend={showWeekendFlag}
        onToggleWeekend={onToggleWeekend}
        onClickView={setCurrentView}
        showFooterTasks={_.get(selectedView, 'showFooterTasks', false)}
        showAddTaskBtn={selectedView.type === CALENDAR_VIEWS.TASK}
      />
      <ReactTooltip id={"po-rep-avatar"} />
    </>
  );
}

export default MultiViewsCalendar;
