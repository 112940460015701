import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Popup } from "@commonsku/styles";
import { selectors as reportsSelectors } from '../../../redux/reports';
import ClientsTableWindowed from "../ClientsTableWindowed";
import DepositInvoicesTableWindowed from "../DepositInvoicesTableWindowed";
import ProjectsTableWindowed from "../ProjectsTableWindowed";
import { AddTaskFormPopup } from '../../message';

const PagePopups = React.memo((props) => {
  const {
    identity,
    showPopup,
    setShowPopup,
  } = props;
  const reports = useSelector(reportsSelectors.reports);
  const reportsLoading = useSelector(reportsSelectors.loading);

  if (!showPopup) { return null; }
  if (showPopup.action === 'add_task') {
    return (
      <AddTaskFormPopup onClose={() => setShowPopup(false)} message={{
        reminder_user: identity.user_id,
        details_type: 'NOTE',
        note_type: 'NOTE',
      }} />
    );
  }

  if (!['projects-list', 'clients-list', 'deposit-invoices-list'].includes(showPopup.type)) {
    return null;
  }

  let Child = null;
  if (showPopup.type === 'projects-list') {
    Child = <ProjectsTableWindowed
      {...showPopup}
      onSave={() => setShowPopup(false)}
    />;
  } else if (showPopup.type === 'clients-list') {
    Child = <ClientsTableWindowed
      {...showPopup}
      showLastActivity={false}
      data={_.get(reports, [showPopup.report_type, 'data'], [])}
      loading={_.get(reportsLoading, [showPopup.report_type], false)}
      onSave={() => setShowPopup(false)}
    />;
  } else if (showPopup.type === 'deposit-invoices-list') {
    Child = <DepositInvoicesTableWindowed
      {...showPopup}
      data={_.get(reports, [showPopup.report_type, 'data'], [])}
      loading={_.get(reportsLoading, [showPopup.report_type], false)}
      onSave={() => setShowPopup(false)}
    />;
  }

  return (
    <Popup
      title={showPopup.title}
      onClose={() => {
        setShowPopup(false);
      }}
      className={`popup ${showPopup.type}-popup csku-styles`}
    >{Child}<br /></Popup>
  );
});

export default PagePopups;
