import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { dateStr, oauth } from '../utils';
import { finalizeFetchAppendPOForProduction } from '../actions/purchase_order';
import Status from '../constants/Status';

export const REPORT_TYPE = {
  'SALES_TOTALS': {
    route: 'sales-totals',
    type: 'SALES_TOTALS',
  },
  'STUCK_IN_STAGE_TOTALS': {
    route: 'stuck-in-stage-totals',
    type: 'STUCK_IN_STAGE_TOTALS',
  },
  'PROJECTION_TOTALS': {
    route: 'projection-totals',
    type: 'PROJECTION_TOTALS',
  },
  'SALES_ORDERS_PENDING_CLIENT_APPROVAL': {
    route: 'dashboard-reports',
    type: 'SALES_ORDERS_PENDING_CLIENT_APPROVAL',
  },
  'PRESALES_PENDING_CLIENT_APPROVAL': {
    route: 'dashboard-reports',
    type: 'PRESALES_PENDING_CLIENT_APPROVAL',
  },
  'PRESALES_CLIENT_REVIEW': {
    route: 'dashboard-reports',
    type: 'PRESALES_CLIENT_REVIEW',
  },
  'OVERDUE_IN_HANDS_DATE_PROJECTS': {
    route: 'dashboard-reports',
    type: 'OVERDUE_IN_HANDS_DATE_PROJECTS',
  },
  'PROJECTS_WITH_NO_BUDGET': {
    route: 'dashboard-reports',
    type: 'PROJECTS_WITH_NO_BUDGET',
  },
  'OPEN_ORDER_STATUS_TOTALS': {
    route: 'open-order-status-totals',
    type: 'OPEN_ORDER_STATUS_TOTALS',
  },
  'POTENTIAL_REPEAT_ORDERS': {
    route: 'potential-repeat-orders',
    type: 'POTENTIAL_REPEAT_ORDERS',
  },
  'CLIENTS_WITH_NO_RECENT_ACTIVITY': {
    route: 'clients-with-no-recent-activity',
    type: 'CLIENTS_WITH_NO_RECENT_ACTIVITY',
  },
  'PO_STATUS_TOTALS': {
    route: 'po-status-totals',
    type: 'PO_STATUS_TOTALS',
  },
  'ORDER_STATUS_TOTALS': {
    route: 'order-status-totals',
    type: 'ORDER_STATUS_TOTALS',
  },
  'INVOICE_OPEN_STATUS_TOTALS': {
    route: 'order-status-totals',
    type: 'INVOICE_OPEN_STATUS_TOTALS',
    params: {
      order_type: 'INVOICE',
      status_name: 'Open',
      status_id: Status['INVOICE_OPEN'],
    },
  },
  'SALES_ORDER_READY_INVOICING_STATUS_TOTALS': {
    route: 'order-status-totals',
    type: 'SALES_ORDER_READY_INVOICING_STATUS_TOTALS',
    params: {
      order_type: 'SALES ORDER',
      status_name: 'Ready To Be Invoiced',
      status_id: Status['SALES_ORDER_READY_TO_BE_INVOICED'],
      report_sub_type: 'SALES_ORDER_READY_TO_BE_INVOICED',
    },
  },
  'SALES_ORDER_IN_PRODUCTION_STATUS_TOTALS': {
    route: 'order-status-totals',
    type: 'SALES_ORDER_IN_PRODUCTION_STATUS_TOTALS',
    params: {
      order_type: 'SALES ORDER',
      status_name: 'In Production',
      status_id: Status['SALES_ORDER_IN_PRODUCTION'],
      report_sub_type: 'SALES_ORDER_IN_PRODUCTION',
    },
  },
  'COMMISSION_TOTALS': {
    route: 'commission',
    type: 'COMMISSION_TOTALS',
    params: {},
  },
  'COMMISSION_UNPAID_TOTALS': {
    route: 'commission',
    type: 'COMMISSION_UNPAID_TOTALS',
    params: {
      commission_paid: 'unpaid',
    },
  },
  'INVOICE_UNPAID_TOTALS': {
    route: 'commission',
    type: 'INVOICE_UNPAID_TOTALS',
    params: {
      invoice_paid: 'unpaid',
    },
  },
  'CLIENTS_ON_CREDIT_HOLD': {
    route: 'clients-on-credit-hold',
    type: 'CLIENTS_ON_CREDIT_HOLD',
  },
  'DEPOSIT_INVOICES_NOT_PAID': {
    route: 'unpaid-deposits',
    type: 'DEPOSIT_INVOICES_NOT_PAID',
  },
  'DEPOSIT_INVOICES_NOT_PAID_UNINVOICED': {
    route: 'deposit-invoices',
    type: 'DEPOSIT_INVOICES_NOT_PAID_UNINVOICED',
    params: {
      report_sub_type: 'UNINVOICED_ORDERS',
      paid: 0,
    },
  },
  'DEPOSIT_INVOICES_PAID_SO_NOT_IN_PRODUCTION': {
    route: 'deposit-invoices',
    type: 'DEPOSIT_INVOICES_PAID_SO_NOT_IN_PRODUCTION',
    params: {
      report_sub_type: 'SO_NOT_IN_PRODUCTION',
      paid: 1,
    },
  },
};

const initialState = {
  data: {},
  current_types: [],
  loading: {},
  errors: {},
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReports(state, action) {
      state.data = action.payload;
    },
    fetchReportsSuccess(state, action) {
      state.data = {
        ...state.data,
        ...action.payload.reduce((acc, v) => ({
          ...acc,
          [v.report_type]: v,
        }), {}),
      };
      state.current_types = action.payload.map(v => v.report_type);
    },
    fetchReportSuccess(state, action) {
      state.data = {
        ...state.data,
        [action.payload.report_type]: {
          ...(state.data[action.payload.report_type] || {}),
          ...action.payload,
        }
      };
    },
    fetchPOReportSuccess(state, action) {
      state.data = {
        ...state.data,
        [action.payload.report_type]: {
          ...(state.data[action.payload.report_type] || {}),
          totals: {
            ...(_.get(state.data, [action.payload.report_type, 'totals'], {})),
            ...(_.get(action.payload, ['totals'], {})),
          },
          data: action.payload.data || [],
        }
      };
    },
    updateReportProjectData(state, action) {
      const reportType = action.payload.report_type;
      const projects = _.get(state.data, [reportType, 'data'], []) || [];
      const foundIdx = !isNaN(action.index) && action.payload.index > -1
        ? action.index
        : _.findIndex(projects, v => v.job_id === action.payload.job_id);
      if (foundIdx === -1) { return; }

      const found = state.data[reportType].data[foundIdx];
      state.data[reportType].data[foundIdx] = {
        ...found,
        ...action.payload.data,
        latest_order: {
          ...(found.latest_order || {}),
          ...(action.payload.data.latest_order || {}),
        },
      };
    },
    reportsFailure(state, action) {
      state.errors = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    updateLoading(state, action) {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
  },
});

export const fetchReport = (subPath, report_type, params = {}) => async (dispatch, getState) => {
  dispatch(updateLoading({[report_type]: true}));
  try {
    const { json } = await oauth('GET', `report/${subPath}`, params, null, null, null, false);

    if (json && !json.error) {
      const report = _.get(json, 'report', _.get(json, 'data', null));
      const totals = _.get(json, 'totals', null);
      const reportData = {report_type: report_type};
      if (totals !== undefined && totals !== null) {
        reportData['totals'] = totals;
      }
      if (report !== undefined && report !== null) {
        reportData['data'] = report;
      }
      dispatch(fetchReportSuccess(reportData));
    } else {
      dispatch(reportsFailure(getErrorMessageFromError(json.error, 'Cannot get reports.')));
    }
  } catch (error) {
    console.error('ERROR|fetchReport| ', error);
    dispatch(reportsFailure(getErrorMessageFromError(error, 'Cannot get reports.')));
  }
  dispatch(updateLoading({[report_type]: false}));
};

const fetchReportTotals = (subPath, report_type, params = {}) => async (dispatch, getState) => {
  dispatch(updateLoading({[report_type]: true}));
  try {
    const { json } = await oauth('GET', `report/${subPath}`, params, null, null, null, false);

    if (json && !json.error) {
      const data = _.get(json, 'totals', {});
      dispatch(fetchReportSuccess({...data, report_type}));
    } else {
      dispatch(reportsFailure(getErrorMessageFromError(json.error, 'Cannot get reports.')));
    }
  } catch (error) {
    console.error('ERROR|fetchReportTotals| ', error);
    dispatch(reportsFailure(getErrorMessageFromError(error, 'Cannot get reports.')));
  }
  dispatch(updateLoading({[report_type]: false}));
};

const fetchDashboardReports = (subPath, report_type, params = {}) => async (dispatch, getState) => {
  dispatch(updateLoading({[report_type]: true}));
  try {
    const { json } = await oauth('GET', `report/${subPath}`, params, null, null, null, false);

    if (json && !json.error) {
      const data = _.get(json, 'report', {});
      dispatch(fetchReportSuccess({...data, report_type}));
    } else {
      dispatch(reportsFailure(getErrorMessageFromError(json.error, 'Cannot get reports.')));
    }
  } catch (error) {
    console.error('ERROR|fetchDashboardReports| ', error);
    dispatch(reportsFailure(getErrorMessageFromError(error, 'Cannot get reports.')));
  }
  dispatch(updateLoading({[report_type]: false}));
};

const fetchPOReport = (subPath, report_type, params = {}) => async (dispatch, getState) => {
  dispatch(updateLoading({[report_type]: true}));
  try {
    const { json } = await oauth('GET', `report/${subPath}`, params, null, null, null, false);

    if (json && !json.error) {
      const data = _.get(json, 'report', {});
      dispatch(fetchPOReportSuccess({...data, report_type}));
      if (_.get(data, ['data'], []).length > 0) {
        dispatch(finalizeFetchAppendPOForProduction(
          _.get(data, ['data'], [])
        ));
      }
    } else {
      dispatch(reportsFailure(getErrorMessageFromError(json.error, 'Cannot get reports.')));
    }
  } catch (error) {
    console.error('ERROR|fetchPOReport| ', error);
    dispatch(reportsFailure(getErrorMessageFromError(error, 'Cannot get reports.')));
  }
  dispatch(updateLoading({[report_type]: false}));
};

function getErrorMessageFromError(error, defaultMsg = "Unable to perform this action") {
  if (typeof error === 'string') { return error; }
  if (_.get(error, 'message', false)) {
    return _.get(error, 'message', false);
  }
  if (_.get(error, 'detail', false)) {
    return _.get(error, 'detail', false);
  }
  return defaultMsg;
}

// Exports
export const getReports = state => state.reports.data;
export const getReportsLoading = s => s.reports.loading;
export const getReportsErrors = s => s.reports.errors;
export const selectReports = createSelector(getReports, s => s);
export const selectors = {
  reports: selectReports,
  loading: createSelector(getReportsLoading, s => s),
  errors: createSelector(getReportsErrors, s => s),
};

export const {
  setReports,
  fetchReportsSuccess,
  fetchReportSuccess,
  reportsFailure,
  setLoading,
  updateLoading,
  updateReportProjectData,
  fetchPOReportSuccess,
} = reportsSlice.actions;

export {
  fetchReportTotals,
  fetchDashboardReports,
};

const today = new Date();

const parseRepId = filters =>
  _.get(filters, ['rep_id'], '') === 'GROUP' ? '' : _.get(filters, ['rep_id'], '');
export function getRequestDataByReport(report_type, filters) {
  const v = REPORT_TYPE[report_type];
  switch (report_type) {
    case 'SALES_TOTALS':
      return {
        route: v.route,
        type: v.type,
        params: {
          ...filters,
          rep_id: parseRepId(filters),
        },
        cb: fetchReportTotals
      };
    case 'PROJECTION_TOTALS':
      return {
        route: v.route,
        type: v.type,
        params: {
          start_stamp: dateStr(new Date(today.getFullYear(), today.getMonth(), 1)),
          end_stamp: dateStr(new Date(today.getFullYear(), today.getMonth()+3, 1)),
          report_type: v.type,
          ...filters,
          rep_id: parseRepId(filters),
        },
        cb: fetchReportTotals
      };
    case 'ORDER_STATUS_TOTALS':
    case 'OPEN_ORDER_STATUS_TOTALS':
    case 'INVOICE_OPEN_STATUS_TOTALS':
    case 'SALES_ORDER_READY_INVOICING_STATUS_TOTALS':
    case 'SALES_ORDER_IN_PRODUCTION_STATUS_TOTALS':
      return {
        route: v.route,
        type: v.type,
        params: {
          ...(v.params || {}),
          report_type: v.type,
          ...filters,
          rep_id: parseRepId(filters),
        },
        cb: fetchReportTotals
      };
    case 'POTENTIAL_REPEAT_ORDERS':
      return {
        route: v.route,
        type: v.type,
        params: {
          report_type: v.type,
          last_year_orders: 90,
          ...filters,
          rep_id: parseRepId(filters),
        },
        cb: fetchReportTotals
      };
    case 'CLIENTS_WITH_NO_RECENT_ACTIVITY':
      return {
        route: v.route,
        type: v.type,
        params: {
          report_type: v.type,
          no_action_days: 60,
          ...filters,
          rep_id: parseRepId(filters),
        },
        cb: fetchReportTotals
      };
    case 'STUCK_IN_STAGE_TOTALS':
      return {
        route: v.route,
        type: v.type,
        params: {
          report_type: v.type,
          days: 30,
          ...filters,
          rep_id: parseRepId(filters),
        },
        cb: fetchReportTotals
      };
    case 'SALES_ORDERS_PENDING_CLIENT_APPROVAL':
    case 'PRESALES_PENDING_CLIENT_APPROVAL':
    case 'OVERDUE_IN_HANDS_DATE_PROJECTS':
    case 'PROJECTS_WITH_NO_BUDGET':
      return {
        route: v.route,
        type: v.type,
        params: {
          report_type: v.type,
          ...filters,
          rep_id: parseRepId(filters)
        },
        cb: fetchDashboardReports,
      };
    case 'PO_STATUS_TOTALS':
      return {
        route: v.route,
        type: v.type,
        params: {
          report_type: v.type,
          new_production: true,
          ...filters,
          rep_id: parseRepId(filters),
          production_rep_id: parseRepId(filters),
        },
        cb: fetchPOReport,
      };
    case 'COMMISSION_TOTALS':
    case 'COMMISSION_UNPAID_TOTALS':
    case 'INVOICE_UNPAID_TOTALS':
    case 'DEPOSIT_INVOICES_NOT_PAID':
    case 'DEPOSIT_INVOICES_NOT_PAID_UNINVOICED':
    case 'DEPOSIT_INVOICES_PAID_SO_NOT_IN_PRODUCTION':
      return {
        route: v.route,
        type: v.type,
        params: {
          date_category: 'custom',
          paid_date_category: 'custom',
          'max-results': 1000000,
          ...(v.params || {}),
          report_type: v.type,
          sales_rep_type: filters.rep_type || '',
          sales_rep_id: parseRepId(filters),
          ...filters,
          rep_id: parseRepId(filters),
        },
        cb: fetchReport,
      };
    case 'CLIENTS_ON_CREDIT_HOLD':
      return {
        route: v.route,
        type: v.type,
        params: {
          ...(v.params || {}),
          report_type: v.type,
          ...filters,
          rep_id: parseRepId(filters),
        },
        cb: fetchReport,
      };
    default:
      return null;
  }
}

export default reportsSlice.reducer;
