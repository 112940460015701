import React from 'react';
import { Col } from '@commonsku/styles';
import { DashboardSalesTotalsTile, MarketingTile } from '../../helpers';
import WelcomeDashboardTile from '../WelcomeDashboardTile';

const TILE_MAX_HEIGHT = 170;

function ReportsTotalsTiles({
  salesReports={},
  marketingTile={},
  onClickTile,
}) {

  return (<>
    <Col xs sm={6} md={4} lg={2} padded>
      <DashboardSalesTotalsTile
        style={{height: TILE_MAX_HEIGHT}}
        title="Pre-sales Total"
        prevTotalCount={salesReports.presales.prevTotalCount}
        prevTotalCurrency={salesReports.presales.prevTotalCurrency}
        totalCount={salesReports.presales.totalCount}
        totalCurrency={salesReports.presales.totalCurrency}
        onClick={() => {
          onClickTile && onClickTile({
            report_type: 'SALES_TOTALS',
            report_sub_type: 'PRE-SALES',
            title: 'Pre-sales',
          });
        }}
      />
    </Col>
    <Col xs sm={6} md={4} lg={2} padded>
      <DashboardSalesTotalsTile
        style={{height: TILE_MAX_HEIGHT}}
        title="In Production Total"
        prevTotalCount={salesReports.inProduction.prevTotalCount}
        prevTotalCurrency={salesReports.inProduction.prevTotalCurrency}
        totalCount={salesReports.inProduction.totalCount}
        totalCurrency={salesReports.inProduction.totalCurrency}
        onClick={() => {
          onClickTile && onClickTile({
            report_type: 'SALES_TOTALS',
            report_sub_type: 'IN-PRODUCTION',
            title: 'In Production',
          });
        }}
      />
    </Col>
    <Col xs sm={6} md={4} lg={2} padded>
      <DashboardSalesTotalsTile
        style={{height: TILE_MAX_HEIGHT}}
        title="Invoice Total"
        prevTotalCount={salesReports.invoices.prevTotalCount}
        prevTotalCurrency={salesReports.invoices.prevTotalCurrency}
        totalCount={salesReports.invoices.totalCount}
        totalCurrency={salesReports.invoices.totalCurrency}
        onClick={() => {
          onClickTile && onClickTile({
            report_type: 'SALES_TOTALS',
            report_sub_type: 'INVOICE',
            title: 'Invoice',
          });
        }}
      />
    </Col>

    <Col xs sm={6} md={4} lg={2} padded>
      <WelcomeDashboardTile maxHeight={TILE_MAX_HEIGHT} />
    </Col>

    <Col xs md={8} lg={4} style={{
      paddingTop: '0.5rem',
      paddingLeft: '0.5rem',
      alignSelf: 'center',
    }}>
      <MarketingTile
        link={marketingTile.link}
        title={marketingTile.title}
        imgSrc={marketingTile.imgSrc}
        maxHeight={TILE_MAX_HEIGHT}
      />
    </Col>
  </>);
}

export default ReportsTotalsTiles;
