import _ from 'lodash';
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Button, CheckMark, Col, colors, Dropdown, H1, Radio, Row, SidePanel, Text, themeOptions } from '@commonsku/styles';
import { LabeledSelect } from '../helpers';
import DateInput, { LabeledDateInput } from '../DateInput';
import { getNoteName, isNoteComplete, selectors as notesSelectors } from '../../redux/notes';
import { dateStr, formatFriendlyDateTime, getUserImageSrc, mentionLinkToStr, parseDate } from '../../utils';
import Img from '../Img';
import MentionTextarea from '../MentionTextarea';
import { createMessage, fetchMessage, deleteMessage } from '../../redux/messages';
import { getUserDropdown, getUserOptions } from '../../selectors/dropdowns';
import { useIdentity } from '../../hooks';

import { window } from '../../global';

function NoteSidepanel({ noteId, clearnoteId, onUpdateNote, }) {
  const dispatch = useDispatch();
  const identity = useIdentity();

  const note = useSelector(s => notesSelectors.noteById(s, noteId));
  const users_options = useSelector(s => getUserOptions(s).map(v => ({
    label: v.value,
    value: v.key,
  })));
  const mention_users = useSelector(getUserDropdown);
  const [messageText, setMessageText] = useState(_.get(note, 'message_text', '') || '');
  const [showAddComment, setShowAddComment] = useState(false);
  const [replyComment, setReplyComment] = useState();

  useEffect(() => {
    if (!noteId) { return; }
    if (!note) { return; }

    mentionLinkToStr((_.get(note, 'message_text', '') || '').replace(/<br(( )?\/)?>/g, '')).then(res => {
      setMessageText(res);
    });
  }, [note, noteId]);

  const completed = isNoteComplete(note || {});
  const noteFile = _.get(note, 'file', false);

  const addComment = (message_id, recipient_id) => {
    if (!message_id || !recipient_id) { return; }

    Promise.resolve(dispatch(createMessage({
      details_type: "NEWSFEED MESSAGE",
      message_text: replyComment,
      parent_id: message_id,
      parent_type: "message",
      recipient_id: recipient_id,
      recipient_type: "USER",
      reminder_complete: 0,
      tagged_users: "",
    }))).then(resp => {
      return dispatch(fetchMessage(note.message_id));
    }).then(res => {
      setShowAddComment(false);
      setReplyComment('');
    });
  };

  const replies = React.useMemo(() => {
    return _.orderBy(
      _.get(note, 'replies', []),
      [v => parseDate(v.date_created, 'YYYY-MM-DD hh:mm:ss')],
      ['desc']
    );
  }, [noteId, note]);

  const onClose = React.useCallback(() => {
    clearnoteId(false);
  }, [clearnoteId]);

  const taskHeaderData = React.useMemo(() => {
    if (!note) { return null; }

    const parentType = _.get(note, 'parent_type', '');
    let result = {
      parent_type: parentType,
      showActions: false,
      title: 'Task',
      subtitle: getNoteName(note),
      menuItems: [],
    };

    if (parentType === 'CLIENT') {
      result.showActions = true;
      result.title = 'Client Task';
      result.menuItems.push({
        content: 'View Client',
        onClick: () => {
          window.open(`/client.php?id=${note.parent_id}`, '_blank');
        },
      });
    } else if (parentType === 'JOB') {
      result.showActions = true;
      result.title = 'Project Task';
      if (note.client_name) {
        result.subtitle = `${_.get(note, 'client_name', '')} - `;
      }
      result.subtitle += `Project #${_.get(note, 'job_number', '')} - ${_.get(note, 'job_name', '')}`;
      result.menuItems.push({
        content: 'View Project',
        onClick: () => {
          window.open(`/project/${note.job_number}`, '_blank');
        },
      });
    } else if (parentType === 'PURCHASE ORDER') {
      result.showActions = true;
      result.title = 'PO Task';
      if (note.job_number) {
        if (note.client_name) {
          result.subtitle = `${_.get(note, 'client_name', '')} - `;
        }
        result.subtitle += `Project #${_.get(note, 'job_number', '')} - ${_.get(note, 'job_name', '')}`;
      }
      result.menuItems.push({
        content: 'View PO',
        onClick: () => {
          window.open(`/purchase_order.php?id=${note.parent_id}`, '_blank');
        },
      });
      if (note.job_number) {
        result.menuItems.unshift({
          content: 'View Project',
          onClick: () => {
            window.open(`/project/${note.job_number}/production`, '_blank');
          },
        });
      }
      if (note.client_id) {
        result.menuItems.push({
          content: 'View Client',
          onClick: () => {
            window.open(`/client.php?id=${note.client_id}`, '_blank');
          },
        });
      }
    }

    if (identity.user_id === note.created_by) {
      result.showActions = true;
      result.menuItems.push({
        content: 'Delete Task',
        onClick: () => {
          const canDelete = window.confirm('Are you sure you want to delete?');
          if (canDelete) {
            dispatch(deleteMessage(note.message_id, note.note_id));
            onClose();
          }
        },
      });
    }

    return result;
  }, [dispatch, identity, noteId, note, onClose]);

  const handleNoteTextBlur = useCallback((value) => {
    setMessageText((prevState) => {
      if (prevState !== value) {
        onUpdateNote({
          note_id: noteId,
          message_text: value,
        });
        return value;
      } else { return prevState; }
    });
  }, [noteId, onUpdateNote]);

  return (
    <SidePanel
      visible={noteId}
      header={<Row>
        <Col xs sm={5} style={{textAlign: 'left'}}>
        <div style={{display: 'inline-block', paddingBottom: 22,}}>
            <Radio type="checkbox" name="note-checkbox" checked={completed} />
            <CheckMark
              checked={completed}
              style={{ top: 'auto', right: 'auto', bottom: 'auto', left: 'auto', }}
              onClick={e => {
                onUpdateNote({
                  note_id: noteId,
                  reminder_complete: completed ? 0 : 1,
                  date_complete: completed ? '' : dateStr(new Date()),
                });
              }}
            />
          </div>
          <div style={{paddingLeft: 30, display: 'inline-block'}}>
            <H1 style={{
              paddingBottom: 10,
              marginTop: 0,
              marginBottom: 0,
            }}>{_.get(taskHeaderData, ['title'], false)}</H1>
          </div>
        </Col>
        <Col xs sm={7} style={{textAlign: 'right', paddingBottom: 10}}>
          <Row>
            {_.get(taskHeaderData, ['showActions'], false) ? <Col xs={7.5}>
              <Dropdown
                sm={'width: 100%; margin-top: 10px;'}
                style={{marginRight: '10px', zIndex: 105}}
                items={_.get(taskHeaderData, ['menuItems'], [])}
              />
            </Col> : null}
            <Col xs={_.get(taskHeaderData, ['showActions'], false) ? 4.5 : 12}>
              <Button variant='primary' onClick={onClose}>Close</Button>
            </Col>
          </Row>
        </Col>

        <Col xs style={{textAlign: 'left', paddingTop: 5, paddingBottom: 10}}>
          {_.get(taskHeaderData, ['subtitle'], '')}
        </Col>
      </Row>}
    >
      <Row>
        {noteFile ? <>
            <Col padded xs>
            <div style={{
              border: '1px solid #EEF6F8',
              boxSizing: 'border-box',
              borderRadius: '5px',
              width: '100%',
              padding: 20,
            }}>
              <a target="_blank" rel="noreferrer" href={noteFile.file_name_original} style={{textDecoration: 'none'}}>
                <Text style={{color: colors.primary}}>{noteFile.file_display_name}</Text>
              </a>
            </div>
          </Col>
          <Col xs><hr style={{border: '1px solid #F4F4F4'}} /></Col>
        </> : null}
        <Col padded xs className="resku">
          <MentionTextarea
            placeholder="Post a note"
            onBlur={handleNoteTextBlur}
            mention_users={mention_users}
            value={messageText}
          />
        </Col>
        <Col padded xs md={6}>
          <DateInput
            portalId="root-portal"
            customInput={<LabeledDateInput date={_.get(note, 'date_reminder', '')} label="Due Date" />}
            showMonthDropdown
            showYearDropdown
            value={_.get(note, 'date_reminder', '')}
            onChange={dt => onUpdateNote({note_id: noteId, date_reminder: dt})}
          />
        </Col>
        <Col padded xs md={6}>
          <LabeledSelect
            label="Assigned to"
            name="sidepanel_note_assigned_to_select"
            options={users_options}
            value={_.get(note, 'reminder_user', '')}
            onChange={v => onUpdateNote({note_id: noteId, reminder_user: v.value})}
          />
        </Col>

        <Col padded xs>
          <Button
            style={{width: '100%', background: '#EEFDFF', color: colors.primary, boxShadow: '0px 2px 4px rgba(22, 112, 125, 0.12)', border: 'none'}}
            onClick={e => setShowAddComment(s => !s)}
          >Add Comment</Button>
        </Col>

        {showAddComment ? <Col xs style={{
          borderBottom: '1px solid #e0e7eb',
          padding: 10,
          marginBottom: 16,
          background: '#edf2f4',
          borderRadius: 4,
          boxShadow: '0 2px 4px rgb(0 0 0 / 7%)',
        }}>
          <Row>
            <Col padded xs className="resku">
              <MentionTextarea placeholder="Post a note"
                mention_users={mention_users}
                value={replyComment}
                onChange={value => setReplyComment(value)} />
            </Col>
            <Col padded xs style={{textAlign: 'right'}}>
              <Button onClick={e => addComment(note.message_id, _.get(note, 'actor.user_id', '') || _.get(note, 'user_id', ''))}
              >Comment</Button>
            </Col>
          </Row>
        </Col>: null}

        <Col padded xs>
          {replies.map((r, i) => {
            const message = (r.message_text || '')
              .replace(/(href=['"])(?!https?:)/, "$1/")
              .replace(/<a /, '<a target="_blank" ');
            return (
              <div key={`${i}_${r.message_id}`} style={{paddingBottom: 20,}}>
                <Img
                  src={r.actor.user_image ? getUserImageSrc(r.actor, 'small') : '/images/user-avatar3-48x48.png'}
                  alt={`User ${_.get(r.actor, 'user_first_name', '')}`}
                  style={{verticalAlign: 'top', borderRadius: '50%', height: 48, width: 48}}
                />
                <span style={{display: 'inline-block', paddingLeft: '10px', minWidth: 435}}>
                  <span style={{fontWeight: 'bold', display: 'block'}}>
                    {_.get(r.actor, 'user_first_name', '')} {_.get(r.actor, 'user_last_name', '')}
                    <span style={{fontWeight: 'normal', textAlign: 'right', float: 'right', color: '#B7B7B7', fontFamily: themeOptions.fontFamilies.regular}}>
                      {formatFriendlyDateTime(r.date)}
                    </span>
                  </span>
                  <span style={{display: 'block', fontFamily: themeOptions.fontFamilies.regular}} dangerouslySetInnerHTML={{__html: message}} />
                </span>
              </div>
            );
          })}
        </Col>
      </Row>
    </SidePanel>
  );
}

export default NoteSidepanel;
