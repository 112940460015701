import _ from 'lodash';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotes, selectors, updateNote } from '../../redux/notes';
import { dateStr, getFirstDayOfWeek, getLastDayOfWeek } from "../../utils";
import Avatar from '../helpers/Avatar';
import getUsers from './getUsers';

export default function useCalendarNotes({ identity, teamId=null, userId=null }) {
  const dispatch = useDispatch();
  const loading = useSelector(s => selectors.loading(s).notes);
  const tasks = useSelector(s => {
    const users = getUsers(s, teamId, userId);
    const result = selectors.calendarNotesByReminderUsers(s, users);
    if (!userId || teamId) {
      return result.map(n => ({
        ...n,
        title: <span>
          <Avatar
            name={_.get(n, 'reminder_user_full_name', n.title)}
            style={{display: 'inline-flex', fontSize: '0.85rem', height: 28, width: 28}}
            data-tip={_.get(n, 'reminder_user_full_name', n.title) || ""}
            data-for={"note-rep-avatar"}
          />
          <span>{n.title}</span>
        </span>,
      }));
    }
    return result;
  });

  const overdueTasks = useSelector(s => {
    const users = getUsers(s, teamId, userId);
    const result = selectors.overdueCalendarNotesByReminderUsers(s, users);
    if (!userId || teamId) {
      return result.map(n => ({
        ...n,
        title: (!userId || teamId) ? <span>
          <Avatar
            name={_.get(n, 'reminder_user_full_name', n.title)}
            style={{display: 'inline-flex', fontSize: '0.85rem', height: 28, width: 28}}
            data-tip={_.get(n, 'reminder_user_full_name', n.title) || ""}
            data-for={"note-rep-avatar"}
          />
          <span>{n.title}</span>
        </span> : n.title,
      }));
    }
    return result;
  });

  const getNotes = React.useCallback((params={}) => {
    if (userId === 'GROUP' && !teamId) { return; }

    dispatch(fetchNotes({
      start_stamp_reminder: dateStr(getFirstDayOfWeek(new Date())),
      end_stamp_reminder: dateStr(getLastDayOfWeek(new Date())),
      include_complete: true,
      note_type: 'ALL REMINDERS',
      reminder_user_id: userId,
      reminder_user_team_id: teamId,
      tenant_id: identity.company_id,
      max_results: 1000,
      with_po: true,
      with_parent_data: true,
      ...params,
    }));
  }, [dispatch, userId, teamId, identity]);

  const onUpdate = (note) => {
    dispatch(updateNote(note.note_id, note));
  };

  return {
    loading,
    tasks,
    overdueTasks,
    getNotes,
    onUpdate,
  };
}
