import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { oauth } from '../utils';

const initialState = {
    data: {},
    current_ids: [],
    errors: {},
};

const slice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setStatus(state, action) {
        state.data = action.payload;
    },
    fetchStatusSuccess(state, action) {
        state.data = {
            ...state.data,
            ...action.payload.reduce((acc, v) => ({
                ...acc,
                [v.status_id]: v,
            }), {}),
        };
        state.current_ids = action.payload.map(v => v.status_id);
    },
    statusFailure(state, action) {
        state.errors = action.payload;
    },
  },
});

const fetchStatus = (params = {}) => async (dispatch, getState) => {
    try {
        const { json } = await oauth('INDEX', 'status', params);

        const data = _.get(json, 'status', []);
        dispatch(fetchStatusSuccess(data));
        return data;
    } catch (error) {
        console.error('ERROR|fetchStatus| ', error);
        dispatch(statusFailure('Cannot get status.'));
    }

    return null;
};

const fetchAllStatus = (params = {}) => async (dispatch, getState) => {
    try {
        const { json } = await oauth('GET', 'status/all-status', params);

        const data = _.get(json, 'status', []);
        dispatch(fetchStatusSuccess(data));
        return data;
    } catch (error) {
        console.error('ERROR|fetchStatus| ', error);
        dispatch(statusFailure('Cannot get status.'));
    }

    return null;
};

const getStatus = s => _.get(s, 'status.data', {});
const getStatusValues = s => Object.values(s.status.data);
const getOrderdStatus = s => _.orderBy(s, [v => parseInt(v.flow_order)], ['asc']);

const selectors = {
    status: createSelector(getStatus, s => s),
    byId: createSelector([getStatus, (s, id) => id], (s, id) => s[id]),
    options: createSelector(getStatus,
        s => Object.values(s)
            .map(v => ({label: v.status_name, value: v.status_id}))
    ),
    optionsOrderedByType: createSelector(
        [s => getStatusValues(s), (s, parentType) => parentType],
        (s, parent_type) => getOrderdStatus(s)
            .filter(v => v.parent_type === parent_type)
            .map(v => ({label: v.status_name, value: v.status_id})),
    ),
};

export const {
    setStatus,
    fetchStatusSuccess,
    statusFailure,
} = slice.actions;
export {
    fetchStatus,
    fetchAllStatus,
    selectors,
};
export default slice.reducer;
