import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Col, Row,
  Popup, Text, Loading,
  Thermometer,
  TabBar,
  Tab,
  colors,
} from '@commonsku/styles';
import { useDispatch, useSelector } from 'react-redux';
import { dateStr, formatDashboardMoney, oauth } from '../../utils';
import { useIdentity, useUserFlags } from '../../hooks';
import {
  REPORT_TYPE, selectors as reportsSelectors, getRequestDataByReport
} from '../../redux/reports';
import { updateNote } from '../../redux/notes';
import { LabeledSelect, DashboardTile, rebuildTooltip } from '../helpers';
import { parseReports, REP_TYPE_OPTIONS } from './utils';
import ProjectsTableWindowed from './ProjectsTableWindowed';
import ReportsNumberTiles from './Sales/ReportsNumberTiles';
import ReportsTotalsTiles from './Sales/ReportsTotalsTiles';
import { AddTaskFormPopup, LazyNotesCalendar, NoteSidepanel } from '../message/';
import DashboardPageTitle from './DashboardPageTitle';
import {
  OpenOrdersCharts,
  ProjectionChart,
  StuckInStageChart,
} from './charts';
import ClientsTableWindowed from './ClientsTableWindowed';
import NewProjectPopup, { NewProjectPopupButton } from '../project/NewProjectPopup';
import { getUserOptions } from '../../selectors/dropdowns';
import { month_names } from '../../containers/AnalyticsHelper';

const today = new Date();
const report_types = [
  'SALES_TOTALS',
  'PROJECTION_TOTALS',
  'STUCK_IN_STAGE_TOTALS',
  'POTENTIAL_REPEAT_ORDERS',
  'CLIENTS_WITH_NO_RECENT_ACTIVITY',
  'SALES_ORDERS_PENDING_CLIENT_APPROVAL',
  'PRESALES_PENDING_CLIENT_APPROVAL',
  'OVERDUE_IN_HANDS_DATE_PROJECTS',
  'PROJECTS_WITH_NO_BUDGET',
  'OPEN_ORDER_STATUS_TOTALS',
];

const ORDER_REP_TYPE_FLAG = 'MANAGEMENT_DASHBOARD_ORDER_REP_TYPE';
const CLIENT_REP_TYPE_FLAG = 'MANAGEMENT_DASHBOARD_CLIENT_REP_TYPE';

function Dashboard({ ...props }) {
  const dispatch = useDispatch();
  const identity = useIdentity();
  const reps_options = useSelector(s => {
    const users = getUserOptions(s).map(v => ({
      value: v.key,
      label: v.value,
    }));
    return [{label: 'All', value: ''}, {label: 'Group', value: 'GROUP'}].concat(users);
  });
  const statuses = useSelector(s => s.entities.statuses);
  const team_options = useSelector(
    s => Object.values(s.entities.teams).map(v => ({
      value: v.team_id,
      label: v.team_name,
    }))
  );
  const isLoading = useSelector(s => {
    const rLoading = reportsSelectors.loading(s);
    return report_types.filter(
      v => _.get(rLoading, v, false)
    ).length > 0;
  });
  const loading = useSelector(reportsSelectors.loading);
  const reports = useSelector(reportsSelectors.reports);

  const {
    hasUserFlag,
    onDeleteAddFlag,
  } = useUserFlags(identity);
  const defaultOrderRepType = hasUserFlag(ORDER_REP_TYPE_FLAG);

  const [filters, setFilters] = useState({
    rep_type: defaultOrderRepType ? 'ORDER' : 'CLIENT',
    rep_id: '',
    team_id: '',
  });
  const [selectedTaskId, setSelectedTaskId] = useState(false);
  const [showPopup, setShowPopup] = useState(null);
  const [showLoading, setShowLoading] = useState(true);
  const [reloadReport, setReloadReport] = useState(false);
  const [selectedTab, setSelectedTab] = useState('OPEN_SALES_ORDERS');
  const [marketingTile, setMarketingTile] = useState({
    imgSrc: '',
    title: '',
    text: '',
    link: '',
    dashboard: '',
  });

  const onUpdateTask = (note) => {
    setShowLoading(false);
    dispatch(updateNote(note.note_id, note));
  };

  useEffect(() => {
    oauth('GET', 'marketing-setting/dashboard-marketing', {dashboard_type: 'MANAGEMENT'})
      .then(resp => {
        const { json } = resp;
        setMarketingTile(json.marketing);
      })
      .catch(err => {
        console.error(err);
      });
  }, [dispatch, identity]);

  useEffect(() => {
    rebuildTooltip();
    if (filters.rep_id === 'GROUP' && !filters.team_id) {
      return;
    }

    setShowLoading(true);
    const reqs = Object.values(REPORT_TYPE)
      .filter(v => report_types.includes(v.type))
      .reduce((acc, v) => {
        const reqData = getRequestDataByReport(v.type, filters);
        if (!reqData) { return acc; }
        return [...acc, reqData];
      }, []);

    (async () => {
      await Promise.all(
        reqs.map(
          v => dispatch(v.cb(v.route, v.type, v.params))
        )
      );
    })();
  }, [dispatch, filters]);

  useEffect(() => {
    const delete_flag = filters.rep_type === 'ORDER' ? CLIENT_REP_TYPE_FLAG : ORDER_REP_TYPE_FLAG;
    const add_flag = filters.rep_type === 'ORDER' ? ORDER_REP_TYPE_FLAG : CLIENT_REP_TYPE_FLAG;
    onDeleteAddFlag(delete_flag, add_flag);
  }, [filters.rep_type, onDeleteAddFlag]);

  function renderFilters() {
    return (
      <Row style={{ marginTop: 0, marginBottom: 0, }}>
        <Col xs md={4} padded>
          <LabeledSelect
            name="dashboard-rep-type"
            options={REP_TYPE_OPTIONS}
            value={filters.rep_type}
            onChange={v => {
              setFilters(s => ({...s, rep_type: v ? v.value : '' }));
            }}
            label="Rep Type" className="csku-select-v3"
          />
        </Col>
        <Col xs md={4} padded>
          <LabeledSelect
            name="dashboard-rep"
            options={reps_options}
            value={filters.rep_id}
            onChange={v => {
              const val = _.get(v, 'value', '');
              // reset team_id, if rep_id change
              if (val !== 'GROUP') {
                setFilters(s => ({...s, rep_id: val, team_id: '', }));
              } else {
                setFilters(s => ({...s, rep_id: val }));
              }
            }}
            label="Rep"
            className="csku-select-v3"
            isClearable={filters.rep_id}
          />
        </Col>
        {filters.rep_id === 'GROUP' ? <Col xs md={4} padded>
          <LabeledSelect
            name="dashboard-team_id"
            options={team_options}
            value={filters.team_id}
            onChange={v => setFilters(s => ({...s, team_id: v ? v.value : '' }))}
            label="Group"
            className="csku-select-v3"
            isClearable={filters.team_id}
          />
        </Col> : null}
      </Row>
    );
  }

  const onClickStuckInStageChartColumn = (data) => {
    if (!_.get(data, 'order_type', '')) { return; }
    setShowLoading(false);
    const order_type = data.order_type;
    const report_type = REPORT_TYPE.STUCK_IN_STAGE_TOTALS;
    const title = data.title;
    const reqData = getRequestDataByReport('STUCK_IN_STAGE_TOTALS', filters);
    if (!reqData) { return; }
    dispatch(reqData.cb(reqData.route, reqData.type, {
      ...reqData.params,
      report_type: 'STUCK_IN_STAGE_TOTALS',
      get_data: 1,
      days: 30,
      order_type,
    }));

    setShowPopup({
      type: 'projects-list',
      title: 'Stuck in Stage - ' + title,
      report_type: report_type.type,
      order_type: order_type,
      loading: loading[report_type.type],
    });
  };

  const onClickProjectionChartColumn = (data, order_type=null) => {
    if(!_.get(data, 'month_name', '')) { return; }
    setShowLoading(false);

    const report_type = REPORT_TYPE.PROJECTION_TOTALS;
    const title = data.month_name;

    const end_of_month = new Date(parseInt(data.year), parseInt(data.month), 1);
    end_of_month.setDate(end_of_month.getDate()-1);

    const reqData = getRequestDataByReport('PROJECTION_TOTALS', filters);
    if (!reqData) { return; }
    dispatch(reqData.cb(reqData.route, reqData.type, {
      ...reqData.params,
      report_type: 'PROJECTION_TOTALS',
      get_data: 1,
      start_stamp: dateStr(new Date(parseInt(data.year), parseInt(data.month)-1, 1)),
      end_stamp: dateStr(end_of_month),
      order_type: order_type || "",
    }));
    setShowPopup({
      type: 'projects-list',
      title: 'Projection - ' + title,
      report_type: report_type.type,
      loading: loading[report_type.type],
      order_type: order_type,
    });
  };

  const onClickOpenOrderChartColumn = (data) => {
    if (!_.get(data, 'order_type', '')) { return; }
    setShowLoading(false);
    const order_type = data.order_type;
    const title = data.title;
    const status_name = data.status_name;

    const reqData = getRequestDataByReport('OPEN_ORDER_STATUS_TOTALS', filters);
    if (!reqData) { return; }
    dispatch(reqData.cb(reqData.route, reqData.type, {
      ...reqData.params,
      report_type: 'OPEN_ORDER_STATUS_TOTALS',
      get_data: 1,
      order_type,
      status_name,
    }));

    setShowPopup({
      type: 'projects-list',
      report_type: 'OPEN_ORDER_STATUS_TOTALS',
      loading: loading['OPEN_ORDER_STATUS_TOTALS'],
      title: 'Open Orders - ' + title,
      order_type: order_type,
    });
  };

  function getSelectedTabStyles(tab) {
    if (selectedTab !== tab) {
      return {opacity: 0, height: 0, display: 'none'};
    }
    return {opacity: 1, transition: '300ms opacity ease-out'};
  }

  function renderContent() {
    const salesReports = parseReports(reports);
    const currentMonthSalesTarget = parseFloat((_.find(
      _.get(reports, 'PROJECTION_TOTALS.sales_target', []),
      v => v.year == today.getFullYear() && v.month == today.getMonth()+1
    ) || {target: "0.0000"}).target || 0);
    const currentMonthInvoices = parseFloat((_.find(
      _.get(reports, 'PROJECTION_TOTALS.orders', []),
      v => v.year == today.getFullYear()
        && v.month == today.getMonth()+1
        && v.order_type == 'INVOICE'
    ) || {total: "0.0000"}).total || 0);
    const currentMonthSalesOrdersTarget = parseFloat((_.find(
      _.get(reports, 'PROJECTION_TOTALS.sales_target', []),
      v => v.year == today.getFullYear() && v.month == today.getMonth()+1
    ) || {sales_orders_target: "0.0000"}).sales_orders_target || 0);
    const currentMonthSalesOrders = parseFloat((_.find(
      _.get(reports, 'PROJECTION_TOTALS.sales_orders_in_production_analytics', []),
      v => v.next_year == today.getFullYear()
        && v.month == month_names[today.getMonth()].substring(0,3)
    ) || {total_next_year: "0.0000"}).total_next_year || 0);

    return (
      <Row>
        {filters.rep_type === 'CLIENT' ? <Col xs padded>
          {identity.show_invoice_targets == true ?
          <Thermometer
            value={currentMonthInvoices}
            valueLabel="Invoices this month:"
            target={currentMonthSalesTarget}
            style={{marginBottom: identity.show_sales_order_targets == true ? '1rem' : ''}}
          /> : null}
           {identity.show_sales_order_targets == true ?
           <Thermometer
            value={currentMonthSalesOrders}
            valueLabel="Sales orders in production:"
            target={currentMonthSalesOrdersTarget}
            barColor = '#FFD302'
            labelTextColor ='#C69B00'
            isSecondary={true}

          /> :  null}
        </Col> : null}
        <ReportsTotalsTiles
          salesReports={salesReports}
          marketingTile={marketingTile}
          onClickTile={(show) => {
            setShowLoading(false);
            if (show) {
              const reqData = getRequestDataByReport('SALES_TOTALS', filters);
              if (!reqData) { return; }
              dispatch(reqData.cb(reqData.route, reqData.type, {
                ...reqData.params,
                report_type: 'SALES_TOTALS',
                report_sub_type: show.report_sub_type,
                get_data: 1,
              }));

              setShowPopup({
                type: 'projects-list',
                report_type: 'SALES_TOTALS',
                loading: loading['SALES_TOTALS'],
                ...show
              });
              return;
            }
            setShowPopup(show);
          }}
        />

        <Col padded xs lg={6}>
          <DashboardTile style={{paddingBottom: 40}}>
            <TabBar style={{marginBottom: 10, borderBottomColor: colors.errors.main, fontSize: 16}}>
              <Tab
                selected={selectedTab === 'OPEN_SALES_ORDERS'}
                onClick={() => { setSelectedTab('OPEN_SALES_ORDERS'); }}
              >Open Sales Orders</Tab>
              <Tab
                selected={selectedTab === 'OPEN_PRE_SALES'}
                onClick={() => { setSelectedTab('OPEN_PRE_SALES'); }}
              >Open Pre-sales</Tab>
              <Tab
                selected={selectedTab === 'STUCK_IN_STAGE'}
                onClick={() => { setSelectedTab('STUCK_IN_STAGE'); }}
              >Stuck In Stage</Tab>
            </TabBar>

            <div style={{ ...getSelectedTabStyles('STUCK_IN_STAGE') }}>
              {/* <Text as="p" style={{ marginTop: 0, fontSize: 18, fontWeight: 'bold', }}>Stuck in Stage (30+ days)</Text> */}
              <StuckInStageChart
                statuses={statuses}
                data={_.get(reports, 'STUCK_IN_STAGE_TOTALS.summary', {})}
                onClickColumn={onClickStuckInStageChartColumn}
              />
            </div>

            <div style={{ ...getSelectedTabStyles('OPEN_SALES_ORDERS') }}>
              <OpenOrdersCharts
                statuses={statuses}
                data={_.get(reports, 'OPEN_ORDER_STATUS_TOTALS.summary', {})}
                order_type="SALES ORDER"
                onClickColumn={onClickOpenOrderChartColumn}
              />
            </div>

            <div style={{ ...getSelectedTabStyles('OPEN_PRE_SALES') }}>
              <OpenOrdersCharts
                statuses={statuses}
                data={_.get(reports, 'OPEN_ORDER_STATUS_TOTALS.summary', {})}
                order_type="PRE-SALES"
                onClickColumn={onClickOpenOrderChartColumn}
              />
            </div>
          </DashboardTile>
        </Col>

        <Col padded xs lg={6}>
          <DashboardTile>
            <Text as="p" style={{ marginTop: 0, fontSize: 16, fontWeight: 'bold', padding: 16 }}>Projection</Text>
            <ProjectionChart
              salesTargets={_.get(reports, 'PROJECTION_TOTALS.sales_target', [])}
              orders={_.get(reports, 'PROJECTION_TOTALS.orders', [])}
              onClickColumn={onClickProjectionChartColumn}
              height={"380px"}
            />
          </DashboardTile>
        </Col>

        <Col xs>
          <ReportsNumberTiles
            salesReports={salesReports}
            salesRepId={filters.rep_id}
            salesTeamId={filters.team_id}
            salesRepType={filters.rep_type}
            onClickTile={(show) => {
              setShowLoading(false);
              setShowPopup(show);
            }}
            marketingTile={marketingTile}
          />
        </Col>

        <Col xs padded>
          <DashboardTile padded>
            <LazyNotesCalendar
              noteId={selectedTaskId}
              onClickNote={setSelectedTaskId}
              repType={filters.rep_type}
              noteUserId={filters.rep_id}
              noteUserTeamId={filters.team_id}
              onAddTask={e => {
                setShowLoading(false);
                setShowPopup({action: 'add_task'});
              }}
              onChangeWeek={() => setShowLoading(false)}
            />
          </DashboardTile>
        </Col>
      </Row>
    );
  }

  function renderPopup() {
    if (!showPopup) { return null; }

    if (showPopup.action === 'add_task') {
      return (
        <AddTaskFormPopup onClose={() => setShowPopup(false)} message={{
          details_type: 'NOTE',
          note_type: 'NOTE',
        }} />
      );
    }
    if (showPopup.action === 'new-project') {
      return (
        <NewProjectPopup onClose={() => setShowPopup(false)} />
      );
    }

    if (!['projects-list', 'clients-list'].includes(showPopup.type)) {
      return null;
    }

    let Child = null;
    if (showPopup.type === 'projects-list') {
      Child = <ProjectsTableWindowed
        {...showPopup}
        onSave={() => setShowPopup(false)}
        loadReportTotal={reportType => setReloadReport(reportType)}
      />;
    } else if (showPopup.type === 'clients-list') {
      Child = <ClientsTableWindowed
        {...showPopup}
        onSave={() => setShowPopup(false)}
      />;
    }

    return (
      <Popup
        title={showPopup.title}
        onClose={() => {
          setShowPopup(false);
          if (reloadReport) {
            const reqData = getRequestDataByReport(reloadReport, filters);
            if (!reqData) { return reqData; }
            dispatch(reqData.cb(reqData.route, reqData.type, reqData.params));
          }
        }}
        className={`popup ${showPopup.type}-popup csku-styles`}
      >{Child}<br /></Popup>
    );
  }

  return (<Row>
    <Col xs padded>
      <NoteSidepanel
        noteId={selectedTaskId}
        clearnoteId={() => setSelectedTaskId(false)}
        onUpdateNote={onUpdateTask}
      />
      {renderPopup()}
      <Row style={{ marginTop: 0, marginBottom: 20, }}>
        <Col xs sm={6} padded>
          <DashboardPageTitle title={"Management Dashboard"} />
        </Col>
        <Col xs
          sm={6}
          padded
          end={1}
        ><NewProjectPopupButton /></Col>
      </Row>
      {renderFilters()}
      {isLoading && showLoading ? <>
        <Loading />
        <p style={{
          padding: 0,
          margin: 0,
          textAlign: 'center',
          color: '#00A0B6',
          fontSize: '1.3rem',
        }}>Loading...</p>
      </> : renderContent()}
    </Col>
  </Row>);
}

export default Dashboard;
