import _ from 'lodash';
import ReactDOM from 'react-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { colors, Link, Loading, Row, Col, SimpleWindowedTable, SimpleWindowedTableStyles } from '@commonsku/styles';
import Tooltip from 'react-tooltip';
import { formatDashboardMoney, getImageSrc, } from '../../utils';
import Avatar, { AvatarsWrapper } from '../helpers/Avatar';
import { rebuildTooltip } from '../helpers';
import useWindowSize from '../../hooks/useWindowSize';
import { document } from '../../global';
import moment from 'moment';
import ProjectSidepanel from '../project/ProjectSidepanel';

function isObj(obj) {
  return typeof obj === 'object' && _.keys(obj).length > 0;
}

const SalesRepPic = ({
  row,
  style={},
}) => {
  const sales_rep_id = row.original.sales_rep_id || row.original.user_id;
  const sales_rep_name = (
    (row.original.sales_rep_first_name || '')
    + " "
    + (row.original.sales_rep_last_name || '')
  ).trim();

  const avatarProps = React.useMemo(() => {
    const sales_rep_file = row.original.order_rep_file;
    const sales_rep_img_paths = row.original.order_rep_user_image_paths;
    if (isObj(sales_rep_file)) {
      return {src: getImageSrc(sales_rep_file)};
    } else if (isObj(sales_rep_img_paths)) {
      return {user_image_paths: sales_rep_img_paths};
    }
    return {};
  }, [
    row.original.order_rep_file,
    row.original.order_rep_user_image_paths,
  ]);

  return (
    <div style={style}>
      <AvatarsWrapper>
        <Avatar
          backgroundColor={colors.primary200}
          name={sales_rep_name}
          data-tip={sales_rep_name}
          data-for={"sales-rep-avatar"}
          {...avatarProps}
          style={!sales_rep_id ? {display: 'none'} : {}}
          onClick={() => {
            if(!sales_rep_id) { return; }
            window.open(`/user.php?id=${sales_rep_id}`, '_blank');
          }}
        />
      </AvatarsWrapper>
    </div>
  );
};

const ProjectName = ({
  row,
  style={},
}) => {
  const projName = row.original.job_name || '';
  const addEllipsis = projName.length > 50;

  return (
    <div style={style}>
      <Link
        as="a"
        href={`/project/${row.original.job_number}`}
        target="_blank"
        rel="noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {row.original.job_number} - {projName.slice(0, 51)}{addEllipsis ? '...' : ''}
      </Link>
    </div>
  );
};

const SalesOrderNumber = ({
  row,
  style={},
}) => {
  return (
    <div style={style}>
      <Link
        as="a"
        href={`/order.php?id=${row.original.order_id}`}
        target="_blank"
        rel="noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {row.original.order_form_number}
      </Link>
    </div>
  );
};

const DepositNumber = ({
  row,
  style={},
}) => {
  return (
    <div style={style}>
      <span>{row.original.form_number}</span>
    </div>
  );
};

const ClientName = ({
  row,
  style={},
}) => {
  const clientName = row.original.client_name || '';
  const addEllipsis = clientName.length > 25;
  return (
    <div style={style}>
      <Link
        as="a"
        href={`/client.php?id=${row.original.client_id}`}
        target="_blank"
        rel="noreferrer"
        onClick={e => e.stopPropagation()}
      >
        {clientName.slice(0, 26)}{addEllipsis}
      </Link>
    </div>
  );
};

const OrderDate = ({
  row,
  style={},
}) => (
  <div style={style}>
    <span>
      {row.original.date_orderdate
        ? moment(row.original.date_orderdate).format('YYYY-MM-DD')
        : ''}
    </span>
  </div>
);

const DepositAmount = ({
  row,
  style={},
}) => (
  <div style={style}>
    <span style={{color: colors.green.main, fontWeight: 'bold' }}>
      {row.original.deposit_amount
        ? formatDashboardMoney(row.original.deposit_amount)
        : ''}
    </span>
  </div>
);

const root = document.getElementById('root') || document.getElementById('resku');
const DepositInvoicesTableWindowed = ({
  data=[],
  loading=false,
  handleUpdateRow,
  defaultSort={id: 'date_orderdate', desc: true },
}) => {
  const windowSize = useWindowSize();

  const [selectedRow, setSelectedRow] = useState(false);
  const [skipPageReset, setSkipPageReset] = useState(false);

  useEffect(() => {
    rebuildTooltip();
    setSkipPageReset(false);
  }, [data]);

  const handleClick = React.useCallback((v) => setSelectedRow(v), []);

  const columns = React.useMemo(() => ([
    {
      Header: () => <div>Order Rep</div>,
      accessor: p => `${p.sales_rep_first_name} ${p.sales_rep_last_name}`,
      sticky: 'left',
      Cell: SalesRepPic,
      noDrag: true,
      minWidth: 140,
      maxWidth: 500,
      id: 'user_id',
    },
    {
      Header: () => <div>Sales Order #</div>,
      accessor: p => `${p.order_form_number}`,
      Cell: SalesOrderNumber,
      sticky: 'left',
      noDrag: true,
      minWidth: 150,
      maxWidth: 500,
      id: 'order_form_number',
    },
    {
      Header: () => <div>Deposit #</div>,
      accessor: p => `${p.form_number}`,
      Cell: DepositNumber,
      sticky: 'left',
      noDrag: true,
      minWidth: 150,
      maxWidth: 500,
      id: 'form_number',
    },
    {
      Header: () => <div>Client</div>,
      accessor: p => `${p.client_name}`,
      Cell: ClientName,
      sticky: 'left',
      noDrag: true,
      minWidth: 140,
      maxWidth: 500,
      id: 'client_id',
    },
    {
      Header: () => <div>Job</div>,
      accessor: p => `${p.job_name}`,
      Cell: ProjectName,
      sticky: 'left',
      noDrag: true,
      minWidth: 210,
      maxWidth: 500,
      id: 'job_id',
    },
    {
      Header: () => <div>Sales Order Date</div>,
      accessor: p => `${p.date_orderdate}`,
      Cell: OrderDate,
      sticky: 'left',
      noDrag: true,
      minWidth: 175,
      maxWidth: 500,
      id: 'date_orderdate',
    },
    {
      Header: () => <div>Deposit Amount</div>,
      accessor: p => `${p.deposit_amount}`,
      Cell: DepositAmount,
      sticky: 'left',
      noDrag: true,
      minWidth: 150,
      maxWidth: 500,
      id: 'deposit_amount',
    },
  ]), []);

  const onClickRow = useCallback(
    (v) => selectedRow
        && selectedRow.job_id === v.job_id
        && selectedRow.order_id === v.order_id
        ? setSelectedRow(false) : setSelectedRow(v),
    [selectedRow]
  );

  const tableHeight = React.useMemo(() => {
    if (windowSize[1] < 251) {
      return 100;
    }
    if (windowSize[1] < 351) {
      return 150;
    }
    if (windowSize[1] < 540) {
      return windowSize[1] - 280;
    }
    if (windowSize[1] < 700) {
      if (windowSize[1] - 350 > 450) {
        return 450;
      }
      return windowSize[1] - 350;
    }
    if (windowSize[1] < 850) {
      if (windowSize[1] - 300 > 450) {
        return 450;
      }
      return windowSize[1] - 300;
    }
    return 500;
  }, [windowSize]);

  const onClose = React.useCallback(() => setSelectedRow(false), []);
  const selectedProjectId = React.useMemo(
    () => _.get(selectedRow, ['job_id'], null),
    [selectedRow]
  );
  const selectedOrderId = React.useMemo(
    () => {
      if (_.get(selectedRow, ['order_type'], null) !== 'INVOICE') {
        return '';
      }
      return _.get(selectedRow, ['order_id'], null);
    },
    [selectedRow]
  );

  return (
    <>
      {ReactDOM.createPortal(
        <ProjectSidepanel
          onClose={onClose}
          onUpdate={handleUpdateRow}
          projectId={selectedProjectId}
          orderId={selectedOrderId}
          loading={loading}
        />, root)}
      <SimpleWindowedTableStyles rowClickable style={loading ? {display: 'none'} : {}}>
        <SimpleWindowedTable
          columns={columns}
          data={data}
          defaultSort={defaultSort}
          onClickRow={onClickRow}
          onScroll={rebuildTooltip}
          onUpdateData={handleUpdateRow}
          useTableProps={{
            autoResetPage: !skipPageReset,
            autoResetGroupBy: !skipPageReset,
            autoResetSortBy: !skipPageReset,
            autoResetFilters: !skipPageReset,
            autoResetRowState: !skipPageReset,
            onClick: handleClick,
          }}
          height={tableHeight}
        />
        <Tooltip id={"sales-rep-avatar"} />
      </SimpleWindowedTableStyles>
      {loading ? <Row>
        <Col xs style={{ alignSelf: "100%", textAlign: 'center' }}>
          <Loading />
        </Col>
      </Row> : null}
    </>
  );
};

export default DepositInvoicesTableWindowed;
