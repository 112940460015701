
export const TASK_PARENT_TYPES = {
  ALL: {
    label: 'All Tasks',
    value: 'ALL',
  },
  CONTACT: {
    label: 'Contact Tasks',
    value: 'CONTACT',
  },
  CLIENT: {
    label: 'Client Tasks',
    value: 'CLIENT',
  },
  JOB: {
    label: 'Project Tasks',
    value: 'JOB',
  },
};
