import _, { round } from 'lodash';
import { createDate, currentMonth, currentYear } from './date_utils';
import { formatDashboardMoney } from '../../../utils';

export const selectByMonthOptions = [
  { label: 'This Month', value: createDate({ d: 1, toStr: true, }) },
  { label: 'Last 2 Months', value: createDate({ d: 1, m: currentMonth - 1, toStr: true, }) },
  { label: 'Last 3 Months', value: createDate({ d: 1, m: currentMonth - 2, toStr: true, }) },
  { label: 'This Year', value: createDate({ d: 1, m: 1, toStr: true, }) },
  { label: 'Last 10 Years', value: createDate({ d: 1, y: currentYear - 10, toStr: true, }) },
];

export const selectByOptions = [
  { value: 'month', label: 'Monthly' },
  { value: 'quarter', label: 'Quarterly' },
  { value: 'year', label: 'Yearly' },
];

export const paymentModelOptions = [
  { label: 'Monthly', value: 'MONTH'},
  { label: 'Yearly', value: 'YEAR'},
];

export function calcChartHeight(dataLength, divLength = 1, def = 550, capSize = 8) {
  const result = (dataLength || 1) * 70 / (divLength || 1);
  return (dataLength / divLength) > capSize ? def : result;
}

/**
 * @param {string|number} val
 * @param {NumberFormatType} type
 */
export const transformVal = (v, type='number') => type == 'currency'
  ? v.toString().includes('$') ? v : formatDashboardMoney(parseFloat(v.toString())).replace(' ', '')
  : type === 'number'
  ? v.toString().includes(',') ? v : _.parseInt(v.toString()).toLocaleString()
  : type === 'percentage'
  ? v.toString().includes('%') ? v : round(parseFloat(v.toString()) * 100) + '%'
  : type === 'roundedCurrency' 
  ? v.toString().includes('$') ? v : '$'+ (Math.round(parseFloat(v.toString()),0))
  : v
;

export const REP_TYPE_OPTIONS = [
  {label: 'Client Rep', value: 'CLIENT'},
  {label: 'Order Rep', value: 'ORDER'},
];

export const PROJECT_STAGES = {
  'OPPORTUNITY': {
    'title': 'Opportunities',
    'type': 'OPPORTUNITY',
    'order': 1,
  },
  'PRESENTATION': {
    'title': 'Presentations',
    'type': 'PRESENTATION',
    'order': 2,
  },
  'ESTIMATE': {
    'title': 'Estimates',
    'type': 'ESTIMATE',
    'order': 3,
  },
  'SALES ORDER': {
    'title': 'Sales Orders',
    'type': 'SALES ORDER',
    'order': 4,
  },
  'INVOICE': {
    'title': 'Invoice',
    'type': 'INVOICE',
    'order': 5,
  }
};

export const ORDER_TYPES = {
  OPPORTUNITY: 'Op',
  PRESENTATION: 'P',
  ESTIMATE: 'E',
  SALES_ORDER: 'SO',
  'SALES ORDER': 'SO',
  INVOICE: 'In',
};

// ================================
export * from './date_utils';
export * from './reports';
