import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from "@commonsku/styles";
import { getUserRepOptions } from '../../../selectors/users';
import { getIdentityUtils } from '../../../utils';
import { LabeledSelect } from '../../helpers';

const RepTypeOptions = [
  { label: 'Client Rep', value: 'CLIENT' },
  { label: 'Order Rep', value: 'ORDER' },
];

const PageFilters = React.memo((props) => {
  const {
    identity,
    filters,
    setFilters
  } = props;
  const reps_options = useSelector(getUserRepOptions);
  const { isAdmin } = getIdentityUtils(identity);

  return (
    <Row style={{ marginTop: 0, }}>
      <Col xs md={4} padded>
        <LabeledSelect
          name="user-rep_type"
          options={RepTypeOptions}
          value={filters.rep_type}
          onChange={v => {
            const val = _.get(v, 'value', '');
            setFilters(s => ({ ...s, rep_type: val }));
          }}
          label="Rep Type"
          className="csku-select-v3"
        />
      </Col>

      <Col xs md={4} padded>
        <LabeledSelect
          name="user-rep-id"
          options={reps_options}
          value={filters.rep_id}
          onChange={v => {
            const val = _.get(v, 'value', '');
            setFilters(s => ({ ...s, rep_id: val }));
          }}
          label="Rep"
          className="csku-select-v3"
          isClearable={isAdmin && filters.rep_id}
        />
      </Col>
    </Row>
  );
});

export default PageFilters;
