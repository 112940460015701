import React, { useEffect } from 'react';
import {
  Col, Row, Text, colors, Popup, PopupHeader, Loading,
} from '@commonsku/styles';
import { HubspotForm } from '../helpers';
import { generateUUID } from '../../utils';

const HEADER_HEIGHT = 80;

export function GotAnIdeaPopup({ dashboardType, onClose }) {
  const formUUID = React.useRef('');

  function updatePopupContentHeight() {
    if (document.querySelector('.popup-content')) {
      document.querySelector('.popup-content').style.maxHeight = "1100px";
      document.querySelector('.popup-content').style.height = `calc(100% - ${HEADER_HEIGHT}px)`;
    } else {
      setTimeout(() => {
        updatePopupContentHeight();
      }, 100);
    }
  }

  useEffect(() => {
    updatePopupContentHeight();
    formUUID.current = generateUUID();

    return () => {
      formUUID.current = "";
    };
  }, []);

  return (
    <Popup
      style={{ padding: 0, maxWidth: 600, }}
      header={<PopupHeader style={{ background: '#CCFFE8', height: HEADER_HEIGHT }}>
        <Col xs md={10} style={{ alignSelf: 'center', }}>
          <Text as="p" style={{
            color: '#123952',
            fontSize: '18px',
            lineHeight: '25px',
            paddingLeft: '32px',
          }}>Have a suggestion? We'd love to hear from you!</Text>
        </Col>
        <Col xs md={2} style={{ textAlign: 'right', alignSelf: 'center', }}>
          <span style={{
            color: colors.primary,
            fontSize: '2rem',
            paddingRight: 30,
            cursor: 'pointer',
          }} onClick={onClose}>&#10006;</span>
        </Col>
      </PopupHeader>}
    >
      <PopupContent dashboardType={dashboardType} formUUID={formUUID.current} onClose={onClose} />
    </Popup>
  );
}

function PopupContent({ dashboardType, formUUID, onClose }) {
  return (<>
    <Row>
      <Col xs style={{ padding: 30, textAlign: 'center' }}>
        <Text style={{color: '#7b98ac'}}>
        Do you have a suggestion that will make our Dashboards or Analytics pages more useful? Please fill out this form for the commonsku team to review.
        </Text>
      </Col>
      <Col xs style={{padding: 30}}>
        <HubspotForm
          id={"hbspt-form-"+formUUID}
          className="hbspt-form"
          region="na1"
          portalId='7611209'
          formId='d119e019-f037-43e0-bebc-a7982b14c368'
          onSubmit={() => onClose && onClose()}
          onReady={(form) => {}}
          loading={<div style={{textAlign: 'center'}}>
            <Loading />
          </div>}
        />
      </Col>
    </Row>
  </>);
}

export default GotAnIdeaPopup;
