import moment from "moment";

export function parseFilters(filters, report_type='') {
    if (report_type === 'SALES_ORDER_IN_PRODUCTION_STATUS_TOTALS') {
      return {
        ...filters,
        date_field: 'date_inhandsdate',
        start_stamp: moment().startOf('month').format('YYYY-MM-DD'),
        end_stamp: moment().endOf('month').format('YYYY-MM-DD'),
      };
    }
    return filters;
}
