import _ from 'lodash';
import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';
import { colors, Input, InputIconLabel, InputIconLabelContainer, Link, ResponsiveTable, TBody, TD, Text, TH, THead, TR, CircleProgressIcon, Loading } from '@commonsku/styles';
import { ORDER_TYPES, PROJECT_STAGES } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { formatDashboardMoney, formatDate, getImageSrcByImgPaths, oauth } from '../../utils';
import DateInput, { LabeledDateInput } from '../DateInput';
import { REPORT_TYPE, selectors } from '../../redux/reports';
import ProjectSidepanel from '../project/ProjectSidepanel';
import Img from '../Img';
import { ANALYTICS_REPORT_TYPES } from '../../containers/AnalyticsHelper';
import { document } from '../../global';

export const getProjectTotalBudget = (p,report_type='') => ['SALES ORDER', 'INVOICE'].includes(p.order_type) ? (Object.values(ANALYTICS_REPORT_TYPES.MARGIN_BY_CLIENT.type).includes(report_type)? p.total_margin_amount: p.total_subtotal) : p.total_budget;
export const getProjectInHandsDate = p => p.order_date_inhandsdate || p.date_inhandsdate || '';
export const getProjectCreatedDate = p => p.order_date_created || p.date_created || '';

export const ProjectRow = ({ project={}, report_type='', onClickProject }) => {
  const [state, setState] = useState({
    total_budget: getProjectTotalBudget(project),
    date_inhandsdate: getProjectInHandsDate(project),
  });

  useEffect(() => {
    setState({
      total_budget: getProjectTotalBudget(project),
      date_inhandsdate: getProjectInHandsDate(project),
    });
  }, [project]);

  const inHandsDate = getProjectInHandsDate(project);
  const totalBudget = getProjectTotalBudget(project);

  const handleUpdateProject = (field, value, previous_value) => {
    oauth('PUT', `project/${project.job_id}`, {[field]: value}).then(
      ({json}) => {
        setState(s => ({ ...s, [field]: value}));
      }
    );
  };

  return (
    <TR style={{borderBottom: '2px solid #dae9ee'}}>
      <TD width="15%">
        <Img alt="Client Rep"
          style={{borderRadius: '50%', paddingRight: 5, height: 48}}
          src={getImageSrcByImgPaths(project.client_rep_user_image_paths, '/images/user-avatar3-48x48.png')}
        />
        <Img alt="Order Rep"
          style={{borderRadius: '50%', paddingRight: 5, height: 48}}
          src={getImageSrcByImgPaths(project.order_rep_user_image_paths, '/images/user-avatar3-48x48.png')}
        />
      </TD>
      <TD width="25%">
        <Link style={{display: 'block'}} onClick={onClickProject}>
          {project.job_number} - {project.job_name}
        </Link>
        <span style={{display: 'block'}}>{project.client_name}</span>
      </TD>
      <TD width="25%">
        <span style={{display: 'inline-block'}}>
          <CircleProgressIcon
            percentage={_.get(PROJECT_STAGES, [project.order_type, 'order'], 0)/5 * 100}
            text={ORDER_TYPES[project.order_type] === 'In' ? '\u2714' : ORDER_TYPES[project.order_type]}
            textStyle={ORDER_TYPES[project.order_type] === 'In' ? {fontSize: 28} : {}}
          />
        </span>
        <span style={{display: 'inline-block', paddingLeft: '10px', verticalAlign: 'top', paddingTop: 10,}}>
          <Text style={{display: 'block'}}>{project.order_type}</Text>
          <span style={{display: 'block'}}>{project.status_name}</span>
        </span>
      </TD>
      <TD width="20%">
        {report_type === REPORT_TYPE.PROJECTS_WITH_NO_BUDGET.type ?
          <InputIconLabelContainer>
            <InputIconLabel style={{height: 38,}}>$</InputIconLabel>
            <Input style={{borderRadius: '0 5px 5px 0px',}}
              value={state.total_budget}
              onChange={e => setState(s => ({...s, total_budget: e.target.value}))}
              onBlur={e => handleUpdateProject('total_budget', e.target.value, project.total_budget)}
            />
          </InputIconLabelContainer>
        : <Text style={{display: 'block', color: colors.special2}}>
            {formatDashboardMoney(totalBudget)}
          </Text>}
      </TD>
      <TD width="15%" style={{paddingRight: 10}}>
        {report_type === REPORT_TYPE.OVERDUE_IN_HANDS_DATE_PROJECTS.type ?
          <DateInput
            customInput={<LabeledDateInput date={state.date_inhandsdate} label="Inhands Date" />}
            showMonthDropdown
            showYearDropdown
            value={state.date_inhandsdate}
            onChange={date => handleUpdateProject('date_inhandsdate', date, project.date_inhandsdate)}
            portalId="root-portal"
            style={{width: '100%'}}
          />
        : (<span>{inHandsDate
          ? formatDate(inHandsDate)
          : ''}</span>)}
      </TD>
    </TR>
  );
};

const root = document.getElementById('root') || document.getElementById('resku');

const ProjectsTable = ({ report_type }) => {
  const dispatch = useDispatch();
  const [selectedProject, setSelectedProject] = useState(false);
  const report = useSelector(s => s.reports.data[report_type]);
  const loading = useSelector(s => selectors.loading(s)[report_type]);
  const projects = _.get(report, 'data', []);

  return (
    <>
      {ReactDOM.createPortal(
        <ProjectSidepanel
          project={selectedProject}
          onClose={() => setSelectedProject(false)}
        />,
        root
      )}
      <ResponsiveTable style={{borderCollapse: 'collapse', borderSpacing: 0, minWidth: '1000px',}}>
        <THead>
          <TR style={{borderBottom: '2px solid #dae9ee'}}>
            <TH style={{background: '#fff'}}>Client & Order Rep</TH>
            <TH style={{background: '#fff'}}>Project</TH>
            <TH style={{background: '#fff'}}>Stage</TH>
            <TH style={{background: '#fff'}}>Budget/Total</TH>
            <TH style={{background: '#fff'}}>In-Hands Date</TH>
          </TR>
        </THead>
        <TBody>
          {loading ? <TR style={{borderBottom: '2px solid #dae9ee'}}>
            <TD colSpan="100%" width="100%" style={{textAlign: 'center'}}>
              <Loading />
            </TD>
          </TR> : projects.map((p, i) => (
            <ProjectRow key={'row-'+i}
              project={p}
              report_type={report_type}
              onClickProject={() => setSelectedProject(p)}
            />
          ))}
        </TBody>
      </ResponsiveTable>
    </>
  );
};

export default ProjectsTable;
