import _ from 'lodash';
import ReactDOM from 'react-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { colors, Link, Text, CircleProgressIcon, Loading, Row, Col, SimpleWindowedTable, SimpleWindowedTableStyles, DollarIcon, LabeledIconInput } from '@commonsku/styles';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'react-tooltip';
import { ORDER_TYPES, PROJECT_STAGES } from './utils';
import { formatDashboardMoney, dateStr, oauth, getImageSrc, parseDate } from '../../utils';
import DateInput, { CSKUInput } from '../DateInput';
import { REPORT_TYPE, selectors, updateReportProjectData } from '../../redux/reports';
import ProjectSidepanel from '../project/ProjectSidepanel';
import { getProjectCreatedDate, getProjectInHandsDate, getProjectTotalBudget } from './ProjectsTable';
import Avatar, { AvatarsWrapper } from '../helpers/Avatar';
import { rebuildTooltip } from '../helpers';
import useWindowSize from '../../hooks/useWindowSize';
import { analytics_report_names } from '../../containers/AnalyticsHelper';
import { updateAnalyticsReportProjectData } from '../../actions';
import { fetchAllStatus, selectors as statusSelectors } from '../../redux/status';
import { document } from '../../global';

function isObj(obj) {
  return typeof obj === 'object' && _.keys(obj).length > 0;
}

const ProjectRepsPics = ({
  row,
  style={},
}) => {
  return (
    <div style={style}>
      <AvatarsWrapper>
        <Avatar
          backgroundColor={colors.primary}
          name={row.original.client_rep_full_name || "Client Rep"}
          data-tip={row.original.client_rep_full_name || "Client Rep"}
          data-for={"client-rep-avatar"}
          {...(isObj(row.original.client_rep_file) ? {
            src: getImageSrc(row.original.client_rep_file)
          } : isObj(row.original.client_rep_user_image_path) ? {
            user_image_paths: row.original.client_rep_user_image_paths,
          } :{})}
        />
        <Avatar
          backgroundColor={row.original.sales_rep_id !== row.original.client_rep_id ? colors.primary200 : colors.primary}
          name={row.original.order_rep_full_name || "Order Rep"}
          data-tip={row.original.order_rep_full_name || "Order Rep"}
          data-for={"sales-rep-avatar"}
          {...(isObj(row.original.order_rep_file) ? {
            src: getImageSrc(row.original.order_rep_file)
          } : isObj(row.original.order_rep_user_image_paths) ? {
            user_image_paths: row.original.order_rep_user_image_paths,
          } :{})}
        />
      </AvatarsWrapper>
    </div>
  );
};

const ProjectName = ({
  row,
  style={},
}) => (
  <div style={style}>
    <Link
      as="a"
      href={`/project/${row.original.job_number}`}
      target="_blank"
      rel="noreferrer"
      onClick={e => e.stopPropagation()}
    >
      {row.original.job_number} - {row.original.job_name}
    </Link>
    <span style={{display: 'block'}}>{row.original.client_name}</span>
  </div>
);

const ProjectStage = ({
  row,
  style={},
}) => {
  const status = useSelector(
    s => _.get(s.entities.statuses, [row.original.status_id])
      ||  statusSelectors.byId(s, row.original.status_id)
  );
  return (
    <div style={style}>
      <span style={{display: 'inline-block'}}>
        <CircleProgressIcon
          percentage={_.get(PROJECT_STAGES, [row.original.order_type, 'order'], 0)/5 * 100}
          text={ORDER_TYPES[row.original.order_type] === 'In' ? '\u2714' : ORDER_TYPES[row.original.order_type]}
          textStyle={ORDER_TYPES[row.original.order_type] === 'In' ? {fontSize: 28} : {}}
        />
      </span>
      <span style={{display: 'inline-block', paddingLeft: '10px', verticalAlign: 'top', paddingTop: 10,}}>
        <Text style={{display: 'block'}}>{row.original.order_type}</Text>
        <span style={{display: 'block'}}>{_.get(status, 'status_name', '') || row.original.status_name}</span>
      </span>
    </div>
  );
};

const ProjectBudget = React.memo(({
  row,
  value,
  onUpdateData,
  report_type,
  style={},
}) => {
  const [state, setState] = useState(value);
  const canEdit = report_type === REPORT_TYPE.PROJECTS_WITH_NO_BUDGET.type;

  useEffect(() => {
    setState(value);
  }, [value]);

  return (
    <div style={style} onClick={e => {
      if(!canEdit) { return; }
      e.preventDefault();
      e.stopPropagation();
    }}>
      {canEdit ?
        <LabeledIconInput
          name={`project-budget-${_.get(row, ['original', 'job_id'], '')}`}
          Icon={<DollarIcon style={{paddingLeft: 8, paddingRight: 0}} />}
          value={state}
          onChange={e => setState(e.target.value)}
          onBlur={e => {
            onUpdateData && onUpdateData({
              job_id: row.original.job_id,
              order_id: row.original.order_id,
              field: 'total_budget',
              value: e.target.value,
              previous_value: row.original.total_budget,
            });
          }}
        />
      : <Text style={{display: 'block', color: colors.special2}}>
          {formatDashboardMoney(state)}
        </Text>}
    </div>
  );
});

const ProjectInHandsDate = ({
  row,
  value,
  onUpdateData,
  report_type,
  style={},
}) => {
  const [state, setState] = useState(value);
  const canEdit = report_type === REPORT_TYPE.OVERDUE_IN_HANDS_DATE_PROJECTS.type;

  useEffect(() => {
    setState(value);
  }, [value]);

  const dateParsed = state ? dateStr(new Date(parseDate(state))) : '';

  return (
    <div style={style} onClick={e => {
      if(!canEdit) { return; }
      e.preventDefault();
      e.stopPropagation();
    }}>
      {canEdit ?
        <DateInput
          customInput={<CSKUInput date={state} />}
          showMonthDropdown
          showYearDropdown
          value={state}
          onChange={date => {
            onUpdateData && onUpdateData({
              job_id: row.original.job_id,
              order_id: row.original.order_id,
              field: 'date_inhandsdate',
              value: date,
              previous_value: row.original.date_inhandsdate,
              index: row.index,
            });
          }}
          portalId="root-portal"
          style={{width: '100%'}}
        />
      : <span>{dateParsed}</span>}
    </div>
  );
};

const ProjectDateCreated = ({
  row,
  value,
  style={},
}) => {
  return (
    <div style={style}>
      <span>{value ? dateStr(new Date(parseDate(value))) : ''}</span>
    </div>
  );
};

const root = document.getElementById('root') || document.getElementById('resku');

const ProjectsTableWindowed = ({
  report_type,
  loadReportTotal,
  showDateCreated=false,
  defaultSort={id: 'date_inhandsdate', desc: true },
}) => {
  const dispatch = useDispatch();
  //if report_type is analytics then below else existing code
  let isAnalytics = analytics_report_names.includes(report_type);
  const report = useSelector(
    s => isAnalytics
      ? s.entities.analytics_report_data[report_type]
      : s.reports.data[report_type]
  );
  const loading = useSelector(
    s => isAnalytics
      ? s.entities.loading_analytics_popup[report_type]
      : selectors.loading(s)[report_type]
  );
  const windowSize = useWindowSize();
  const winHeight = windowSize[1];

  const [selectedProject, setSelectedProject] = useState(false);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const projects = React.useMemo(() => {
    return (report ? report.data : []) || [];
  }, [report, report_type]);

  useEffect(() => {
    dispatch(fetchAllStatus());
  }, [dispatch]);

  useEffect(() => {
    rebuildTooltip();
    setSkipPageReset(false);
  }, [projects]);

  const selectedProjectMemo = React.useMemo(() => {
    if (!selectedProject) {
      return selectedProject;
    }

    const found = _.find(
      projects,
      v => v.job_id === selectedProject.job_id
        && v.order_id === selectedProject.order_id
    );
    if (!found) { return selectedProject; }
    return found;
  }, [selectedProject, projects]);

  const onClickProject = React.useCallback((p) => setSelectedProject(p), []);

  const handleUpdateProject = React.useCallback((respData, { job_id, order_id, field, index }) => {
    setSkipPageReset(true);
    const actionData = {
      job_id,
      order_id,
      [field]: respData[field],
    };
    if (field === 'date_inhandsdate') {
      actionData['order_date_inhandsdate'] = respData[field];
    } else if (field === 'total_budget') {
      if (['SALES ORDER', 'INVOICE'].includes(respData.order_type)) {
        actionData['total_subtotal'] = respData[field];
      } else {
        actionData['total_budget'] = respData[field];
      }
    }
    isAnalytics ? dispatch(updateAnalyticsReportProjectData({
      index,
      job_id,
      report_type,
      data: actionData
    })) : dispatch(updateReportProjectData({
      index,
      job_id,
      report_type,
      data: actionData
    }));

    if (['date_inhandsdate', 'total_budget', 'order_type', 'status_id'].includes(field)) {
      loadReportTotal && loadReportTotal(report_type);
    }
    return respData;
  }, [dispatch, isAnalytics, loadReportTotal, report_type]);

  const updateProjectOrder = React.useCallback(({job_id, order_id, field, value, previous_value, index, shouldRemoteUpdate=true}) => {
    if (!shouldRemoteUpdate) {
      handleUpdateProject({ job_id, order_id, field, index, [field]: value, }, {job_id, order_id, field, index});
      return;
    }
    oauth(
      'PUT',
      ['date_inhandsdate', 'status_id'].includes(field) ? `order/${order_id}` : `project/${job_id}`,
      {[field]: value}
    ).then(({json}) => {
      const respData = json.order || json.project;
      handleUpdateProject(respData, { job_id, order_id, field, index });
      return respData;
    });
  }, [handleUpdateProject]);

  const columns = React.useMemo(() => ([
    {
      Header: () => <div>Client &amp; Order Rep</div>,
      accessor: p => `${p.order_rep_full_name} ${p.client_rep_full_name}`,
      sticky: 'left',
      Cell: ProjectRepsPics,
      noDrag: true,
      minWidth: 175,
      maxWidth: 500,
      id: 'sales_rep_id',
    },
    {
      Header: () => <div>Project</div>,
      accessor: p => `${p.job_number}-${p.job_name} ${p.client_name}`,
      Cell: ProjectName,
      sticky: 'left',
      noDrag: true,
      minWidth: 280,
      maxWidth: 500,
      id: 'job_id',
    },
    {
      Header: () => <div>Stage</div>,
      accessor: p => `${p.order_type} ${p.status_id}`,
      Cell: ProjectStage,
      sticky: 'left',
      noDrag: true,
      minWidth: 270,
      maxWidth: 500,
      id: 'order_type',
    },
    {
      Header: () => <div>Budget/Total</div>,
      accessor: p => getProjectTotalBudget(p,report_type),
      Cell: ProjectBudget,
      sticky: 'left',
      noDrag: true,
      minWidth: 140,
      maxWidth: 500,
      id: 'total_budget',
    },
    {
      Header: () => <div>In-Hands Date</div>,
      accessor: p => getProjectInHandsDate(p),
      Cell: ProjectInHandsDate,
      sticky: 'left',
      noDrag: true,
      minWidth: 210,
      maxWidth: 500,
      id: 'date_inhandsdate',
    },
  ].concat(showDateCreated ? [{
    Header: () => <div>Created Date</div>,
    accessor: getProjectCreatedDate,
    Cell: ProjectDateCreated,
    sticky: 'left',
    noDrag: true,
    minWidth: 210,
    maxWidth: 500,
    id: 'date_created',
  }] : [])), [showDateCreated, report_type]);

  const onClickRow = useCallback(
    (v) => selectedProject
        && selectedProject.job_id === v.job_id
        && selectedProject.order_id === v.order_id
        ? setSelectedProject(false) : setSelectedProject(v),
    [selectedProject]
  );

  const tableHeight = React.useMemo(() => {
    if (winHeight < 251) {
      return 100;
    }
    if (winHeight < 351) {
      return 150;
    }
    if (winHeight < 540) {
      return winHeight - 280;
    }
    if (winHeight < 700) {
      if (winHeight - 350 > 450) {
        return 450;
      }
      return winHeight - 350;
    }
    if (winHeight < 850) {
      if (winHeight - 300 > 450) {
        return 450;
      }
      return winHeight - 300;
    }
    return 500;
  }, [winHeight]);

  const onClose = React.useCallback(() => setSelectedProject(false), []);
  const selectedProjectId = React.useMemo(
    () => _.get(selectedProjectMemo, ['job_id'], null),
    [selectedProjectMemo]
  );
  const selectedOrderId = React.useMemo(
    () => {
      if (_.get(selectedProjectMemo, ['order_type'], null) !== 'INVOICE') {
        return '';
      }
      return _.get(selectedProjectMemo, ['order_id'], null);
    },
    [selectedProjectMemo]
  );

  return (
    <>
     {ReactDOM.createPortal(
      <ProjectSidepanel
        project={selectedProjectMemo}
        onClose={onClose}
        onUpdate={updateProjectOrder}
        isAnalytics={isAnalytics}
        projectId={selectedProjectId}
        orderId={selectedOrderId}
        loading={loading}
      />, root)}
      <SimpleWindowedTableStyles rowClickable style={loading ? {display: 'none'} : {}}>
        <SimpleWindowedTable
          columns={columns}
          data={projects}
          defaultSort={defaultSort}
          onClickRow={onClickRow}
          onScroll={rebuildTooltip}
          onUpdateData={updateProjectOrder}
          useTableProps={{
            autoResetPage: !skipPageReset,
            autoResetGroupBy: !skipPageReset,
            autoResetSortBy: !skipPageReset,
            autoResetFilters: !skipPageReset,
            autoResetRowState: !skipPageReset,
            report_type: report_type,
            onClickProject: onClickProject
          }}
          height={tableHeight}
        />
        <Tooltip id={"client-rep-avatar"} />
        <Tooltip id={"sales-rep-avatar"} />
      </SimpleWindowedTableStyles>
      {loading ? <Row>
        <Col xs style={{ alignSelf: "100%", textAlign: 'center' }}>
          <Loading />
        </Col>
      </Row> : null}
    </>
  );
};

export default ProjectsTableWindowed;
