import _ from 'lodash';
import { colors } from '@commonsku/styles';
import { transformVal } from '../';
import {
  createDate, months, quarters,
  currentYear, currentMonth, currentQuarter,
  prevMonth, prevQuarter, monthsOrdered,
} from './date_utils';
import { document } from '../../../global';

const date_from =  createDate({ m: 0, d: 1, y: currentYear-2, toStr: true, }) //REVERT
const date_to = createDate({ m: 11, d: 31, y: currentYear, toStr: true, });
export function getDateParams(group_by, compare_previous=1, totals_by='') {
  const params = {
    date_from: '',
    date_to: '',
    quarter: '',
    year: '',

    prev_date_from: '',
    prev_date_to: '',
    prev_quarter: '',
    prev_year: '',
  };

  if (['totals', 'tags', 'tenants_count'].includes(totals_by)) {
    return {date_from, date_to};
  }

  if (group_by == 'quarter') {
    params['quarter'] = currentQuarter;
    params['year'] = currentYear;
    if(compare_previous) {
      params['prev_quarter'] = prevQuarter;
      params['prev_year'] = currentQuarter == 1 ? currentYear-1 : currentYear;
    }
  } else if (group_by == 'month') {
    params['month'] = currentMonth+1;
    params['year'] = currentYear;
    if(compare_previous) {
      params['prev_month'] = prevMonth+1;
      params['prev_year'] = currentMonth==0 ? currentYear-1 : currentYear;
    }
  } else if (group_by == 'year') {
    params['year'] = currentYear;
    if(compare_previous) {
      params['prev_year'] = currentYear-1;
    }
  }
  return params;
}

export function modifyExportData({data, totals_by, group_by, formatNumberType, onlyValTransform,}) {
  if (!data || (data && data.length == 0)) { return data; }
  data = data.data ? data.data : data;
  let subData = [];
  let other_index = -1;
  data.forEach((val, i) => {
    if (val && (!val.group_by || (totals_by === 'tenant' || totals_by === 'industry' ||  totals_by === 'events'))) {  
      const gr = val['gr1'] !== undefined ? 1 :  val['gr2'] !== undefined ? 2 : '';
      if (val[`gr${gr}_subData`] && val[`gr${gr}_subData`].length > 0) {
        other_index = i;
        subData = subData.concat(val[`gr${gr}_subData`].map(s => ({
          year: group_by == 'year' ? s['group_by'].split(' ')[1] : s['group_by'].split(' ')[2],
          group_by: s['group_by'].split(' ')[1],
          totals: transformVal(s.totals, formatNumberType),
          [`${totals_by}_name`]: s[`${totals_by}_name`],
        })));
      }
      if (!onlyValTransform) {
        data[i] = {
          year: group_by == 'year' ? val[`gr${gr}`] : val[`gr${gr}_group_by`].split(' ')[2],
          group_by: val[`gr${gr}`],
          totals: formatNumberType === 'roundedCurrency' ? transformVal(parseFloat(val[`gr${gr}_totals`]),formatNumberType) :  transformVal(_.parseInt(val[`gr${gr}_totals`]),formatNumberType),
          [`${totals_by}_name`]: val[`gr${gr}_${totals_by}_name`],
        };
      } else {
        data[i]['totals'] = transformVal(val['totals'], formatNumberType);
        delete data[i][`${totals_by}_id`];
      }
    }
  });
  data = data.concat(subData);
  if (other_index > -1) {
    data.splice(other_index, 1); //remove 'Others' row from excel 
   }
  data = _.orderBy(data, ['year',
    (v) => (!v ? v : group_by == 'month'
      ? months.indexOf(v.group_by)
      : group_by == 'quarter'
        ? quarters.indexOf(v.group_by)
        : _.parseInt(v.group_by)
    ), function(v) {
      if (!v) { return v; }
      let totals = v.totals;
        totals = (totals+'').replace(',', '').replace('$', '').trim();
        totals = parseFloat(totals);
      return totals;
    }], ['desc', group_by == 'year' ? 'desc' : 'asc', 'desc']
  );
  return data;
}

export function modifyTotalsExportData({data, totals_by, group_by, formatNumberType, onlyValTransform}) {
  if (!data || (data && data.length == 0)) { return data; }
  data = data.data ? data.data : data;
  let modifiedData =[];
  var totalsColumnName =  totals_by === 'tags' || totals_by === 'tenants_count' ? 'Total Percenatge' : 'Total';
  data.forEach((val,i)=> {
    modifiedData[i] = {
      [totalsColumnName]: totals_by === 'tags' || totals_by === 'tenants_count' ? transformVal(val.totals_percentage, formatNumberType) : 
      transformVal(val.totals, formatNumberType),
      year: _.parseInt(val.year),
      group_by: val.group_by.split(' ')[0],
    };
  });
  modifiedData
    .sort((a, b) => {
      const grA = group_by == 'month'
        ? months.indexOf(a.group_by)
        : group_by == 'quarter'
          ? quarters.indexOf(a.group_by)
          : _.parseInt(a.group_by);
      const grB = group_by == 'month'
        ? months.indexOf(b.group_by)
        : group_by == 'quarter'
          ? quarters.indexOf(b.group_by)
          : _.parseInt(b.group_by);
      return grA > grB ? 1 : grA < grB ? -1 : 0;
    })
    .sort((a, b) => a.year > b.year ? -1 : a.year < b.year ? 1 : 0);

  return modifiedData;
}

/**
 *
 * @param {Chart} chart
 */
export function chartExportOptions(chart) {
  let options = chart.exporting.getFormatOptions("png");
  options.quality = 1;
  options.multiplier = 2;
  options.scale = 4;
  chart.exporting.setFormatOptions("png", options);
  options = chart.exporting.getFormatOptions("jpg");
  options.quality = 1;
  options.multiplier = 2;
  options.scale = 4;
  chart.exporting.setFormatOptions("jpg", options);
  options = chart.exporting.getFormatOptions("svg");
  options.quality = 1;
  options.multiplier = 2;
  options.scale = 4;
  chart.exporting.setFormatOptions("svg", options);
  options = chart.exporting.getFormatOptions("pdf");
  options.multiplier = 2;
  options.scale = 4;
  chart.exporting.setFormatOptions("pdf", options);
}

export const modifyExport = ({cardId, totals_by, group_by, formatNumberType, onlyValTransform, fileName}) => (chart, data, helper) => {
  chart.openPopup("Exporting");
  chartExportOptions(chart);
  if (fileName) {
    fileName = fileName.replaceAll(' ','_');
    chart.exporting.filePrefix = fileName;
  }
  if (!document.getElementById("export_menu_"+cardId).hasChildNodes()) {
    chart.exporting.menu.container = document.getElementById("export_menu_"+cardId);
  }
  if (chart.exporting.events.hasListenersByType('exportstarted')) {
    const listeners = chart.exporting.events;
    if (listeners._listeners && listeners._listeners.length > 0) {
      listeners._listeners.forEach(v => {
        v.disposer._dispose();
      });
    }
  }

  chart.exporting.events.on("exportstarted", function(ev) {
   chart.exporting.adapter.add("data", function(data) {
    if (['totals', 'tags', 'tenants_count'].includes(totals_by)) {
      data = modifyTotalsExportData({data: data, totals_by, group_by, formatNumberType, onlyValTransform});
    } else {
      data = modifyExportData({data: data , totals_by, group_by, formatNumberType, onlyValTransform});
    }
    return {...data, data: data};
  });

  // chart.exporting.adapter.add("data", function(chartData) {
  //   const data = chartData.data;
  //   let new_data = [];
  //   if (['totals', 'tags', 'tenants_count'].includes(totals_by)) {
  //     new_data = modifyTotalsExportData({data: data, totals_by, group_by, formatNumberType});
  //   } else {
  //     new_data = modifyExportData({data: data, totals_by, group_by, formatNumberType});
  //   }
  //   console.log({...chartData, data: new_data});
  //   return {...chartData, data: new_data};
  // });
  },this);
  chart.closeAllPopups();
};

export const exportTotalsPdf = ({
  formatNumberType,
  totals_by,
  group_by,
  title,
  type,
  onlyValTransform,
}) => {
  return (chart, chartData) => {
    Promise.all([
      chart.exporting.pdfmake,
      chart.exporting.getImage("png"),
    ]).then(res => {
      const pdfMake = res[0];
      const data = modifyTotalsExportData({data: chart.exporting.data, totals_by, group_by, formatNumberType, onlyValTransform});
      const currentData = data.filter(v => v.year == currentYear);
      const prevData = data.filter(v => v.year == currentYear - 1);

      const table = [], table2 = [];
      table.push([{ text: 'Year', bold: true }]);
      table.push([{ text: currentData[0].year }]);
      table.push([{ text: prevData[0].year }]);
      const cols = ['*'];

      const cell_options = (primary = true) => ({
        fillColor: primary ? colors.primary : colors.cta,
        color: '#fff',
        bold: true,
      });

      if (group_by == 'year') {
        currentData.forEach((v, i) => {
          cols.push('*');
          table[0].push({ text: '', bold: true });
          table[1].push({ text: v.totals, ...(i == 0 ? cell_options() : {}) });
        });
        prevData.forEach((v, i) => {
          table[2].push({ text: v.totals, ...(i == 0 ? cell_options(false) : {}) });
        });
      } else if (group_by == 'quarter') {
        currentData.forEach((v, i) => {
          cols.push('*');
          table[0].push({ text: v.group_by, bold: true });
          table[1].push({ text: v.totals, ...(i == currentQuarter ? cell_options() : {}) });
        });
        prevData.forEach((v, i) => {
          table[2].push({ text: v.totals, ...(i == currentQuarter ? cell_options(false) : {}) });
        });
      } else if (group_by == 'month') {
        table2.push([{ text: 'Year', bold: true }]);
        table2.push([{ text: currentData[0].year }]);
        table2.push([{ text: prevData[0].year }]);

        currentData.forEach((v, i) => {
          if (i < 6) {
            cols.push('*');
            table[0].push({ text: v.group_by, bold: true });
            table[1].push({ text: v.totals, ...(i == currentMonth ? cell_options() : {}) });
          } else {
            table2[0].push({ text: v.group_by, bold: true });
            table2[1].push({ text: v.totals, ...(i == currentMonth ? cell_options() : {}) });
          }
        });
        prevData.forEach((v, i) => {
          if (i < 6) {
            table[2].push({ text: v.totals, ...(i == currentMonth ? cell_options(false) : {}) });
          } else {
            table2[2].push({ text: v.totals, ...(i == currentMonth ? cell_options(false) : {}) });
          }
        });
      }

      const doc = {
        pageSize: "A4",
        pageOrientation: "landscape",
        pageMargins: [30, 30, 30, 30],
        content: []
      };
      doc.content.push({
        text: title,
        fontSize: 20,
        bold: true,
        margin: [0, 20, 0, 15]
      });
      doc.content.push({
        text: `This contains ${
          type == 'SUPPLIER_POS'
            ? "the sum of purchase orders totals"
            : "the count of engagements"
          } this year compared to previous year.`,
        fontSize: 15,
        margin: [0, 0, 0, 15]
      });
      doc.content.push({
        image: res[1],
        width: 700,
        margin: [0, 0, 0, 15]
      });
      doc.content.push({
        table: {
          headerRows: 1,
          widths: cols,
          body: table,
        },
        margin: [0, 0, 0, 15]
      });
      if (group_by == 'month') {
        doc.content.push({
          table: {
            headerRows: 1,
            widths: cols,
            body: table2,
          },
          margin: [0, 0, 0, 15],
          // pageBreak: 'after'
        });
      }

      pdfMake.createPdf(doc).download("report.pdf");
    });
  };
};
