import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { oauth, defaultReduce, getImageSrc } from '../../utils';
import { today, months, monthsOrdered, quarters, getQuarterOfMonthByIdx, quartersOrdered, monthsOrderedLeft, quartersOrderedLeft } from './utils'

export const initialState = {
    entities: {
        reports: {
            supplierPOs: {
                totals_by_totals: {},
                totals_by_tenant: {},
                totals_by_supplier: {},
                totals_by_order_rep: {},
                totals_by_client_rep: {},

            },
            supplierEvents: {
                totals_by_totals: {},
                totals_by_tenant: {},
                totals_by_supplier: {},
                totals_by_product: {},
                totals_by_industry: {},
                totals_by_events: {}
            },
            supplierProducts : {
                totals_by_product: {}
            },
            supplierPresales : {
                totals_by_order_rep: {},
                totals_by_client_rep: {},
            }
            // products 
        },
        billing_periods: {},
        tenants: {},
        suppliers: {},
        supplier_tags: [],
        products: {},
        supplierPOs: [],
        supplierEvents: [],
        supplierProducts: [],
        supplierPresales: []
    },
    loading: {
        supplierPOStats: false,
        supplierEventStats: false,
        supplierPOs: false,
        supplierEvents: false,
        supplierStats: false,
        supplierProducts: false,
        supplierPresales: false,
        // supplierpresales
        billing_periods: false
    },
};

const slice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = {
                ...state.loading,
                ...action.payload,
            };
        },

        fetchBillingPeriodsSuccess(state, action) {
          state.entities.billing_periods = (action.payload ?? []).reduce(
            (o, bp) => ({
              ...o,
              [bp.billing_period_id]: bp
	    }),
            {}
	  );
	},

        fetchBillingPeriodsFailure(state, action) {
          state.entities.billing_periods = false;
	},

        // Stats
        //SUPPLIER_PRESALES
        fetchSupplierPresalesSuccess(state, action) {
            let events = _.get(action.payload, 'data', []);
            const params = _.get(action.payload, 'params', {});
            const group_by = params.group_by;
            const totals_by = params.totals_by;
            const with_others = params.with_others;
            if (group_by) {
                if (['totals', 'tags', 'tenants_count'].includes(totals_by)) {
                    let sortByCurrent = true;
                    if (['tags', 'tenants_count'].includes(totals_by)) { sortByCurrent = false; }
                    const { data, years, labels } = parseGroupBy([...events], group_by, { ...params, totals_by, sortByCurrent });
                    events = { [`totals_by_${totals_by}`]: { data, years, labels } };
                } else if (totals_by) {
                    const data = parseTotalsBy([...events], { totals_by, group_by, until: _.get(params, 'until', 25), with_others });
                    events = { [`totals_by_${totals_by}`]: { data: data } };
                } else {
                    events = { data: events };
                }
            } else {
                if (['totals', 'tags', 'tenants_count'].includes(totals_by)) {
                    events = { [`totals_by_${totals_by}`]: { data: events } };
                } else if (totals_by) {
                    events = { [`totals_by_${totals_by}`]: { data: events } };
                } else {
                    events = { data: events };
                }
            }

            state.entities.reports.supplierPresales = {
                ...state.entities.reports.supplierPresales,
                ...events,
            };
            return state;
        },
        //one forSUPPLIER_PRODUCTS
        fetchSupplierProductsSuccess(state, action) {
            let pos = _.get(action.payload, 'data', []);

            const params = _.get(action.payload, 'params', {});
            const group_by = params.group_by;
            const totals_by = params.totals_by;
            const with_others = params.with_others;

            if (group_by) {
                if (['totals', 'tags', 'tenants_count'].includes(totals_by)) {
                    let sortByCurrent = true;
                    if (['tags', 'tenants_count'].includes(totals_by)) { sortByCurrent = false; }
                    const { data, years, labels } = parseGroupBy([...pos], group_by, { ...params, totals_by, sortByCurrent, });
                    pos = { [`totals_by_${totals_by}`]: { data, years, labels } };
                } else if (totals_by) {
                    const data = parseTotalsBy([...pos], { totals_by, group_by, until: _.get(params, 'until', 25), with_others });
                    pos = { [`totals_by_${totals_by}`]: { data: data } };
                } else {
                    pos = { data: pos };
                }
            } else {
                if (['totals', 'tags', 'tenants_count'].includes(totals_by)) {
                    pos = { [`totals_by_${totals_by}`]: { data: pos } };
                } else if (totals_by) {
                    pos = { [`totals_by_${totals_by}`]: { data: pos, } };
                } else {
                    pos = { data: pos };
                }
            }

            state.entities.reports.supplierProducts = {
                ...state.entities.reports.supplierProducts,
                ...pos,
            };
            return state;
        },
        fetchSupplierPOStatsSuccess(state, action) {
            let pos = _.get(action.payload, 'data', []);
            const params = _.get(action.payload, 'params', {});
            const group_by = params.group_by;
            const totals_by = params.totals_by;
            const with_others = params.with_others;

            if (group_by) {
                if (['totals', 'tags', 'tenants_count'].includes(totals_by)) {
                    let sortByCurrent = true;
                    if (['tags', 'tenants_count'].includes(totals_by)) { sortByCurrent = false; }
                    const { data, years, labels } = parseGroupBy([...pos], group_by, { ...params, totals_by, sortByCurrent, });
                    pos = { [`totals_by_${totals_by}`]: { data, years, labels } };
                } else if (totals_by) {
                    const data = parseTotalsBy([...pos], { totals_by, group_by, until: _.get(params, 'until', 25), with_others });
                    pos = { [`totals_by_${totals_by}`]: { data: data } };
                } else {
                    pos = { data: pos };
                }
            } else {
                if (['totals', 'tags', 'tenants_count'].includes(totals_by)) {
                    pos = { [`totals_by_${totals_by}`]: { data: pos } };
                } else if (totals_by) {
                    pos = { [`totals_by_${totals_by}`]: { data: pos, } };
                } else {
                    pos = { data: pos };
                }
            }

            state.entities.reports.supplierPOs = {
                ...state.entities.reports.supplierPOs,
                ...pos,
            };
            return state;
        },
        fetchSupplierPOStatsFailure(state, action) {
            //
        },
        fetchSupplierProductsFailure(state, action) {
            //
        },
        fetchSupplierPresalesFailure(state, action) {
            //
        },
        fetchSupplierEventStatsSuccess(state, action) {
            let events = _.get(action.payload, 'data', []);
            const params = _.get(action.payload, 'params', {});
            const group_by = params.group_by;
            const totals_by = params.totals_by;
            const with_others = params.with_others;
            if (group_by) {
                if (['totals', 'tags', 'tenants_count'].includes(totals_by)) {
                    let sortByCurrent = true;
                    if (['tags', 'tenants_count'].includes(totals_by)) { sortByCurrent = false; }
                    const { data, years, labels } = parseGroupBy([...events], group_by, { ...params, totals_by, sortByCurrent });
                    events = { [`totals_by_${totals_by}`]: { data, years, labels } };
                } else if (totals_by) {
                    const data = parseTotalsBy([...events], { totals_by, group_by, until: _.get(params, 'until', 25), with_others });
                    events = { [`totals_by_${totals_by}`]: { data: data } };
                } else {
                    events = { data: events };
                }
            } else {
                if (['totals', 'tags', 'tenants_count'].includes(totals_by)) {
                    events = { [`totals_by_${totals_by}`]: { data: events } };
                } else if (totals_by) {
                    events = { [`totals_by_${totals_by}`]: { data: events } };
                } else {
                    events = { data: events };
                }
            }

            state.entities.reports.supplierEvents = {
                ...state.entities.reports.supplierEvents,
                ...events,
            };
            return state;
        },
        fetchSupplierEventStatsFailure(state, action) {
            //
        },

        fetchSupplierPOsSuccess(state, action) {
            state.entities.supplierPOs = action.payload;
        },
        fetchSupplierPOsFailure(state, action) {
            //
        },
        fetchSupplierEventsSuccess(state, action) {
            state.entities.supplierEvents = action.payload;
        },
        fetchSupplierEventsFailure(state, action) {
            //
        },
        fetchTenantsSuccess(state, action) {
            state.entities.tenants = { ...state.entities.tenants, ...action.payload, };
        },
        fetchTenantsFailure(state, action) {
            //
        },
        fetchSuppliersSuccess(state, action) {
            state.entities.suppliers = { ...state.entities.suppliers, ...action.payload, };
        },
        fetchSuppliersFailure(state, action) {
            //
        },
        fetchSupplierTagsSuccess(state, action) {
            state.entities.supplier_tags = defaultReduce(action.payload, 'tag_id');
        },
        fetchSupplierTagsFailure(state, action) {
            //
        },
        fetchProductsSuccess(state, action) {
            // append or replace to the obj
            if (action.payload.append) {
                state.entities.products = {
                    ...state.entities.products,
                    ...action.payload.products,
                };
            } else {
                state.entities.products = action.payload;
            }
        },
        fetchProductsFailure(state, action) {
            //
        },
        updateProductState(state, action) {
            state.entities.products = {
                ...state.entities.products,
                [action.payload.product_id]: action.payload.product,
            };
        },
        updateProductsState(state, action) {
            state.entities.products = {
                ...state.entities.products,
                ...action.payload,
            };
        },
        clearSupplierPOsState(state, action) {
            state.entities.supplierPOs = [];
        },
        clearSupplierEventsState(state, action) {
            state.entities.supplierEvents = [];
        },
    }
});

export const {
    setLoading, fetchSupplierPOsSuccess, fetchSupplierPOsFailure,
    fetchSupplierEventsSuccess, fetchSupplierEventsFailure,
    fetchSupplierPOStatsSuccess, fetchSupplierPOStatsFailure,
    fetchSupplierEventStatsSuccess, fetchSupplierEventStatsFailure,
    fetchTenantsSuccess, fetchTenantsFailure,
    fetchSuppliersSuccess, fetchSuppliersFailure,
    fetchSupplierTagsSuccess, fetchSupplierTagsFailure,
    fetchProductsSuccess, fetchProductsFailure,
    updateProductState, updateProductsState,
    fetchBillingPeriodsSuccess, fetchBillingPeriodsFailure,
    clearSupplierPOsState, clearSupplierEventsState,fetchSupplierProductsSuccess,fetchSupplierProductsFailure, fetchSupplierPresalesSuccess, fetchSupplierPresalesFailure
} = slice.actions;

export default { [slice.name]: slice.reducer, };

function createAndUpdateGroupBy(options = {}) {
    const {
        keys = [], data = [],
        year = today.getFullYear(), month = "01",
        group_by = false, // month, quarter, year, ...
        group = false,
        gr_by_val = false, // if monthly => month, quarterly => quarter, yearly => year
        i = false, // year's index
        j = false, // group by's index (month index, quarter index)
    } = options || {};
    const exists = _.includes(keys, group);
   const url_path =  window.location.pathname;
    const isFutureMonth = group_by === 'month' && i === 0 && monthsOrderedLeft.includes(gr_by_val); //present yr index(i) is 0. we dont want 0 values for future months
    const isFutureQuarter = group_by === 'quarter' && i === 0 && quartersOrderedLeft.includes(gr_by_val);
    //REVERT
   
    if (!exists && !isFutureMonth && !isFutureQuarter) {
        data[group] = {
            tenant_counts: "0",
            group_by: group,
            total: "0",
            totals: "0",
            date_created: `${year}-${month}-01 10:01:01`,
            original: false,
            [`y${i + 1}`]: "0",
            [`y${i + 1}_totals`]: "0",
            [`y${i + 1}_group_by`]: gr_by_val,
            [`y${i + 1}_tenant_counts`]: "0",
            year: year,
            fillOpacity: 0.7,
            categoryX : gr_by_val
        };
    }
    if (data[group]) {
        data[group] = {
            ...data[group],
            [`y${i + 1}`]: data[group]['totals'],
            [`y${i + 1}_totals`]: data[group]['totals'],
            [`y${i + 1}_tenant_counts`]: data[group]['tenant_counts'],
            [`y${i + 1}_group_by`]: gr_by_val,
            [`y${i + 1}_year`]: year,
            year: year,
            fillOpacity: 0.7,
            ..._.get(options, 'extraOptions', {}),
            categoryX : gr_by_val
        }

        if (group_by == 'month' || group_by == 'quarter') {
            data[group][`y${i + 1}_gr${j + 1}`] = data[group]['totals'];
        }

        if ((group_by == 'month' && today.getMonth() == j)
            || (group_by == 'quarter' && getQuarterOfMonthByIdx(today.getMonth()) == gr_by_val)
            || (group_by == 'year' && today.getFullYear() == year)) {
            data[group]['fillOpacity'] = 1;
        }
    }

    return _.get(data, group, null);
  
}

function parseGroupBy(response, group_by, options = {sortByCurrent: true, totals_by: '', }) {
    let labels = [], data = {};
    const latest_year = options.date_to ? options.date_to.split('-')[0]  : today.getFullYear() + '';
    const latest_year_value = parseInt(latest_year);
    const prev_year= (latest_year_value - 1) + '';
    const past_year =(latest_year_value - 2) + '';
    const years = [latest_year, prev_year, past_year] ;
    const keys = [];
    let ordered = {};
  _.forEach(response, (v) => {
        v.group_by = String(v.group_by);
        const y = v.group_by.split(' ').length > 1 ?
            v.group_by.split(' ')[1] : v.group_by.split(' ')[0];
        if (!_.includes(years, y)) { years.push(y); }
        if (!_.includes(keys, v.group_by)) { keys.push(v.group_by); }
        data[v.group_by] = { ...v, year: y };
    });
    years.sort((a, b) => b - a);
    const commonOptions = { totals_by: options.totals_by, group_by, keys, data, };
    // group by year (then into months or quarters, if any given)
    _.forEach(years, (y, i) => {
        if (group_by == 'month') {
            _.forEach(months, (m, j) => {
                createAndUpdateGroupBy({
                    i, j, group: `${m} ${y}`, year: y,
                    gr_by_val: m, month: m,
                    ...commonOptions
                });
            });
        } else if (group_by == 'quarter') {
            _.forEach(quarters, (q, j) => {
                createAndUpdateGroupBy({
                    i, j, group: `${q} ${y}`, year: y,
                    gr_by_val: q, month: q,
                    ...commonOptions
                });
            });
        } else if (group_by == 'year') {
            createAndUpdateGroupBy({
                i, group: y, year: y, gr_by_val: y, month: "01",
                ...commonOptions
            });
        }
    }); 
    Object.keys(data).sort((a, b) => {
        if (group_by == 'month') {
            let gr = months;
            if (options.sortByCurrent) { gr = monthsOrdered; }
            return gr.indexOf(a.split(' ')[0])
                - gr.indexOf(b.split(' ')[0]);
        } else if (group_by == 'quarter') {
            let gr = quarters;
            if (options.sortByCurrent) { gr = quartersOrdered; }
            return gr.indexOf(a.split(' ')[0])
                - gr.indexOf(b.split(' ')[0]);
        }
        return a.split(' ') - b.split(' ')[0];
    }).forEach((key) => ordered[key] = data[key]);
    if (group_by == 'month') {
        labels = months;
    } else if (group_by == 'quarter') {
        labels = quarters;
    } else if (group_by == 'year') {
        labels = years;
    }
    return { data: ordered, labels, years, };
}

function parseTotalsBy(data, { totals_by, group_by, until = 10, with_others }) {
    const currentGrBy = group_by == 'quarter' ? getQuarterOfMonthByIdx(today.getMonth())
        : group_by == 'month' ? today.getMonth()
            : today.getFullYear();
    const currentData = _.filter(data, v => {
        v.group_by = String(v.group_by);
        return group_by == 'month' ? months[currentGrBy] == v.group_by.split(' ')[1]
            : group_by == 'quarter' ? "Q" + currentGrBy == v.group_by.split(' ')[1]
                : currentGrBy == v.group_by.split(' ')[1];
    });

    const prevMonth = months[currentGrBy == 0 ? 11 : currentGrBy - 1];
    const prevMonthYr = currentGrBy == 0 ? today.getFullYear()-1 : today.getFullYear();
    const prevQuarter = "Q" + (currentGrBy == 1 ? 4 : currentGrBy-1);
    const prevQuarterYr = currentGrBy == 1 ? today.getFullYear()-1 : today.getFullYear();
    const prevYr = currentGrBy - 1;
    const prevData = _.filter(data, v => {
        v.group_by = String(v.group_by);
        return group_by == 'month' ? prevMonth == v.group_by.split(' ')[1] && prevMonthYr == v.group_by.split(' ')[2]
            : group_by == 'quarter' ? prevQuarter == v.group_by.split(' ')[1] && prevQuarterYr == v.group_by.split(' ')[2]
                : prevYr == v.group_by.split(' ')[1];
    });
    const imgOptions = {
        defaultImage: ['tenant', 'tenants_count', 'order_rep', 'order_client_rep', 'industry', 'events'].includes(totals_by) // include industry
            ? '/images/user-avatar3-36x36.png' : '/images/404.png'
    };
    if (totals_by) {
        data = currentData.map((v, i) => ({
            gr1_subData: v.subData,
            gr1_totals: v.totals,
            gr1_tenant_name: v['tenant_name'],
            [`gr1_${totals_by}_name`]: v[`${totals_by}_name`],
            gr1_img_src: getImageSrc(_.get(v, 'file', {}), 'small', imgOptions),
            [`gr1_${totals_by}_id`]: v[`${totals_by}_id`],
            gr1_group_by: String(v.group_by),
            gr1_group: currentGrBy,
            gr1: group_by == 'month' ? months[currentGrBy]
                : group_by == 'quarter' ? "Q" + currentGrBy
                    : String(currentGrBy),
            hidden: i > 25,
            isActive:v['active'],
            tooltipText: v['active'] == 0 ? "This rep is no longer active with commonsku.": "",
            opacity:v['active'] == 0 ? 0.4 : 1
        })).concat(prevData.map((p, i) => ({
            gr2_subData: p.subData,
            gr2_totals: p.totals,
            gr2_tenant_name: p['tenant_name'],
            [`gr2_${totals_by}_name`]: p[`${totals_by}_name`],
            gr2_img_src: getImageSrc(_.get(p, 'file', {}), 'small', imgOptions),
            [`gr2_${totals_by}_id`]: p[`${totals_by}_id`],
            gr2_group_by: p.group_by + '',
            gr2_group: (group_by == 'month'
                ? currentGrBy == 0 ? 11 : currentGrBy - 1
                : group_by == 'quarter'
                ? currentGrBy == 1 ? 4 : currentGrBy-1
                : currentGrBy - 1) + '',
            gr2: (group_by == 'month' ? prevMonth
                : group_by == 'quarter' ? prevQuarter
                    : currentGrBy - 1) + '',
            hidden: i > 25,
            isActive: p['active'],
            tooltipText: p['active'] == 0 ? "This rep is no longer active with commonsku.": "",
            opacity: p['active'] == 0 ? 0.4 : 1
        })));
    }

    return data;
}

export const fetchBillingPeriods = () => async (dispatch, getState) => {
  const state = getState();
  if (
    state.dashboard.loading.billing_periods ||
    false === state.dashboard.entities.billing_periods
  ) {
    return;
  }
  dispatch(setLoading({ billing_periods: true }));
  try {
    const { json } = await oauth('GET', 'billing-period', {});
    if (json) {
      if (json.error) {
        dispatch(fetchBillingPeriodsFailure(json.error));
      } else {
        dispatch(fetchBillingPeriodsSuccess(json.billing_periods));
      }
    } else {
      dispatch(fetchBillingPeriodsFailure('Unable to fetch billing periods.'));
    }
  } catch (error) {
      dispatch(fetchBillingPeriodsFailure('Unable to fetch billing periods.'));
  } finally {
    dispatch(setLoading({ billing_periods: false }));
  }
};

export const fetchSupplierPOStats = (params = {}) => async dispatch => {
    const group_by = _.get(params, 'group_by', false);
    const totals_by = _.get(params, 'totals_by', false);

    dispatch(setLoading({ [`SUPPLIER_POS_${totals_by}_${group_by}`]: true }));
    try {
        const { json } = await oauth('INDEX', 'supplier-po-stat', params);

        if (json && !json.error) {
            const data = _.get(json, 'pos', []);
            dispatch(fetchSupplierPOStatsSuccess({ data, params }));
        } else {
            if (json.error) {
                dispatch(fetchSupplierPOStatsSuccess(json.error));
            } else {
                dispatch(fetchSupplierPOStatsFailure('Cannot fetch pos.'));
            }
        }

        dispatch(setLoading({ [`SUPPLIER_POS_${totals_by}_${group_by}`]: false }));
    } catch (error) {
        console.error('ERROR|fetchSupplierPOStats| ', error);
        dispatch(fetchSupplierPOStatsFailure('Cannot fetch pos.'));
    }
};

export const fetchSupplierPresales = (params = {}) => async dispatch => {
    const group_by = _.get(params, 'group_by', false);
    const totals_by = _.get(params, 'totals_by', false);
    dispatch(setLoading({ [`SUPPLIER_PRESALES_${totals_by}_${group_by}`]: true }));
    try {
        const { json } = await oauth('INDEX', 'supplier-po-stat', params);
        if (json && !json.error) {
            const data = _.get(json, 'pos');
            dispatch(fetchSupplierPresalesSuccess({ data, params }));
        } else {
            dispatch(fetchSupplierPresalesFailure('Cannot fetch events.'));
        }
    } catch (error) {
        console.error('ERROR|fetchSupplierEventStats| ', error);
        dispatch(fetchSupplierPresalesFailure('Cannot fetch events.'));
    }

    dispatch(setLoading({ [`SUPPLIER_PRESALES_${totals_by}_${group_by}`]: false }));

    return null;
};

export const fetchSupplierEventStats = (params = {}) => async dispatch => {
    const group_by = _.get(params, 'group_by', false);
    const totals_by = _.get(params, 'totals_by', false);
    dispatch(setLoading({ [`SUPPLIER_EVENTS_${totals_by}_${group_by}`]: true }));
    try {
        const { json } = await oauth('INDEX', 'supplier-event-stat', params);
        if (json && !json.error) {
            const data = _.get(json, 'events');
            dispatch(fetchSupplierEventStatsSuccess({ data, params }));
        } else {
            dispatch(fetchSupplierEventStatsFailure('Cannot fetch events.'));
        }
    } catch (error) {
        console.error('ERROR|fetchSupplierEventStats| ', error);
        dispatch(fetchSupplierEventStatsFailure('Cannot fetch events.'));
    }

    dispatch(setLoading({ [`SUPPLIER_EVENTS_${totals_by}_${group_by}`]: false }));

    return null;
};

export const fetchSupplierPOs = (params = {}, stats=true) => async dispatch => {
    dispatch(setLoading({ SUPPLIER_POS: true }));
    try {
        const { json } = await oauth(
            stats ? 'INDEX' : 'GET',
            stats ? 'supplier-event-stat' : 'supplier/get-events-data',
            params
        );

        if (json && !json.error) {
            const pos = _.get(json, 'pos', []);
            dispatch(fetchSupplierPOsSuccess(pos));
        } else {
            if (json.error) {
                dispatch(fetchSupplierPOsSuccess(json.error));
            } else {
                dispatch(fetchSupplierPOsFailure('Cannot fetch pos.'));
            }
        }

        dispatch(setLoading({ SUPPLIER_POS: false }));
    } catch (error) {
        console.error('ERROR|fetchSupplierPOs| ', error);
        dispatch(fetchSupplierPOsFailure('Cannot fetch pos.'));
    }
};

export const fetchSupplierEvents = (params = {}, stats=true) => async dispatch => {
    dispatch(setLoading({ SUPPLIER_EVENTS: true }));
    try {
        const { json } = await oauth(
            stats ? 'INDEX' : 'GET',
            stats ? 'supplier-event-stat' : 'supplier/get-events-data',
            params
        );
        if (json && !json.error) {
            const events = _.get(json, 'events');
            dispatch(fetchSupplierEventsSuccess(events));
        } else {
            dispatch(fetchSupplierEventsFailure('Cannot fetch events.'));
        }
    } catch (error) {
        console.error('ERROR|fetchSupplierEvents| ', error);
        dispatch(fetchSupplierEventsFailure('Cannot fetch events.'));
    }

    dispatch(setLoading({ SUPPLIER_EVENTS: false }));

    return null;
};

export const fetchTenants = (params = {}) => async dispatch => {
    dispatch(setLoading({ tenants: true }));
    try {
        dispatch(setLoading({ tenants: false }));
        const { json } = await oauth('INDEX', 'tenant', params);
        if (json && !json.error) {
            const resp = _.get(json, 'tenants', []);
            let data = defaultReduce(resp, 'tenant_id');
            dispatch(fetchTenantsSuccess(data));
            return data;
        } else {
            dispatch(fetchTenantsFailure('Cannot fetch tenants.'));
        }
    } catch (error) {
        dispatch(setLoading({ tenants: false }));
        console.error('ERROR|fetchTenants| ', error);
        dispatch(fetchTenantsFailure('Cannot fetch tenants'));
    }

    return [];
};

export const fetchSuppliers = (params = {}) => async dispatch => {
    dispatch(setLoading({ suppliers: true }));
    try {
        dispatch(setLoading({ suppliers: false }));
        const { json } = await oauth('INDEX', 'supplier', params);
        if (json && !json.error) {
            const resp = _.get(json, 'suppliers', []);
            let data = defaultReduce(resp, 'supplier_id');
            dispatch(fetchSuppliersSuccess(data));
            return data;
        } else {
            dispatch(fetchSuppliersFailure('Cannot fetch suppliers.'));
        }
    } catch (error) {
        dispatch(setLoading({ suppliers: false }));
        console.error('ERROR|fetchSuppliers| ', error);
        dispatch(fetchSuppliersFailure('Cannot fetch suppliers'));
    }
    return [];
};

export const fetchSupplierTags = (params = {}) => async dispatch => {
    dispatch(setLoading({ supplier_tags: true }));
    try {
        dispatch(setLoading({ supplier_tags: false }));
        const { json } = await oauth('GET', 'tag/supplier-dashboard-tags', params);
        if (json && !json.error) {
            const resp = _.get(json, 'tags', []);
            dispatch(fetchSupplierTagsSuccess(resp));
        } else {
            dispatch(fetchSupplierTagsFailure('Cannot fetch supplier tags.'));
        }
    } catch (error) {
        dispatch(setLoading({ supplier_tags: false }));
        console.error('ERROR|fetchSupplierTags| ', error);
        dispatch(fetchSupplierTagsFailure('Cannot fetch supplier tags'));
    }
};

export const fetchProduct = (product_id, params = {}) => async dispatch => {
    dispatch(setLoading({ product: true }));
    try {
        dispatch(setLoading({ product: false }));
        const { json } = await oauth('GET', `product/${product_id}`, params);
        if (json && !json.error) {
            const resp = _.get(json, 'product', {});
            const products = defaultReduce([resp], 'product_id');
            dispatch(fetchProductsSuccess({ products, append: true }));
        } else {
            dispatch(fetchProductsFailure('Cannot fetch product.'));
        }
    } catch (error) {
        dispatch(setLoading({ product: false }));
        console.error('ERROR|fetchProduct| ', error);
        dispatch(fetchProductsFailure('Cannot fetch product'));
    }
};

export const fetchProducts = (params = {}) => async dispatch => {
    dispatch(setLoading({ products: true }));
    try {
        dispatch(setLoading({ products: false }));
        const { json } = await oauth('INDEX', 'product', params);
        if (json && !json.error) {
            const resp = _.get(json, 'products', []);
            const products = defaultReduce(resp, 'product_id');
            dispatch(fetchProductsSuccess(products));
        } else {
            dispatch(fetchProductsFailure('Cannot fetch product.'));
        }
    } catch (error) {
        dispatch(setLoading({ products: false }));
        console.error('ERROR|fetchProduct| ', error);
        dispatch(fetchProductsFailure('Cannot fetch product'));
    }
};

export const getSupplierStatsParams = (type, totals_by = 'totals', options = {}) => {
    return {
        type,
        totals_by: totals_by,
        group_by: options.group_by,
        compare_previous: options.compare_previous || 0,
        tenant_id: options.tenant_id || '',
        limit: options.limit || '',
        with_others: options.with_others || 0,
        until: options.until || '',
        division_id: options.division_id || '',
        with_tenants: options.with_tenants || '',
        order_rep_id: options.order_rep_id || '',
        order_client_rep_id: options.order_client_rep_id || '',

        tenant_ids: options.tenant_ids || '',
        supplier_account_ids: options.supplier_account_ids || '',
        supplier_tags: options.supplier_tags || '',

        date_from: options.date_from,
        date_to: options.date_to,
        month: options.month || '',
        quarter: options.quarter || '',
        year: options.year || '',

        prev_year: options.prev_year || '',
        prev_quarter: options.prev_quarter || '',
        prev_month: options.prev_month || '',
        prev_date_from: options.prev_date_from || '',
        prev_date_to: options.prev_date_to || '',
    };
};

export const fetchMultipleSupplierStats = (calls_data = []) => async dispatch => {
    dispatch(setLoading({ supplierStats: true }));
    function onSuccess(type = "SUPPLIER_POS", data) {
        if (type.includes("SUPPLIER_POS")) {  
            return fetchSupplierPOStatsSuccess(data);
        }
        if (type.includes("SUPPLIER_PRODUCTS")) {  
            return fetchSupplierProductsSuccess(data);
        }
         if (type.includes("SUPPLIER_PRESALES")) {
            return fetchSupplierPresalesSuccess(data);

        }
        return fetchSupplierEventStatsSuccess(data);
    }
    function onFail(type = "SUPPLIER_POS", data) {
        if (type.includes("SUPPLIER_POS")) {
            return fetchSupplierPOStatsFailure(data);
            //4 types fetchSupplierProductsFailure make new methods 
        }
        if (type.includes("SUPPLIER_PRODUCTS")) {
            return fetchSupplierProductsFailure(data);
            //4 types fetchSupplierProductsFailure make new methods 
        }
        if (type.includes("SUPPLIER_PRESALES")) {
            return fetchSupplierPresalesFailure(data);
        }

        return fetchSupplierEventStatsFailure(data);
    }

    try {
        const promises = calls_data.map((v, i) => {
            const params = getSupplierStatsParams(v.type, v.totals_by, v.params);
            calls_data[i].params = params;
            if (v.type.includes('SUPPLIER_POS') || v.type.includes('SUPPLIER_PRESALES')) {
                return oauth('INDEX', 'supplier-po-stat', params, null, null, null, false);
            } else if (v.type.includes('SUPPLIER_EVENTS') || v.type.includes('SUPPLIER_PRODUCTS')) {
                return oauth('INDEX', 'supplier-event-stat', params, null, null, null, false);
            }
            // if SUPPLIER_PRODUCTS->SUPPLIER_EVENTS
            //if SUPPLIER_PRESALES (po stat)->
        });
        const results = await Promise.all(promises);
        dispatch(setLoading({ supplierStats: false }));

        results.forEach((v, i) => {
            const call_data = calls_data[i];
            const key = call_data.type.includes("SUPPLIER_POS") || call_data.type.includes('SUPPLIER_PRESALES') ? 'pos' : 'events';
            //4 keys
            try {
                const { json } = v;
                if (json && !json.error) {
                    let data = _.get(json, key, []);
                    if (call_data.totals_by == "totals") {
                        data = parseTotals(data, call_data.params.group_by, call_data.type);
                    }
                    dispatch(onSuccess(call_data.type, { data, params: call_data.params, }));
                } else {
                    dispatch(onFail(call_data.type, `Cannot fetch ${key}.`));
                }
            } catch (error) {
                console.error(`ERROR|fetch_${call_data.type}| `, error);
                dispatch(onFail(call_data.type, `Cannot fetch .${key}`));
            }
        });

        return results;
    } catch(e) {
        dispatch(setLoading({ supplierStats: false }));
        console.error('ERROR|fetchMultipleSupplierStats');
        console.error(e);
    }
};

function parseTotals(data, group_by, type) {
    let result = data;
    const commonData = {
        tenant_counts: "0",
        ...(type === "SUPPLIER_POS" ? {
            totals: "0.0000",
            po_total: "0.0000",
        } : {
            event_count: "0",
            totals: "0",
        }),
    };
    if (group_by === 'month') {
        const hasPrevYr = data.filter(d => (d.month_yr || '').indexOf(today.getFullYear()-1) !== -1).length > 0;
        if (!hasPrevYr) {
            months.forEach((m, mi) => {
                const found = data.findIndex(d => d.group_by == m + " " + (today.getFullYear()));
                if (found === -1) {
                    result.push({
                        month_yr: (today.getFullYear()) + "-" + ((mi+1) + "").padStart(2, '0') +"-01",
                        group_by: m + " " + (today.getFullYear()),
                        ...commonData,
                    });
                }
                const foundPrev = data.findIndex(d => d.group_by == m + " " + (today.getFullYear()-1));
                if (foundPrev === -1) {
                    result.push({
                        month_yr: (today.getFullYear()-1) + "-" + ((mi+1) + "").padStart(2, '0') +"-01",
                        group_by: m + " " + (today.getFullYear()-1),
                        ...commonData,
                    });
                }
                    const foundPast = data.findIndex(d => d.group_by == m + " " + (today.getFullYear()-2));
                    if (foundPast === -1) {
                        result.push({
                            month_yr: (today.getFullYear()-2) + "-" + ((mi+1) + "").padStart(2, '0') +"-01",
                            group_by: m + " " + (today.getFullYear()-2),
                            ...commonData,
                        })
                    }
            });
        }
    } else if (group_by === 'quarter') {
        quarters.forEach((q, qi) => {
            const found = data.findIndex(d => d.group_by == q + " " + (today.getFullYear()));
            if (found === -1) {
                result.push({
                    "month_yr": (today.getFullYear()) + "-" + ((qi+1) + "").padStart(2, '0') +"-01",
                    "group_by": q + " " + (today.getFullYear()),
                    ...commonData,
                });
            }
            const foundPrev = data.findIndex(d => d.group_by == q + " " + (today.getFullYear()-1));
            if (foundPrev === -1) {
                result.push({
                    "month_yr": (today.getFullYear()-1) + "-" + ((qi+3) + "").padStart(2, '0') +"-01",
                    "group_by": q + " " + (today.getFullYear()-1),
                    ...commonData,
                });
            }
        });
    } else if (group_by === 'year') {
        const hasPrevYr = data.filter(d => (d.month_yr || '').indexOf(today.getFullYear()-1) !== -1).length > 0;
        if (!hasPrevYr) {
            result.push({
                "month_yr": (today.getFullYear()-1) + "-01-01",
                "group_by": "" + (today.getFullYear()-1),
                ...commonData,
            });
        }
            const hasPastYr = data.filter(d=> (d.month_yr || '').indexOf(today.getFullYear()-2) !== -1).length > 0;
            if (!hasPastYr) {
                result.push({
                    "month_yr": (today.getFullYear()-2) + "-01-01",
                    "group_by": "" + (today.getFullYear()-2),
                    ...commonData,
                })
            }
    }

    return result;
}
