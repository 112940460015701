import React, { useState } from 'react';
import { colors, themeOptions } from '@commonsku/styles';
import { DashboardTile } from '../helpers';
import GotAnIdeaPopup from './GotAnIdeaPopup';

export default function WelcomeDashboardTile({
    maxHeight = 170,
    ...props
}) {
    const [showGotIdeaForm, setShowGotIdeaForm] = useState(false);

    return (
        <>
        {showGotIdeaForm && <GotAnIdeaPopup onClose={() => setShowGotIdeaForm(false)} />}
            <DashboardTile style={{
                textAlign: 'center',
                color: '#16344E',
                overflowY: 'auto',
                height: maxHeight,
                overflow: 'hidden',
            }}>
                <span style={{
                display: 'block',
                marginBlockStart: '0.8em',
                marginBlockEnd: '0.8em',
                fontFamily: themeOptions.fontFamilies.regular,
                fontSize: 16,
                fontWeight: 600,
                }}>Welcome to the new dashboard!</span>
                <span
                style={{
                    margin: 0,
                    display: 'block',
                    fontFamily: themeOptions.fontFamilies.regular,
                    fontSize: 14,
                    cursor: 'pointer',
                    color: colors.primary,
                }}
                onClick={() => { setShowGotIdeaForm && setShowGotIdeaForm(true);}}
                >Have ideas for more tiles to make your work easier?</span>
            </DashboardTile>
        </>
    );
}
