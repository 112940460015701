import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Link, Textarea, Popup, Dropzoned } from '@commonsku/styles';
import DateInput, { LabeledDateInput } from '../DateInput';
import { LabeledSelect } from '../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { createMessage, updateMessage } from '../../redux/messages';
import { useIdentity } from '../../hooks';
import { createUploadFile } from '../../actions/file';
import MentionTextarea from '../MentionTextarea';
import { getUserDropdown, getUserOptions } from '../../selectors/dropdowns';

function AddTaskFormPopup({
  message = {},
  onClose
}) {
  const dispatch = useDispatch();
  const identity = useIdentity();
  const users_options = useSelector(s => getUserOptions(s).map(v => ({
    label: v.value,
    value: v.key,
  })));
  const mention_users = useSelector(getUserDropdown);

  const [selectedFile, setFile] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [state, setState] = useState({
    message_id: message.message_id || '',
    note_id: message.note_id || '',
    date_reminder: message.date_reminder || '',
    date_complete: message.date_complete || '',
    details_parent_id: message.details_parent_id || '',
    details_parent_type: message.details_parent_type || '',
    details_type: message.details_type || 'NOTE',
    message_text: message.message_text || '',
    message_type: message.message_type || '',
    note_type: message.note_type || 'NOTE',
    reminder_user: message.reminder_user || _.get(identity, 'user_id', ''),
    tagged_users: message.tagged_users || '',
  });
  const updateField = (field, value) => setState(s => ({ ...s, [field]: value }));
  const handleSave = () => {
    if (state.message_id) {
      dispatch(updateMessage(state));
      onClose && onClose();
      return;
    }
    Promise.resolve(dispatch(createMessage(state))).then(resp => {
      if (!resp || !resp.note_id || !selectedFile) { return; }
      uploadFile(resp.note_id, selectedFile);
    });
    onClose && onClose();
  };

  const uploadFile = (parent_id, file_blob, folder_id = null) => {
    try {
      dispatch(createUploadFile(parent_id, 'NOTE', file_blob, folder_id));
    } catch (error) {
      console.log('UPLOAD ERROR: ', error);
    }
  };

  useEffect(() => {
    setState({
      message_id: message.message_id || '',
      note_id: message.note_id || '',
      date_reminder: message.date_reminder || '',
      date_complete: message.date_complete || '',
      details_parent_id: message.details_parent_id || '',
      details_parent_type: message.details_parent_type || '',
      details_type: message.details_type || 'NOTE',
      message_text: message.message_text || '',
      message_type: message.message_type || '',
      note_type: message.note_type || 'NOTE',
      reminder_user: message.reminder_user || _.get(identity, 'user_id', ''),
      tagged_users: message.tagged_users || '',
    });
  }, [identity, message]);

  return (
    <Popup
      title="Create Task"
      controls={<>
        <Button cta style={{ marginRight: '10px' }} onClick={handleSave}>Post</Button>
        <Button onClick={onClose}>Cancel</Button>
      </>}
    >
      <Row style={{ background: '#EEFDFF' }}>
        <Col padded xs className="resku">
          <MentionTextarea
            mention_users={mention_users}
            value={state.message_text}
            onChange={value => updateField('message_text', value)}
          />
        </Col>
        <Col padded xs md={6}>
          <DateInput
            customInput={<LabeledDateInput date={state.date_reminder} label="Task Date" />}
            showMonthDropdown
            showYearDropdown
            value={state.date_reminder}
            onChange={dateStr => updateField('date_reminder', dateStr)}
            portalId="root-portal"
            style={{ width: '100%' }}
          />
        </Col>
        <Col padded xs md={6}>
          <LabeledSelect
            label="Assign to"
            name="task_assigned_to"
            options={users_options}
            value={state.reminder_user}
            onChange={v => updateField('reminder_user', v.value)}
            inPopup
          />
        </Col>
        <Col xs></Col>
        <Col xs={3} sm={2} md={1}>
          <Link onClick={() => setShowFiles(s => !s)}>+ File</Link>
        </Col>
        <Col xs>
          {showFiles ? <Dropzoned style={{ width: '100%' }}
            placeholder="Upload Files"
            showDroppedFiles={true}
            onDrop={(acceptedFiles) => {
              if (acceptedFiles.length > 0) {
                setFile(acceptedFiles[0]);
              }
            }}
          /> : null}
        </Col>
      </Row>
    </Popup>);
};

export default AddTaskFormPopup;
