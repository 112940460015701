import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Col, Row,
} from '@commonsku/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useIdentity } from '../../hooks';
import { updateNote } from '../../redux/notes';
import { DashboardTile, rebuildTooltip } from '../helpers';
import {
  NoteSidepanel,
  LazyNotesCalendar,
} from '../message/';
import {
  getRequestDataByReport,
  REPORT_TYPE,
  selectors as reportsSelectors,
} from '../../redux/reports';
import DashboardPageTitle from './DashboardPageTitle';
import { NewProjectPopupButton } from '../project/NewProjectPopup';
import { getIdentityUtils } from '../../utils';
import { parseFilters } from './finance/utils';
import PageContent from './finance/PageContent';
import PageFilters from './finance/PageFilters';
import PagePopups from './finance/PagePopups';

const report_types = [
  'INVOICE_OPEN_STATUS_TOTALS',
  'SALES_ORDER_READY_INVOICING_STATUS_TOTALS',
  'SALES_ORDER_IN_PRODUCTION_STATUS_TOTALS',
  'CLIENTS_ON_CREDIT_HOLD',
  'DEPOSIT_INVOICES_NOT_PAID_UNINVOICED',
  'DEPOSIT_INVOICES_PAID_SO_NOT_IN_PRODUCTION',
];

function Dashboard(props) {
  const dispatch = useDispatch();
  const identity = useIdentity();
  const { isAdmin } = getIdentityUtils(identity);

  const reportsLoading = useSelector(reportsSelectors.loading);
  const isLoading = useSelector(s => {
    const rLoading = reportsSelectors.loading(s);
    return s.display.loading.fetch_pos_for_production
     || report_types.filter(
        v => _.get(rLoading, v, false)
      ).length > 0;
  });

  const [selectedTaskId, setSelectedTaskId] = useState(false);
  const [showPageLoading, setShowPageLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(null);
  const [filters, setFilters] = useState({
    rep_type: 'CLIENT',
    rep_id: isAdmin ? '' : identity.user_id,
    team_id: '',
  });

  const onUpdateTask = (note) => {
    dispatch(updateNote(note.note_id, note));
  };

  const getFilters = React.useCallback((report_type = '') => {
    return parseFilters(filters, report_type);
  }, [filters]);

  useEffect(() => {
    const f = getFilters();
    rebuildTooltip();
    if (f.rep_id === 'GROUP' && !f.team_id) {
      return;
    }

    setShowPageLoading(true);
    const reqs = Object.values(REPORT_TYPE)
      .filter(v => report_types.includes(v.type))
      .reduce((acc, v) => {
        const reportFilters = getFilters(v.type);
        const reqData = getRequestDataByReport(v.type, {
          ...reportFilters,
          'max-results': 1,
        });
        if (!reqData) { return acc; }
        return [...acc, reqData];
      }, []);

    (async () => {
      await Promise.all(
        reqs.map(
          v => dispatch(v.cb(v.route, v.type, v.params))
        )
      );
    })();
  }, [dispatch, getFilters]);

  const onClickNumberTile = React.useCallback(v => () => {
    const report_type = v.report_type || '';
    const order_type = v.params.order_type;
    const status_id = v.params.status_id;
    const f = getFilters(report_type);
    const reqData = getRequestDataByReport(report_type, f);
    if (!reqData) { return; }
    setShowPageLoading(false);
    dispatch(reqData.cb(reqData.route, reqData.type, {
      ...reqData.params,
      report_type: report_type,
      get_data: 1,
      ...v.params
    }));

    const title = `${v.title} - ${v.text || ''}`;
    const listType = v.type;

    setShowPopup({
      type: listType,
      title: title,
      report_type: v.report_type,
      order_type: order_type,
      status_id: status_id,
      loading: v.loading || _.get(reportsLoading, [v.report_type], false),
      storeLoadingKeys: ['reports', 'loading', v.report_type],
      filters: f,
    });
  }, [dispatch, getFilters, reportsLoading]);

  return (<Row>
    <Col xs padded>
      <NoteSidepanel
        noteId={selectedTaskId}
        clearnoteId={() => setSelectedTaskId(false)}
        onUpdateNote={onUpdateTask}
      />
      <PagePopups
        identity={identity}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
      />
      <Row style={{ marginTop: 0, marginBottom: 20, }}>
        <Col xs sm={6} padded>
          <DashboardPageTitle title={"Finance Dashboard"} />
        </Col>
        <Col xs
          sm={6}
          padded
          end={1}
        ><NewProjectPopupButton /></Col>
      </Row>
      <PageFilters
        identity={identity}
        filters={filters}
        setFilters={setFilters}
      />
      <PageContent
        onClickNumberTile={onClickNumberTile}
        isLoading={isLoading && showPageLoading}
        filters={filters}
      />
      <Row style={{...(isLoading && showPageLoading ? {display: 'none'} : {})}}>
        <Col xs padded>
          <DashboardTile padded>
            <LazyNotesCalendar
              noteId={selectedTaskId}
              onClickNote={setSelectedTaskId}
              noteUserId={filters.rep_id}
              onAddTask={e => {
                setShowPopup({action: 'add_task'});
              }}
            />
          </DashboardTile>
        </Col>
      </Row>
    </Col>
  </Row>);
}

export default Dashboard;
