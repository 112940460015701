import _ from 'lodash';
import { REPORT_TYPE } from '../../../redux/reports';

export function parseReports(reports) {
  const salesCurrentReport = _.get(reports, ['SALES_TOTALS', 'current_week'], {});
  const salesPrevReport = _.get(reports, ['SALES_TOTALS', 'past_week'], {});

  const potentialRepeatOrders = _.get(reports, ['POTENTIAL_REPEAT_ORDERS'], {});
  const clientWithNoRecentActivity = _.get(reports, ['CLIENTS_WITH_NO_RECENT_ACTIVITY'], {});
  const invoiceOpenStatusTotals = _.get(reports, ['INVOICE_OPEN_STATUS_TOTALS'], {});
  const soReadyToBeInvoicedTotals = _.get(reports, ['SALES_ORDER_READY_INVOICING_STATUS_TOTALS'], {});
  const commissionUnpaidTotals = _.get(reports, ['COMMISSION_UNPAID_TOTALS'], {});
  const clientsOnCreditHold = _.get(reports, ['CLIENTS_ON_CREDIT_HOLD'], {});
  const depositInvoicesNotPaid = _.get(reports, ['DEPOSIT_INVOICES_NOT_PAID'], {});
  const depositInvoicesNotPaidUnInvoiced = _.get(reports, ['DEPOSIT_INVOICES_NOT_PAID_UNINVOICED'], {});
  const depositInvoicesPaidSOInProduction = _.get(reports, ['DEPOSIT_INVOICES_PAID_SO_NOT_IN_PRODUCTION'], {});
  const soInProduction = _.get(reports, ['SALES_ORDER_IN_PRODUCTION_STATUS_TOTALS'], {});

  const presalesCurrentCount = parseInt(salesCurrentReport.num_estimates || 0)
    + parseInt(salesCurrentReport.num_opportunities || 0)
    + parseInt(salesCurrentReport.num_presentations || 0);
  const presalesCurrentTotal = parseFloat(salesCurrentReport.total_estimate_budget || 0)
    + parseFloat(salesCurrentReport.total_opportunity_budget || 0)
    + parseFloat(salesCurrentReport.total_presentation_budget || 0);
  const presalesPrevCount = parseInt(salesPrevReport.num_estimates || 0)
    + parseInt(salesPrevReport.num_opportunities || 0)
    + parseInt(salesPrevReport.num_presentations || 0);
  const presalesPrevTotal = parseFloat(salesPrevReport.total_estimate_budget || 0)
    + parseFloat(salesPrevReport.total_opportunity_budget || 0)
    + parseFloat(salesPrevReport.total_presentation_budget || 0);

  const OPEN_ORDER_STATUS_TOTALS = REPORT_TYPE.OPEN_ORDER_STATUS_TOTALS.type;
  const openOrderStatusTotals = _.get(reports, [OPEN_ORDER_STATUS_TOTALS, 'summary'], {});

  const presalesPendingClientApproval = _.get(reports, REPORT_TYPE.PRESALES_PENDING_CLIENT_APPROVAL.type, {});
  const soPendingClientApproval = _.get(reports, REPORT_TYPE.SALES_ORDERS_PENDING_CLIENT_APPROVAL.type, {});
  const projectsWithNoBudget = _.get(reports, REPORT_TYPE.PROJECTS_WITH_NO_BUDGET.type, {});
  const projectsWithNoInhandsDate = _.get(reports, REPORT_TYPE.OVERDUE_IN_HANDS_DATE_PROJECTS.type, {});

  const poStatusReport = _.get(reports, ['PO_STATUS_TOTALS'], {});

  return {
    presales: {
      prevTotalCount: presalesPrevCount,
      prevTotalCurrency: presalesPrevTotal,
      totalCount: presalesCurrentCount,
      totalCurrency: presalesCurrentTotal,
    },
    inProduction: {
      prevTotalCount: parseInt(salesPrevReport.num_in_production || 0),
      prevTotalCurrency: parseFloat(salesPrevReport.total_in_production || 0),
      totalCount: parseInt(salesCurrentReport.num_in_production || 0),
      totalCurrency: parseFloat(salesCurrentReport.total_in_production || 0),
    },
    invoices: {
      prevTotalCount: parseInt(salesPrevReport.num_invoices || 0),
      prevTotalCurrency: parseFloat(salesPrevReport.total_invoices || 0),
      totalCount: parseInt(salesCurrentReport.num_invoices || 0),
      totalCurrency: parseFloat(salesCurrentReport.total_invoices || 0),
    },
    presalesPendingClientApproval: {
      totals: parseInt(presalesPendingClientApproval.totals || 0),
      data: presalesPendingClientApproval.data,
    },
    soPendingClientApproval: {
      totals: parseInt(soPendingClientApproval.totals || 0),
      data: soPendingClientApproval.data,
    },
    projectsWithNoBudget: {
      totals: parseInt(projectsWithNoBudget.totals || 0),
      data: projectsWithNoBudget.data,
    },
    projectsWithNoInhandsDate: {
      totals: parseInt(projectsWithNoInhandsDate.totals || 0),
      data: projectsWithNoInhandsDate.data,
    },
    openOrderStatusTotals,
    potentialRepeatOrders,
    clientWithNoRecentActivity,
    clientsOnCreditHold,
    poStatusReport,
    commissionUnpaidTotals,
    depositInvoicesNotPaid,
    depositInvoicesNotPaidUnInvoiced,
    depositInvoicesPaidSOInProduction,
    invoiceOpenStatusTotals: parseInt(_.get(
      invoiceOpenStatusTotals, ['summary', 'num_open'], 0
    ) || 0),
    soReadyToBeInvoicedTotals: parseInt(_.get(
      soReadyToBeInvoicedTotals, ['summary', 'num_ready_to_be_invoiced'], 0
    ) || 0),
    soInProductionTotals: parseInt(_.get(
      soInProduction, ['summary', 'num_in_production'], 0
    ) || 0),
    soInProduction,
  };
}
