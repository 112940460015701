import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { Row, Col, colors, ChevronIcon, BulletIcon } from "@commonsku/styles";
import { isVisibleHorizontal } from "../../utils";
import { window } from "../../global";

interface DragPosition {
  isDown: boolean;
  startX: number;
  scrollLeft: number;
}

interface ScrollPosition {
  scrollWidth: number;
  startIdx: number;
  endIdx: number;
}

interface TilesSliderProps {
  deps?: any[];
  sliderHeight?: number;
  isDraggable?: boolean;
  children?: React.ReactNode;
}

export function TilesSlider({
  deps = [],
  sliderHeight = 190,
  isDraggable = true,
  children,
}: TilesSliderProps) {
  const [dragPos, setDragPos] = useState<DragPosition>({
    isDown: false,
    startX: -1,
    scrollLeft: -1,
  });
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>({
    scrollWidth: 180,
    startIdx: -1,
    endIdx: -1,
  });

  const parentRef = useRef<HTMLDivElement | null>(null);
  const startTileRef = useRef<{ index: number; e: HTMLElement | null }>({
    index: -1,
    e: null,
  });
  const endTileRef = useRef<{ index: number; e: HTMLElement | null }>({
    index: -1,
    e: null,
  });

  const [currentPage, setCurrentPage] = useState(0);

  const perPage = parentRef.current
    ? Math.ceil(parentRef.current.clientWidth / scrollPosition.scrollWidth)
    : 1;
  const pages = parentRef.current
    ? Math.ceil(parentRef.current.children.length / perPage)
    : 1;

  const updateScrollPosition = useCallback(() => {
    if (!parentRef.current) return;

    let foundFirst = false;
    let foundLast = false;
    let startIdx = -1;
    let endIdx = -1;
    const children = parentRef.current.children;

    for (let i = 0; i < children.length; i++) {
      const element = children[i] as HTMLElement;
      const isVisible = isVisibleHorizontal(element, parentRef.current, 100);

      if (isVisible) {
        if (!foundFirst) {
          foundFirst = true;
          startTileRef.current.e = element;
          startTileRef.current.index = i;
        }

        if (foundFirst && !foundLast && i === children.length - 1) {
          endTileRef.current.e = element;
          endTileRef.current.index = i;
          foundLast = true;
          setScrollPosition((s) => ({
            ...s,
            scrollWidth: element.scrollWidth,
            endIdx: i,
          }));
        }
      } else {
        if (foundFirst && !foundLast && i > 0) {
          endTileRef.current.e = children[i - 1] as HTMLElement;
          endTileRef.current.index = i - 1;
          foundLast = true;
        }
      }

      if (!isVisible) {
        if (endIdx === -1 && startIdx !== -1) {
          setScrollPosition((s) => ({
            ...s,
            scrollWidth: element.scrollWidth,
            endIdx: i - 1,
          }));
          endIdx = i - 1;
        }
      } else {
        if (startIdx === -1) {
          setScrollPosition((s) => ({
            ...s,
            scrollWidth: element.scrollWidth,
            startIdx: i,
            endIdx: i === children.length - 1 ? i : s.endIdx,
          }));
          startIdx = i;
        }
      }
    }
  }, [parentRef]);

  useEffect(() => {
    window.addEventListener("resize", updateScrollPosition);
    updateScrollPosition();

    return () => {
      window.removeEventListener("resize", updateScrollPosition);
    };
  }, [updateScrollPosition, deps]);

  useEffect(() => {
    if (!parentRef.current) return;

    const itemsPerPage = Math.ceil(
      parentRef.current.clientWidth / scrollPosition.scrollWidth,
    );
    setCurrentPage(
      itemsPerPage <= 0
        ? 0
        : Math.ceil((scrollPosition.startIdx - 1) / itemsPerPage),
    );
  }, [parentRef, scrollPosition]);

  const canMoveForward = useMemo(() => {
    if (!parentRef.current) return false;
    return scrollPosition.endIdx < parentRef.current.children.length - 1;
  }, [parentRef, scrollPosition]);

  const canMoveBackward = useMemo(() => {
    if (!parentRef.current) return false;
    return scrollPosition.startIdx > 0;
  }, [parentRef, scrollPosition]);

  const onNext = () => {
    if (!canMoveForward) return;
    onMove(currentPage + 1, true);
  };

  const onPrev = () => {
    if (!canMoveBackward) return;
    onMove(currentPage - 1, false);
  };

  const onMove = (startIdx: number, forward = true) => {
    if (!parentRef.current) return;

    if (!forward) {
      parentRef.current.scroll({
        left:
          startIdx === 0 || startIdx === -1
            ? 0
            : parentRef.current.scrollLeft -
              scrollPosition.scrollWidth * (startIdx * perPage),
        behavior: "smooth",
      });
      return;
    }

    parentRef.current.scroll({
      left:
        startIdx === 0
          ? 0
          : parentRef.current.scrollLeft +
            scrollPosition.scrollWidth * (startIdx * perPage),
      behavior: "smooth",
    });
  };

  const onMouseDown = (e: React.MouseEvent) => {
    if (!isDraggable) return;
    setDragPos((s) => ({
      ...s,
      isDown: true,
      startX: e.pageX - parentRef.current!.offsetLeft,
      scrollLeft: parentRef.current!.scrollLeft,
    }));
  };

  const onMouseLeave = () => {
    if (!isDraggable) return;
    setDragPos((s) => ({ ...s, isDown: false }));
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (!dragPos.isDown || !isDraggable) return;

    e.preventDefault();

    const x = e.pageX - parentRef.current!.offsetLeft;
    const walk = (x - dragPos.startX) * 2; // scroll-fast
    parentRef.current!.scrollLeft = dragPos.scrollLeft - walk;
  };

  const updatePage = (page: number) => {
    if (currentPage === page) return currentPage;
    if (page > currentPage) {
      onMove(page, true);
    } else {
      onMove(page, false);
    }
  };

  const showPagesNav =
    startTileRef.current.index === 0 &&
    endTileRef.current.index === parentRef.current?.children.length - 1;

  return (
    <>
      <div
        className="tiles-slider-container"
        style={{
          overflow: "hidden",
          width: "100%",
          height: sliderHeight,
        }}
      >
        <Row
          className="tiles-slider-content"
          ref={parentRef}
          onScroll={updateScrollPosition}
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onMouseUp={onMouseLeave}
          onMouseMove={onMouseMove}
          style={{
            width: "100%",
            height: sliderHeight + 50,
            overflowX: "auto",
            overflowY: "hidden",
            flexWrap: "nowrap",
            transform: "translate3d(0, 0, -82vw)",
            cursor: isDraggable ? "grab" : "auto",
            marginBottom: "-50px",
            paddingBottom: "50px",
          }}
        >
          {children}
        </Row>
      </div>
      <Row
        className="slide-indicator"
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          ...(showPagesNav ? { display: "none" } : {}),
        }}
      >
        <Col xs style={{ textAlign: "center", verticalAlign: "middle" }}>
          <ChevronIcon
            direction="left"
            size="large"
            onClick={onPrev}
            color={
              canMoveBackward ? colors.primary1.main : colors.neutrals["50"]
            }
            style={{
              paddingRight: 15,
              verticalAlign: "text-bottom",
              cursor: canMoveBackward ? "pointer" : "not-allowed",
            }}
          />
          {Array.from({ length: pages }).map((_, i) => (
            <BulletIcon
              key={`dot-${i}`}
              onClick={() => updatePage(i)}
              color={
                currentPage === i ? colors.primary1.main : colors.neutrals["50"]
              }
              style={{
                paddingLeft: i === 0 ? 0 : 10,
                cursor: "pointer",
              }}
            />
          ))}
          <ChevronIcon
            size="large"
            onClick={onNext}
            color={
              canMoveForward ? colors.primary1.main : colors.neutrals["50"]
            }
            style={{
              paddingLeft: 15,
              verticalAlign: "text-bottom",
              cursor: canMoveForward ? "pointer" : "not-allowed",
            }}
          />
        </Col>
      </Row>
    </>
  );
}

const TilesSliderMemo = React.memo(TilesSlider);
export default TilesSliderMemo;
