export default function getUsers(s, teamId, userId) {
  let users = [];
  if (teamId && (userId === 'GROUP' || !userId)) {
    const team = s.entities.teams[teamId];
    users = team && team.user_ids ? team.user_ids : [];
  } else if (userId && userId !== 'GROUP') {
    users = [userId];
  } else if (!userId && !teamId) {
    users = 'ALL';
  }
  return users;
}
