import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { colors, Link, Loading, Row, Col, SimpleWindowedTable, SimpleWindowedTableStyles, LabeledProgress, Number } from '@commonsku/styles';
import Tooltip from 'react-tooltip';
import { formatDashboardMoney, dateStr, getImageSrc } from '../../utils';
import { ACTIVITY_TYPE_MAP } from '../company-search/utils';
import Avatar, { AvatarsWrapper } from '../helpers/Avatar';
import { rebuildTooltip } from '../helpers';
import useWindowSize from '../../hooks/useWindowSize';

const IdField = 'client_id';
const ClientsTableWindowed = ({
  data=[],
  loading,
  handleUpdateRow,
  showLastActivity=true,
  defaultSort={id: 'date_created', desc: true },
}) => {
  const windowSize = useWindowSize();

  const [selectedRow, setSelectedRow] = useState(false);
  const [skipPageReset, setSkipPageReset] = useState(false);

  useEffect(() => {
    rebuildTooltip();
    setSkipPageReset(false);
  }, [data]);

  const onClickProject = React.useCallback((v) => setSelectedRow(v), []);
  const columns = React.useMemo(() => ([
    {
      Header: () => <div>Client</div>,
      accessor: v => `${v.client_name}`,
      Cell: ClientName,
      sticky: 'left',
      noDrag: true,
      minWidth: 250,
      maxWidth: 500,
      id: IdField,
    },
    {
      Header: () => <div>Rep</div>,
      accessor: p => `${p.sales_rep_full_name}`,
      sticky: 'left',
      Cell: ClientRepPic,
      noDrag: true,
      minWidth: 130,
      maxWidth: 500,
      id: 'sales_rep_id',
    },
    {
      Header: () => <div>Sales Target</div>,
      accessor: v => `${v.sales_target}`,
      Cell: ClientSalesTarget,
      sticky: 'left',
      noDrag: true,
      minWidth: 230,
      maxWidth: 500,
      id: 'sales_target',
    },
    {
      Header: () => <div>Contact</div>,
      accessor: v => `${v.contact_full_name}`,
      Cell: ClientContact,
      sticky: 'left',
      noDrag: true,
      minWidth: 280,
      maxWidth: 500,
      id: 'primary_contact_id',
    },
    ...(!showLastActivity ? [] : [{
      Header: () => <div>Last Activity</div>,
      accessor: v => `${_.get(v, ['last_activity', 'activity_details'], '')}`,
      Cell: ClientActivity,
      sticky: 'left',
      noDrag: true,
      minWidth: 160,
      maxWidth: 500,
      id: 'last_activity',
    }]),
  ]), [showLastActivity]);

  const onClickRow = useCallback(
    (v) => selectedRow && selectedRow[IdField] === v[IdField]
        ? setSelectedRow(false) : setSelectedRow(v),
    [selectedRow]
  );

  return (
    <>
      <SimpleWindowedTableStyles rowClickable style={loading ? {display: 'none'} : {}}>
        <SimpleWindowedTable
          columns={columns}
          data={data}
          defaultSort={defaultSort}
          onClickRow={onClickRow}
          onScroll={rebuildTooltip}
          onUpdateData={handleUpdateRow}
          useTableProps={{
            autoResetPage: !skipPageReset,
            autoResetGroupBy: !skipPageReset,
            autoResetSortBy: !skipPageReset,
            autoResetFilters: !skipPageReset,
            autoResetRowState: !skipPageReset,
            onClickProject: onClickProject
          }}
          height={getTableHeight(windowSize[1])}
        />
        <Tooltip id={"sales-rep-avatar"} />
      </SimpleWindowedTableStyles>
      {loading ? <Row>
        <Col xs style={{ alignSelf: "100%", textAlign: 'center' }}>
          <Loading />
        </Col>
      </Row> : null}
    </>
  );
};

function getTableHeight(winHeight) {
  if (winHeight < 251) {
    return 100;
  } else if (winHeight < 351) {
    return 150;
  } else if (winHeight < 540) {
    return winHeight - 280;
  } else if (winHeight < 700) {
    return winHeight - 350 > 450 ? 450 : winHeight - 350;
  } else if (winHeight < 850) {
    return winHeight - 300 > 450 ? 450 : winHeight - 300;
  }

  return 500;
}

function ClientRepPic ({
  row,
  style={},
}) {
  return (
    <div style={{...style, overflowWrap: 'break-word',}}>
      <AvatarsWrapper>
        <Avatar
          backgroundColor={colors.primary}
          name={row.original.sales_rep_full_name || "Sales Rep"}
          data-tip={row.original.sales_rep_full_name || "Sales Rep"}
          data-for={"sales-rep-avatar"}
          {...(row.original.sales_rep_image && _.isObject(row.original.sales_rep_image) ? {
            src: getImageSrc(row.original.sales_rep_image)
          } : {})}
        />
      </AvatarsWrapper>
    </div>
  );
}

function ClientName ({
  row,
  style={},
}) {
  return (
    <div style={{...style, overflowWrap: 'break-word',}}>
      <Link as="a"
        href={`/client.php?id=${row.original.client_id}`}
        target={"_blank"}
        style={{display: 'block', color: colors.primary, fontWeight: 'bold', textDecoration: 'none'}}
      >{row.original.client_name}</Link>
    </div>
  );
}

function ClientSalesTarget({
  row,
  value,
  style={},
}) {
  const st = row.original.sales_target;
  const hasSalesTarget = (st && !isNaN(st) && parseInt(st) !== 0);
  const sales_target = hasSalesTarget ? parseFloat(st).toFixed(0) : 0;

  return (
    <div style={{...style, overflowWrap: 'break-word',}}>
      <span
        style={{
          display: 'block',
          fontWeight: 'bold',
          color: colors.green.main,
        }}
      >{formatDashboardMoney(sales_target)}</span>
    </div>
  );
}

function ClientContact({
  row,
  style={},
}) {
  return (
    <div style={{...style, overflowWrap: 'break-word',}}>
      <span style={{display: 'block', fontWeight: 'bold'}}>{row.original.contact_full_name || ''}</span>
      <span>{row.original.contact_email || ''}</span>
    </div>
  );
}

function ClientActivity ({
  row,
  style={},
}) {
  const last_activity = React.useMemo(() => {
    const la = row.original.last_activity;
    if (!la) { return null; }

    const d = la.activity_date && la.activity_date.indexOf('0000-00-00') === -1
          ? new Date(la.activity_ts * 1000)
          : '';
    const activity_type = ACTIVITY_TYPE_MAP[la.activity_type];
    const result = {
      'date': dateStr(d) || '',
      'activity_type': activity_type,
      'val': '',
      'link': false,
    };
    if (!activity_type) {
      result['val'] = '';
      result['link'] = '';
    } else if (activity_type.href === 'none') {
      result['val'] = activity_type.val || '';
      result['link'] = false;
    } else if (activity_type.val) {
      result['val'] = activity_type.val + (la.activity_details ? ' #' + la.activity_details : '');
      result['link'] = la.activity_type.replace(' ', '_').toLowerCase() + '.php?id=' + la.activity_id;
    }

    return result;
  }, [row.original.last_activity]);

  if (!last_activity) {
    return null;
  }

  return (
    <div style={{...style, overflowWrap: 'break-word',}}>
      <span style={{display: 'block', fontWeight: 'bold'}}>{last_activity.date || ''}</span>
      {!last_activity.link ?
        <span>{last_activity.val}</span>
      : <Link as="a"
        href={last_activity.link}
        target={"_blank"}
        rel="noopener noreferrer"
        style={{display: 'block', color: colors.primary, fontWeight: 'bold', textDecoration: 'none'}}
      >{last_activity.val}</Link>}
    </div>
  );
}

export default ClientsTableWindowed;
