Date.prototype.toStr = function() {
  const y = this.getFullYear(),
        m = (this.getMonth()+1+'').padStart(2, '0'),
        d = (this.getDate()+'').padStart(2, '0');
  return `${y}-${m}-${d}`;
};

export const today = new Date();
export const currentYear = today.getFullYear();
export const currentMonth = today.getMonth();
export const currentDate = today.getDate();
export const createDate = ({y=currentYear, m=currentMonth, d=currentDate, toStr=false}) =>
  toStr ? (new Date(y, m, d)).toStr() : new Date(y, m, d);

export const months_map = {
  "Jan": "January",
  "Feb": "February",
  "Mar": "March",
  "Apr": "April",
  "May": "May",
  "Jun": "June",
  "Jul": "July",
  "Aug": "August",
  "Sep": "September",
  "Oct": "October",
  "Nov": "November",
  "Dec": "December",
};

export const months = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
];
export const fullMonths = [
  "January", "February", "March", "April", "May", "June", "July",
  "August", "September", "October", "November", "December",
];
export const monthsUpToNow = months.filter((m, i) => i <= currentMonth);
export const monthsOrdered = months.filter((m, i) => i > currentMonth)
  .concat(monthsUpToNow);
  export const monthsOrderedLeft = months.filter((m, i) => i > currentMonth);

export const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];

export const getQuarterOfMonthByIdx = (mIdx) => parseInt(mIdx / 3) + 1;
export const getQuarterOfMonthByDate = (d = new Date()) =>
  getQuarterOfMonthByIdx(d.getMonth());
export const getQuarterOfMonthByShortName = (m) =>
  getQuarterOfMonthByIdx(months.indexOf(m));
export const currentQuarter = getQuarterOfMonthByIdx(currentMonth);
export const quartersUpToNow = quarters.filter((q, i) => i <= currentQuarter-1);
export const quartersOrdered = quarters.filter((q, i) => i > currentQuarter).concat(quartersUpToNow);
export const quartersOrderedLeft = quarters.filter((q, i) => i > currentQuarter-1);
export const getStartEndOfQuarter = (q=1) => {
  const calcQ = (q%4 === 0 ? 4 : q%4)*3;
  return {
    start: `2020-${calcQ - 2}-01`,
    end: (new Date(2020, calcQ, 0)).toStr()
  };
};

export const prevQuarter = currentQuarter == 1 ? 4 : currentQuarter-1;
export const nextQuarter = currentQuarter == 4 ? 1 : currentQuarter+1;
export const prevQYear = currentQuarter == 1 ? currentYear-1 : currentYear;
export const prevMonth = currentMonth == 0 ? 11 : currentMonth-1;
export const nextMonth = currentMonth == 11 ? 0 : currentMonth+1;
export const prevMYear = currentMonth == 0 ? currentYear-1 : currentYear;
