import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { oauth, parseDate } from '../utils';

const initialState = {
    data: {},
    current_ids: [],
    errors: {},
    loading: {
        notes: false,
    },
};

const slice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setNotes(state, action) {
        state.data = action.payload;
    },
    fetchNotesSuccess(state, action) {
        state.data = {
            ...state.data,
            ...action.payload.reduce((acc, v) => ({
                ...acc,
                [v.note_id]: v,
            }), {}),
        };
        state.current_ids = action.payload.map(v => v.note_id);
    },
    updateNoteSuccess(state, action) {
        state.data = {
            ...state.data,
            [action.payload.note_id]: {
                ...(state.data[action.payload.note_id] || {}),
                ...action.payload,
            },
        };
    },
    updateNoteRepliesSuccess(state, action) {
        const found = Object.values(state.data)
            .find(v => v.parent_type === 'MESSAGE' && v.message_id === action.payload.parent_id);
        if (_.isEmpty(found)) { return; }

        const replies = state.data[found.note_id].replies || [];
        const replyFoundIdx = replies.findIndex(v => v.message_id === found.message_id);

        state.data = {
            ...state.data,
            [found.note_id]: {
                ...state.data[found.note_id],
                replies: replyFoundIdx === -1 ? [...replies, action.payload] : [
                    ...replies.slice(0, replyFoundIdx),
                    {...replies[replyFoundIdx], ...action.payload},
                    ...replies.slice(replyFoundIdx+1),
                ],
            },
        };
    },
    deleteNoteSuccess(state, action) {
        delete state.data[action.payload.note_id];
    },
    notesFailure(state, action) {
        state.errors = action.payload;
    },
    setLoading(state, action) {
        state.loading = action.payload;
    },
    updateLoading(state, action) {
        state.loading = {
            ...state.loading,
            ...action.payload,
        };
    },
  },
});

const fetchNotes = (params = {}) => async (dispatch, getState) => {
    dispatch(updateLoading({ notes: true }));
    try {
        const { json } = await oauth('INDEX', 'note', params);

        const data = _.get(json, 'notes', []);
        dispatch(fetchNotesSuccess(data));
    } catch (error) {
        console.error('ERROR|fetchNotes| ', error);
        dispatch(notesFailure('Cannot get notes.'));
    }
    dispatch(updateLoading({ notes: false }));
};

const createNoteMessage = (params = {}) => async (dispatch, getState) => {
    try {
        const { json } = await oauth('POST', 'message', params);

        const data = _.get(json, ['message', 'note'], {});
        dispatch(updateNoteSuccess(data));
    } catch (error) {
        console.error('ERROR|createNote| ', error);
        dispatch(notesFailure('Cannot create note.'));
    }
};

const fetchNote = (note_id, params = {}) => async (dispatch, getState) => {
    dispatch(updateLoading({ notes: true }));
    try {
        const { json } = await oauth('GET', `note/${note_id}`, params);

        const data = _.get(json, 'note', {});
        dispatch(updateNoteSuccess(data));
    } catch (error) {
        console.error('ERROR|fetchNote| ', error);
        dispatch(notesFailure('Cannot get note.'));
    }
    dispatch(updateLoading({ notes: false }));
};

const updateNote = (note_id, params = {}) => async (dispatch, getState) => {
    try {
        const { json } = await oauth('PUT', `note/${note_id}`, params);

        const data = _.get(json, 'note', {});
        dispatch(updateNoteSuccess(data));
    } catch (error) {
        console.error('ERROR|updateNote| ', error);
        dispatch(notesFailure('Cannot update note.'));
    }
};

const deleteNote = (note_id, params = {}) => async (dispatch, getState) => {
    try {
        await oauth('DELETE', `note/${note_id}`, params);
        dispatch(deleteNoteSuccess({ note_id }));
    } catch (error) {
        console.error('ERROR|deleteNote| ', error);
        dispatch(notesFailure('Cannot delete note.'));
    }
};

export const isNoteComplete = (note) => parseInt(_.get(note, 'reminder_complete', 0)) === 1;
export const getNoteName = (note) => (
    _.get(note, 'parent_name', '') ||
    _.get(note, 'reminder_user_full_name', '')
);
const today = new Date(new Date().toDateString());
export const isNoteOverdue = (note) => !note.date_reminder || (
    new Date(parseDate(note.date_reminder)) < today
        && !isNoteComplete(note)
);

export const getNoteById = note_id => state => state.notes.data[note_id];
export const getNotes = state => state.notes.data;
export const getNotesByReminderUser = (notes, userId) => Object.values(notes)
    .filter(v => v.reminder_user === userId);
export const getNotesByReminderUsers = (notes, userIds) => Object.values(notes)
    .filter(v => userIds === 'ALL' || userIds.includes(v.reminder_user));
export const getNotesLoading = state => state.notes.loading;
export const getNotesErrors = state => state.notes.errors;
const parseCalendarNotes = (notes) => notes.map(v => {
    const msgSplit = (v.message_text || '')
        .replace(/[<]br[^>]*[>]/gi,"")
        .split('\n');
    const msgStr = msgSplit.slice(0, 4).join('\n');

    return {
        id: v.note_id,
        key: 'note-' + v.note_id,
        title: getNoteName(v),
        description: msgSplit.length > 4 ? msgStr + '...' : msgStr,
        date: v.date_reminder ? new Date(parseDate(v.date_reminder)) : v.date_reminder,
        colorType: isNoteOverdue(v) ? 'light-red' : 'light-green',
        completed: isNoteComplete(v),
        checked: isNoteComplete(v),
        isDescriptionHtml: true,
        ...v,
    };
});

export const getUserId = (state) => state.identity.user_id;
const getNotesByRemUser = (notes, userId) => getNotesByReminderUser(notes, userId);
const getNotesByRemUsers = (notes, userIds) => getNotesByReminderUsers(notes, userIds);
const getCalNotes = (notes) => parseCalendarNotes(notes);
const getOverdueNotes = (notes) => notes.filter(v => isNoteOverdue(v));

const selectNotes = createSelector(getNotes, s => s);
const selectNotesValues = createSelector(getNotes, s => Object.values(s));
const selectNotesByReminderUsers = createSelector([getNotes, (s, userIds) => userIds], getNotesByRemUsers);
const selectOverdueNotesByReminderUsers = createSelector(
    selectNotesByReminderUsers, getOverdueNotes
);
const selectNotesByReminderUser = createSelector([getNotes, (s, userId) => userId], getNotesByRemUser);
const selectOverdueNotesByReminderUser = createSelector(
    selectNotesByReminderUser, getOverdueNotes
);
const selectNotesByIdentityReminderUser = createSelector([getNotes, getUserId], getNotesByRemUser);
const selectOverdueNotesByIdentityReminderUser = createSelector(
    selectNotesByIdentityReminderUser, getOverdueNotes
);

export const selectors = {
    notes: selectNotes,
    loading: createSelector(getNotesLoading, s => s),
    errors: createSelector(getNotesErrors, s => s),
    notesParentTypes: createSelector(selectNotesValues,
        s => Array.from(new Set(s.filter(v => v.parent_type).map(v => v.parent_type)))
    ),
    calendarNotes: createSelector(selectNotesValues, s => parseCalendarNotes(s)),
    overdueCalendarNotes: createSelector(selectNotesValues,
        s => parseCalendarNotes(getOverdueNotes(s))
    ),
    noteById: createSelector([getNotes, (s, noteId) => noteId], (s, noteId) => s[noteId]),
    notesByReminderUser: createSelector(getNotes, getNotesByReminderUser),
    calendarNotesByReminderUsers: createSelector(
        selectNotesByReminderUsers,
        getCalNotes,
    ),
    overdueCalendarNotesByReminderUsers: createSelector(
        selectOverdueNotesByReminderUsers,
        getCalNotes,
    ),
    calendarNotesByReminderUser: createSelector(
        selectNotesByReminderUser,
        getCalNotes,
    ),
    overdueCalendarNotesByReminderUser: createSelector(
        selectOverdueNotesByReminderUser,
        getCalNotes,
    ),
    notesByIdentityReminderUser: selectNotesByIdentityReminderUser,
    calendarNotesByIdentityReminderUser: createSelector(
        selectNotesByIdentityReminderUser,
        getCalNotes,
    ),
    overdueCalendarNotesByIdentityReminderUser: createSelector(
        selectOverdueNotesByIdentityReminderUser,
        getCalNotes,
    ),
    byParent: createSelector(
        [selectNotesValues, (s, parentId) => parentId, (s, parentId, parentType) => parentType],
        (s, parentId, parentType) => s.filter(
            v => v.parent_id === parentId && v.parent_type === parentType
        )
    ),
    remindersByParent: createSelector(
        [selectNotesValues, (s, parentId) => parentId, (s, parentId, parentType) => parentType],
        (s, parentId, parentType) => s.filter(
            v => !_.isEmpty(v.date_reminder) && v.parent_id === parentId && v.parent_type === parentType
        )
    ),
};

export const {
    setNotes,
    fetchNotesSuccess,
    updateNoteSuccess,
    updateNoteRepliesSuccess,
    deleteNoteSuccess,
    notesFailure,
    setLoading,
    updateLoading,
} = slice.actions;
export {
    fetchNotes,
    fetchNote,
    createNoteMessage,
    updateNote,
    deleteNote,
};
export default slice.reducer;
