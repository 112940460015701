import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from 'react-tooltip';
import { DraggableTasksCalendar, } from '@commonsku/styles';
import { TASK_PARENT_TYPES } from './utils';
import { fetchNotes, selectors, updateNote } from '../../redux/notes';
import { dateStr, getFirstDayOfWeek, getLastDayOfWeek, subtractDaysDate, oauth } from '../../utils';
import { useIdentity } from '../../hooks';
import Avatar from '../helpers/Avatar';
import { rebuildTooltip } from '../helpers';
import getUsers from '../calendar/getUsers';

const today = new Date();
export const SHOW_WEEKEND_FLAG = 'SHOW_CALENDAR_WEEKEND';

function NotesCalendar({ noteId, onClickNote=null, noteUserId=null, noteUserTeamId=null, onAddTask=null, onChangeWeek=null, ...props}) {
  const dispatch = useDispatch();
  const identity = useIdentity();
  const loading = useSelector(s => selectors.loading(s).notes);
  const calNotes = useSelector(s => {
    const users = getUsers(s, noteUserTeamId, noteUserId);
    const result = selectors.calendarNotesByReminderUsers(s, users);
    if (!noteUserId || noteUserTeamId) {
      return result.map(n => ({
        ...n,
        title: <span>
          <Avatar
            name={_.get(n, 'reminder_user_full_name', n.title)}
            style={{display: 'inline-flex', fontSize: '0.85rem', height: 28, width: 28}}
            data-tip={_.get(n, 'reminder_user_full_name', n.title) || ""}
            data-for={"note-rep-avatar"}
          />
          <span>{n.title}</span>
        </span>,
      }));
    }
    return result;
  });
  const overdueNotes = useSelector(s => {
    const users = getUsers(s, noteUserTeamId, noteUserId);
    const result = selectors.overdueCalendarNotesByReminderUsers(s, users);
    if (!noteUserId || noteUserTeamId) {
      return result.map(n => ({
        ...n,
        title: (!noteUserId || noteUserTeamId) ? <span>
          <Avatar
            name={_.get(n, 'reminder_user_full_name', n.title)}
            style={{display: 'inline-flex', fontSize: '0.85rem', height: 28, width: 28}}
            data-tip={_.get(n, 'reminder_user_full_name', n.title) || ""}
            data-for={"note-rep-avatar"}
          />
          <span>{n.title}</span>
        </span> : n.title,
      }));
    }
    return result;
  });
  const [calendarTab, setCalendarTab] = useState('ALL');
  const [userFlags, setUserFlags] = useState(identity.user_flags || []);

  const calendarTabs = [
    { content: '', label: 'All Tasks', key: 'ALL', onClick: () => { setCalendarTab('ALL'); } },
    { content: '', label: 'Client Tasks', key: 'CLIENT', onClick: () => { setCalendarTab('CLIENT'); } },
    { content: '', label: 'Project Tasks', key: 'JOB', onClick: () => { setCalendarTab('JOB'); } },
    { content: '', label: 'PO Tasks', key: 'PURCHASE ORDER', onClick: () => { setCalendarTab('PURCHASE ORDER'); } },
  ];

  const tasks = React.useMemo(() =>
    calendarTab === TASK_PARENT_TYPES.ALL.value
      ? calNotes
      : _.filter(calNotes, v => v.parent_type === calendarTab),
    [calNotes, calendarTab]);

  const footerTasks = React.useMemo(() =>
    calendarTab === TASK_PARENT_TYPES.ALL.value
      ? overdueNotes
      : _.filter(overdueNotes, v => v.parent_type === calendarTab),
    [overdueNotes, calendarTab]);

  const onUpdateNote = (note) => {
    dispatch(updateNote(note.note_id, note));
  };

  const onUpdateCalendarNote = (note, otherData) => {
    if (otherData.action === 'DROP') {
      onUpdateNote({
        note_id: note.note_id,
        date_reminder: _.isDate(note.date) ? dateStr(note.date) : note.date
      });
    } else if (otherData.action === 'TOGGLE_CHECKBOX') {
      onUpdateNote({
        note_id: note.note_id,
        reminder_complete: note.completed ? 1 : 0,
      });
    }
    rebuildTooltip();
  };

  const getNotes = React.useCallback((params={}) => {
    if (noteUserId === 'GROUP' && !noteUserTeamId) { return; }

    dispatch(fetchNotes({
      start_stamp_reminder: dateStr(getFirstDayOfWeek(today)),
      end_stamp_reminder: dateStr(getLastDayOfWeek(today)),
      include_complete: true,
      note_type: 'ALL REMINDERS',
      reminder_user_id: noteUserId,
      reminder_user_team_id: noteUserTeamId,
      tenant_id: identity.company_id,
      max_results: 1000,
      with_po: true,
      with_parent_data: true,
      ...params,
    }));
  }, [dispatch, noteUserId, noteUserTeamId, identity]);

  useEffect(() => {
    setUserFlags(identity.user_flags || []);

    // on first load get overdue tasks for last 30 days
    dispatch(fetchNotes({
      start_stamp_reminder: dateStr(subtractDaysDate(30, today)),
      end_stamp_reminder: dateStr(subtractDaysDate(1, getFirstDayOfWeek(today))),
      include_complete: false,
      note_type: 'ALL REMINDERS',
      reminder_user_id: noteUserId,
      reminder_user_team_id: noteUserTeamId,
      tenant_id: identity.company_id,
      max_results: 1000,
      with_po: true,
      with_parent_data: true,
    }));
  }, [identity]);

  useEffect(() => {
    getNotes();
    rebuildTooltip();
  }, [getNotes]);

  useEffect(() => {
    onClickNote && onClickNote(false);
  }, [calendarTab, onClickNote]);

  useEffect(() => {
    rebuildTooltip();
  }, [loading]);

  const hanldeClickNote = React.useCallback((note) => {
    if (typeof noteId !== 'undefined' && noteId === note.id) {
      onClickNote && onClickNote(false);
      return;
    }
    onClickNote && onClickNote(note.id);
  }, [noteId, onClickNote]);

  const handleChangeWeek = React.useCallback(({ date }) => {
    onChangeWeek && onChangeWeek({ date });

    const params = {
      start_stamp_reminder: dateStr(getFirstDayOfWeek(date)),
      end_stamp_reminder: dateStr(getLastDayOfWeek(date)),
    };
    getNotes(params);
    rebuildTooltip();
  }, [getNotes, onChangeWeek]);

  const onShowWeekend = () => {
    oauth('POST', 'user-flag/', {user_id: identity.user_id, flag_name: SHOW_WEEKEND_FLAG}).then(
      ({json}) => {
        setUserFlags(s =>
          Array.from(new Set(...s.concat([SHOW_WEEKEND_FLAG])))
        );
      }
    );
  };

  const onHideWeekend = () => {
    oauth('DELETE', 'user-flag/', {user_id: identity.user_id, flag_name: SHOW_WEEKEND_FLAG}).then(
      ({json}) => {
        setUserFlags(s => {
          const idx = s.findIndex(v => v === SHOW_WEEKEND_FLAG);
          if (idx === -1) { return s; }

          return [
            ...s.slice(0, idx),
            ...s.slice(idx+1),
          ];
        });
      }
    );
  };

  const showWeekendFlag = userFlags.includes(SHOW_WEEKEND_FLAG);
  const onToggleWeekend = (show) => {
    if (show) {
      onShowWeekend();
    } else {
      onHideWeekend();
    }
    rebuildTooltip();
  };

  return (
    <>
      <DraggableTasksCalendar
        tasks={tasks}
        onUpdateTask={onUpdateCalendarNote}
        footerTasks={footerTasks}
        headerTabs={calendarTabs}
        onClickTask={hanldeClickNote}
        onChangeWeek={handleChangeWeek}
        onClickAddTask={onAddTask}
        loading={loading}
        weekend={showWeekendFlag}
        onToggleWeekend={onToggleWeekend}
      />
      <Tooltip id={"note-rep-avatar"} />
    </>
  );
}

export default NotesCalendar;
