import _ from 'lodash';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReceiptLongIcon, Text, themeOptions } from '@commonsku/styles';
import { fetchPOForProduction, fetchPurchaseOrder, updatePurchaseOrder } from "../../actions/purchase_order";
import { getPurchaseOrderList, getPurchaseOrders } from "../../selectors";
import { dateStr, getFirstDayOfWeek, getLastDayOfWeek, parseDate } from "../../utils";
import Avatar from '../helpers/Avatar';
import getUsers from './getUsers';

export function getDateFilterKey(selectedTab) {
  switch (selectedTab) {
    case 'NA_DATE':
      return 'next_action_date';
    case 'IH_DATE':
      return 'in_hands_date';
    case 'PS_DATE':
      return 'ship_date';
    default:
      return 'calendar_view';
  }
}

export function getPODate(selectedTab, v) {
  switch (selectedTab) {
    case 'NA_DATE':
      return v.date_nextaction;
    case 'IH_DATE':
      return v.po_or_so_in_hands_date;
    case 'PS_DATE':
      return v.date_shipdatecurrent;
    default:
      return null;
  }
}

export default function usePOs({ identity, teamId = null, userId = null, repType=null, selectedTab='NA_DATE', }) {
  const dispatch = useDispatch();
  const loading = useSelector(s =>
    _.get(s, ['display', 'loading', 'fetch_pos_for_production'], false)
  );
  const next_action_types = useSelector(
    state => state.entities.next_action_types
  );

  const pos = useSelector(getPurchaseOrders);
  const tasks = useSelector(s => {
    const users = getUsers(s, teamId, userId);
    const POs = getPurchaseOrderList(s);
    return POs
      .filter(v => {
        const dt = getPODate(selectedTab, v);
        return (users === 'ALL' || users.includes(v.production_rep_id))
          && dt && dt !== '0000-00-00 00:00:00' && dt !== '0';
      })
      .map(v => {
        const title = `PO #${v.form_number}`;
        const dt = getPODate(selectedTab, v);
        return {
          hideCheckbox: true,
          id: v.purchase_order_id,
          purchase_order_id: v.purchase_order_id,
          date_nextaction: v.date_nextaction,
          draggable: ['NA_DATE', 'PS_DATE'].includes(selectedTab),
          description: <Text style={{color: '#000', fontFamily: themeOptions.fontFamilies.regular}}>
            <span style={{ display: 'block', paddingTop: 10, paddingBottom: 5 }}>{v.supplier_name}</span>
            <span style={{ display: 'block', paddingBottom: 5 }}>Project #{v.job_number}</span>
            <span style={{ display: 'block', paddingBottom: 5 }}>{_.get(next_action_types, [v.next_action_type_id, 'label'], '')}</span>
          </Text>,
          title: (!userId || teamId) ? <span style={{color: '#000'}}>
            <Avatar
              name={_.get(v, 'purchase_order_rep_full_name', title)}
              style={{ display: 'inline-flex', fontSize: '0.85rem', height: 28, width: 28 }}
              data-tip={_.get(v, 'purchase_order_rep_full_name', title) || ""}
              data-for={"po-rep-avatar"}
            />
            <Text style={{fontWeight: 'bold'}}>#{v.form_number}</Text>
          </span> : <Text style={{fontWeight: 'bold', color: '#000'}}>#{v.form_number}</Text>,
          Icon: <ReceiptLongIcon />,
          date: new Date(parseDate(dt)),
          colorType: 'light-green',
          isDescriptionHtml: true,
        };
      });
  });

  const findPO = React.useCallback((poId) => {
    return _.get(pos, [poId]);
  }, [pos]);

  const fetchPO = React.useCallback((poId) => {
    return dispatch(fetchPurchaseOrder(poId, { production_po: true }));
  }, [dispatch]);

  const getPOs = React.useCallback((params = {}) => {
    if (userId === 'GROUP' && !teamId) { return; }

    dispatch(fetchPOForProduction({
      start_stamp: dateStr(getFirstDayOfWeek(new Date())),
      end_stamp: dateStr(getLastDayOfWeek(new Date())),
      date_filter: '',
      rep_type: repType,
      production_rep_id: userId || '',
      rep_team_id: teamId || '',
      tenant_id: identity.company_id,
      'max-results': 1000,
      calendar_view: true,
      ...params,
    }));
  }, [dispatch, userId, teamId, repType, identity]);

  const onUpdate = (purchase_order_id, field, previous_value, value) => {
    dispatch(updatePurchaseOrder(purchase_order_id, field, previous_value, value));
  };

  return {
    loading,
    tasks,
    overdueTasks: [],
    getPOs,
    onUpdate,
    findPO,
    fetchPO,
  };
}
