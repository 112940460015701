import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { Row, Col, ToggleLink, Toggle, TabBar, Tab, colors } from '@commonsku/styles';
import _ from 'lodash';
import { selectByOptions } from './utils';
import { getDateParams } from './utils/supplier_charts_utils';
import { clearSupplierEventsState, getSupplierStatsParams, fetchSupplierPOStats, fetchSupplierPresales } from './dashboardSlice';
import { delay } from '../../utils';
import { Select, ChartPopup } from '../helpers';
import ProductSidepanel from './ProductSidepanel';
import {
  updateProductState, fetchMultipleSupplierStats,
} from './dashboardSlice';
import { getTenantOptionsDropdown } from './selectors';

const SupplierStatsChart =  loadable(() => import('./SupplierCharts'), {ssr: false}) 
const SupplierReportDownloads =  loadable(() => import('./SupplierReportDownloads'), {ssr: false}) 

const modeOptions = [
  {label: 'Sales', value: 'SALES'},
  {label:  'Pre-Sales', value: 'PRESALES'},
];

const rep_type_options = [
  {label:  'Client Reps' , value: 'order_client_rep'},
  {label: 'Order Reps'  , value: 'order_rep'},
];

const rep_type_presales_options = [
  {label:  'Client Reps', value: 'order_client_rep'},
  {label:  'Order Reps' , value: 'order_rep'},
];


function SupplierDashboard(props) {
  const dispatch = useDispatch();
  const identity = useSelector(s => s.identity);
  const tenant_options = useSelector(getTenantOptionsDropdown);

  const [state, setState] = useState({
    group_by: 'month', // monthly, quarterly, yearly
    compare_previous: 1, // comparison with previous time period
    tenant_id: null,
    division_id: '',
    supplier_tags: '',
    rep_type: 'order_rep',
    rep_type_presales: 'order_rep'
  });
  const [popupData, setPopup] = useState(false);
  const [panelData, setPanel] = useState(false);
  const [mode, setMode] = useState("SALES");

  useEffect(function () {
    const stats_calls = [];
    if (mode === 'SALES') {
     stats_calls.push(
      {type: 'SUPPLIER_POS', totals_by: 'totals', params: {
        ...getDateParams(state.group_by, state.compare_previous, 'totals'),
        group_by: state.group_by,
        compare_previous: state.compare_previous,
        tenant_id: state.tenant_id,
        division_id: state.division_id,
      }},
      //new 2
      {type: 'SUPPLIER_PRODUCTS', totals_by: 'product', params: {
        group_by: state.group_by,
        compare_previous: state.compare_previous,
        tenant_id: state.tenant_id,
        division_id: state.division_id,
        ...getDateParams(state.group_by, state.compare_previous, 'product'),
        with_others: 1,
        until: 25,
      }},
      //SUPPLIER_PRODUCTS
      
     
      {type: 'SUPPLIER_POS', totals_by: 'tenant', params: {
        group_by: state.group_by,
        compare_previous: state.compare_previous,
        tenant_id: state.tenant_id,
        division_id: state.division_id,
        ...getDateParams(state.group_by, state.compare_previous, 'tenant'),
        with_others: 1,
        until: 25,
      }},      
     );
    }
    if (mode === 'PRESALES') 
    {
      stats_calls.push({
        type: 'SUPPLIER_EVENTS', totals_by: 'industry', params: {
          group_by: state.group_by,
          compare_previous: state.compare_previous,
          tenant_id: state.tenant_id,
          division_id: state.division_id,
          ...getDateParams(state.group_by, state.compare_previous, 'industry'),
          with_others: 1,
          until: 25,
        }
      },
      {type: 'SUPPLIER_EVENTS', totals_by: 'totals', params: {
        ...getDateParams(state.group_by, state.compare_previous, 'totals'),
        group_by: state.group_by,
        compare_previous: state.compare_previous,
        tenant_id: state.tenant_id,
        division_id: state.division_id,
      }},
      {type: 'SUPPLIER_EVENTS', totals_by: 'product', params: {
        group_by: state.group_by,
        compare_previous: state.compare_previous,
        tenant_id: state.tenant_id,
        division_id: state.division_id,
        ...getDateParams(state.group_by, state.compare_previous, 'product'),
        with_others: 1,
        until: 25,
      }},
      {type: 'SUPPLIER_EVENTS', totals_by: 'tenant', params: {
        group_by: state.group_by,
        compare_previous: state.compare_previous,
        tenant_id: state.tenant_id,
        division_id: state.division_id,
        ...getDateParams(state.group_by, state.compare_previous, 'tenant'),
        with_others: 1,
        until: 25,
      }},
      {
        type: 'SUPPLIER_EVENTS', totals_by: 'events', params: {
          group_by: state.group_by,
          compare_previous: state.compare_previous,
          tenant_id: state.tenant_id,
          division_id: state.division_id,
          ...getDateParams(state.group_by, state.compare_previous, 'events'),
          with_others: 1,
          until: 25,
        }
      }
      );
      
    }
    if (!state.tenant_id) {
     if( mode === 'SALES') {
      stats_calls.push({type: `SUPPLIER_POS_TENANTS_COUNT_${state.group_by.toUpperCase()}`, totals_by: 'tenants_count', params: {
        group_by: state.group_by,
        compare_previous: state.compare_previous,
        ...getDateParams(state.group_by, state.compare_previous, 'tenants_count'),
        with_others: 1,
        until: 25,
        division_id: state.division_id,
      }});
     }
    if (mode === 'PRESALES') {
      stats_calls.push({type: `SUPPLIER_EVENTS_TENANTS_COUNT_${state.group_by.toUpperCase()}`, totals_by: 'tenants_count', params: {
        group_by: state.group_by,
        compare_previous: state.compare_previous,
        ...getDateParams(state.group_by, state.compare_previous, 'tenants_count'),
        with_others: 1,
        until: 25,
        division_id: state.division_id,
      }});
    }
  }
    dispatch(fetchMultipleSupplierStats(stats_calls));
  }, [state.group_by, state.compare_previous, state.tenant_id, state.division_id,mode]);
  useEffect(() => {
    if (mode === 'PRESALES') {
    const req_data = getSupplierStatsParams('SUPPLIER_PRESALES', state.rep_type_presales, {
      ...getDateParams(state.group_by, state.compare_previous, state.rep_type_presales),
      group_by: state.group_by,
      compare_previous: state.compare_previous,
      tenant_id: state.tenant_id,
      division_id: state.division_id,
      ...(state.tenant_id ? {tenant_id: state.tenant_id} : {
        with_tenants: 1,
        until: 25,
        limit: 25,
      }),
    });
    
    dispatch(fetchSupplierPresales(req_data));
  }
  }, [mode,state.rep_type_presales, state.tenant_id, state.compare_previous, state.group_by, state.division_id]);

  useEffect(() => {
    const req_data = getSupplierStatsParams('SUPPLIER_POS', state.rep_type, {
      ...getDateParams(state.group_by, state.compare_previous, state.rep_type),
      group_by: state.group_by,
      compare_previous: state.compare_previous,
      tenant_id: state.tenant_id,
      division_id: state.division_id,
      ...(state.tenant_id ? {tenant_id: state.tenant_id} : {
        with_tenants: 1,
        until: 25,
        limit: 25,
      }),
    });
    
    dispatch(fetchSupplierPOStats(req_data));
  }, [state.rep_type, state.group_by, state.compare_previous, state.tenant_id, state.division_id]); 
  const divisions = React.useMemo(() => {
    return [{label: 'Select Division', value: ''}].concat(
      identity.divisions.map(v => ({label: v.division_name, value: v.division_id}))
    );
  }, [identity.divisions]);

  const commonProps = {
    showPopup: setPopup,
    group_by: state.group_by,
    division_id: state.division_id,
    tenant_id: state.tenant_id,
  };

  const asyncHandleClosePanel = async () => {
    setPanel(false);
    await delay(); // wait to slide out
    await new Promise(done => {
      dispatch(updateProductState({key: panelData.product_id, val: {}}));
    });
    await new Promise(done => {dispatch(clearSupplierEventsState());});
  };

  return (<>
    {popupData ? <ChartPopup {...popupData} onClosePopup={() => setPopup(false)} /> : null}
    <ProductSidepanel
      onClosePanel={() => asyncHandleClosePanel()}
      product_id={_.get(panelData, 'product_id', false)}
      product_data={_.get(panelData, 'product_data', {})}
      supplierStatsData={_.get(panelData, 'supplierStatsData', {})}
    />
    <Row>
      <TabBar style={{marginBottom: 10, marginLeft: '15px'}}>
        {modeOptions.map((tab,index) =>
          <Tab key={index} style={{borderBottomColor: colors.cta, fontSize: 18}}
            selected ={tab.value === mode }
            onClick={()=>setMode(tab.value)}
          >
            {tab.label}
          </Tab>
        )}
        <Tab
          style={{ borderBottomColor: colors.cta, fontSize: 18 }}
          selected={'DOWNLOAD-REPORTS' === mode}
          onClick={() => setMode('DOWNLOAD-REPORTS')}
        >
          Download Reports
        </Tab>
      </TabBar>
    </Row> 
    {['SALES', 'PRESALES'].includes(mode) ? <Row end>
      <Col padded end xs={6} md={3} style={{ textAlign: 'left' }}>
        <Select isClearable
          placeholder="Search for distributor"
          options={tenant_options}
          value={state.tenant_id}
          onChange={(e) => setState({...state, tenant_id: e ? e.value : null})}
          isTenant
        />
      </Col>
      {/* {mode === "SALES" ?
        <Col padded end xs={6} md={3}>
          <Select isClearable placeholder="Select Tag..."
            options={supplierTagsOptions}
            value={state.supplier_tags}
            onChange={(e) => setState({...state, supplier_tags: e ? e.value : ''})}
          />
        </Col>
      : null} */}
      <Col padded  xs={6} md={3} style={{ textAlign: 'left' }}>
        <Select placeholder="Select Division"
          options={divisions}
          value={state.division_id}
          onChange={(e) => setState({...state, division_id: e ? e.value : ''})}
        />
      </Col>
      <Col padded end offset= {3} xs={6} md={3} style={{ textAlign: 'left' }}>
      <Toggle>
        {selectByOptions.map((option,index) =>
          <ToggleLink 
            key={index}
            id ={option.value} 
            selected ={state.group_by === option.value}
            onClick={(e)=> {
              setState({...state, group_by: option.value })
	    }}
          >
            {option.label}
          </ToggleLink>
        )}
      </Toggle>
      </Col> 
    </Row> : null}

    <Row style={{display: mode === "SALES" ? 'flex' : 'none'}}>
      {/* {!state.tenant_id && state.supplier_tags.length > 0 ?
        <SupplierStatsChart
          type="SUPPLIER_POS"
          chart_type="line"
          totals_by="tags"
          isSparkline={false}
          colSizes={{xs: 12, md: 6}}
          filters={getDateParams(state.group_by, state.compare_previous, 'tags')}
          {...commonProps}
        /> : null} */}
      {!state.tenant_id ?
        <SupplierStatsChart
          type="SUPPLIER_POS"
          chart_type="line"
          totals_by="tenants_count"
          // isSparkline={state.supplier_tags.length > 0 ? false : true}
          colSizes={{xs: 12, md: state.supplier_tags.length > 0 ? 6 : 12}}
          filters={getDateParams(state.group_by, state.compare_previous, 'tenants_count')}
          {...commonProps}
        /> : null}
      <SupplierStatsChart
        type="SUPPLIER_POS"
        chart_type="bar"
        totals_by="totals"
        isSparkline={false}
        colSizes={{xs: 12, md: 12, lg: 12}}
        filters={getDateParams(state.group_by, state.compare_previous, 'totals')}
        {...commonProps}
      />
      <div style={{display: state.tenant_id ? 'none' : 'contents'}}><SupplierStatsChart
        type="SUPPLIER_POS"
        chart_type="pie"
        totals_by="tenant"
        colSizes={{xs: 12, lg: 6}}
        show_previous={true}
        filters={getDateParams(state.group_by, state.compare_previous, 'tenant')}
        {...commonProps}
      /></div>
      <SupplierStatsChart
        type="SUPPLIER_POS"
        chart_type="bar_bullet"
        totals_by={state.rep_type}
        colSizes={{xs: 12, lg: 6}}
        show_previous={true}
        getTooltipText={(gr='gr1') => (
          `{${gr}}: [bold]{valueX.value}[/]\nDistributor: {${gr}_tenant_name}`
        )}
        filters={getDateParams(state.group_by, state.compare_previous, state.rep_type)}
        titleSelect={{
          placeholder: "Select Rep Type...",
          options: rep_type_options,
          value: state.rep_type,
          onChange:(v) => {
            setState({...state, rep_type: v});
          },
        }}
        {...commonProps}
      />
       <SupplierStatsChart
        type="SUPPLIER_PRODUCTS"
        chart_type="bar_bullet"
        totals_by="product"
        colSizes={{xs: 12, lg: 6}}
        show_previous={true}
        filters={getDateParams(state.group_by, state.compare_previous)}
        showSidepanel={setPanel}
        getSidepanelData={(data, gr='gr1') => ({
          isPrev: gr == 'gr2',
          supplierStatsData: {
            totals: data[`${gr}_totals`],
            group: data[gr],
            tenant_id: state.tenant_id,
            division_id: state.division_id,
            ...getDateParams(state.group_by, state.compare_previous),
            isPrev: gr == 'gr2',
            type: "SUPPLIER_PRODUCTS"
          },
          product_id: data[`${gr}_product_id`],
          product_data: {
            product_id: data[`${gr}_product_id`],
            product_name: data[`${gr}_product_name`],
            totals: data[`${gr}_totals`],
            img_src: data[`${gr}_img_src`],
            group: data[gr],
            isPrev: gr == 'gr2',
          },
        })}
        {...commonProps}
      /> 
    </Row>

    <Row style={{display: mode === "PRESALES" ? 'flex' : 'none'}}>
      {!state.tenant_id ?
        <SupplierStatsChart
          type="SUPPLIER_EVENTS"
          chart_type="line"
          totals_by="tenants_count"
          // isSparkline={state.supplier_tags.length > 0 ? false : true}
          colSizes={{xs: 12, md: state.supplier_tags.length > 0 ? 6 : 12}}
          filters={getDateParams(state.group_by, state.compare_previous, 'tenants_count')}
          {...commonProps}
        /> : null}
      <SupplierStatsChart
        type="SUPPLIER_EVENTS"
        chart_type="bar"
        totals_by="totals"
        isSparkline={false}
        colSizes={{xs: 12, md: 12, lg: 12}}
        filters={getDateParams(state.group_by, state.compare_previous, 'totals')}
        {...commonProps}
      />
      {state.tenant_id ? null : <SupplierStatsChart
        type="SUPPLIER_EVENTS"
        chart_type="pie"
        totals_by="tenant"
        colSizes={{xs: 12, lg: 6}}
        show_previous={true}
        filters={getDateParams(state.group_by, state.compare_previous)}
        {...commonProps}
      />}
       <SupplierStatsChart
       type="SUPPLIER_EVENTS"
       chart_type="pie"
       totals_by="industry"
       colSizes={{xs: 12, lg: 6}}
       show_previous={true}
       filters={getDateParams(state.group_by, state.compare_previous,'industry')}
       {...commonProps}
     />         
      <SupplierStatsChart
        type="SUPPLIER_EVENTS"
        chart_type="pie"
        totals_by="events"
        colSizes={{xs: 12, lg: 6}}
        show_previous={true}
        filters={getDateParams(state.group_by, state.compare_previous, 'events')}
        {...commonProps}
      /> 
      <SupplierStatsChart
        type="SUPPLIER_EVENTS"
        chart_type="bar_bullet"
        totals_by="product"
        colSizes={{xs: 12, lg: 6}}
        show_previous={true}
        filters={getDateParams(state.group_by, state.compare_previous)}
        showSidepanel={setPanel}
        getSidepanelData={(data, gr='gr1') => ({
          isPrev: gr == 'gr2',
          supplierStatsData: {
            totals: data[`${gr}_totals`],
            group: data[gr],
            tenant_id: state.tenant_id,
            division_id: state.division_id,
            ...getDateParams(state.group_by, state.compare_previous),
            isPrev: gr == 'gr2',
            type: "SUPPLIER_EVENTS"
          },
          product_id: data[`${gr}_product_id`],
          product_data: {
            product_id: data[`${gr}_product_id`],
            product_name: data[`${gr}_product_name`],
            totals: data[`${gr}_totals`],
            img_src: data[`${gr}_img_src`],
            group: data[gr],
            isPrev: gr == 'gr2',
          },
        })}
        {...commonProps}
      />
       <SupplierStatsChart
        type="SUPPLIER_PRESALES"
        chart_type="bar_bullet"
        totals_by={state.rep_type_presales}
        colSizes={{xs: 12, lg: 6}}
        show_previous={true}
        filters={getDateParams(state.group_by, state.compare_previous)}
        titleSelect={{
          placeholder: "Select Rep Type...",
          options: rep_type_presales_options,
          value: state.rep_type_presales,
          onChange:(v) => {
            setState({...state, rep_type_presales: v});
          },
        }}
        {...commonProps}
      />
    </Row>
    <Row style={{display: mode === 'DOWNLOAD-REPORTS' ? 'flex' : 'none'}}>
      <SupplierReportDownloads />
    </Row>
  </>);
}

export default SupplierDashboard;
