import _ from 'lodash';
import { initialState } from './dashboardSlice';
import { createOptions } from '../../utils';

export const getLoading = (s={dashboard: initialState}) => s.dashboard.loading;

export const getProducts = (s={dashboard: initialState}) => s.dashboard.entities.products;
export const getProduct = (product_id) => (s={dashboard: initialState}) => _.get(s.dashboard.entities.products, product_id, {});

export const getTenants = (s={dashboard: initialState}) => s.dashboard.entities.tenants;
export const getTenantOptions = (s={dashboard: initialState}) => createOptions(
    Object.values(_.get(s.dashboard.entities, 'tenants', {})),
    'tenant_id', 'tenant_name', null
);

export const getSuppliers = (s={dashboard: initialState}) => s.dashboard.entities.suppliers;
export const getSupplierOptions = (s={dashboard: initialState}) => createOptions(
    Object.values(_.get(s.dashboard.entities, 'suppliers', {})),
    'supplier_id', 'supplier_name', null
);

export const getBillingPeriods = (s = { dashboard: initialState }) => s.dashboard.entities.billing_periods;

export const getSupplierEventStats = (s={dashboard: initialState}) => s.dashboard.entities.reports.supplierEvents;
export const getSupplierPOStats = (s={dashboard: initialState}) => s.dashboard.entities.reports.supplierPOs;
export const getSupplierProductsStats = (s={dashboard: initialState}) => s.dashboard.entities.reports.supplierProducts;
export const getSupplierPresalesStats = (s={dashboard: initialState}) => s.dashboard.entities.reports.supplierPresales;

export const getSupplierEvents = (s={dashboard: initialState}) => s.dashboard.entities.supplierEvents;
export const getSupplierPOs = (s={dashboard: initialState}) => s.dashboard.entities.supplierPOs;

export const getSupplierTags = (s={dashboard: initialState}) => s.dashboard.entities.supplier_tags;
export const getSupplierTagsOptions = (s={dashboard: initialState}) => createOptions(
    Object.values(_.get(s.dashboard.entities, 'supplier_tags', {})),
    'label', 'label', null
);
export const getSupplierTagsCustomOptions = (s={dashboard: initialState}) => createOptions(
    Object.values(_.get(s.dashboard.entities, 'supplier_tags', {})),
    'supplier_account_ids', 'label', null
);

export const getTenantsDropdown = (s={dropdowns: {tenants: {}}}) => _.get(s, 'dropdowns.tenants', {});
export const getTenantOptionsDropdown = (s={dropdowns: {tenants: {}}}) => createOptions(
    Object.values(getTenantsDropdown(s)),
    'tenant_id', 'tenant_name', null
);

export const getCompanyUsersDropdown = (s={dropdowns: {company_users: {}}}) => _.get(s, 'dropdowns.company_users', {});
export const getCompanyUsersOptionsDropdown = (s={dropdowns: {company_users: {}}}) => createOptions(
    Object.values(getCompanyUsersDropdown(s)),
    'user_id', v => v.contact_first_name + ' ' + v.contact_last_name, null
);
